<script setup>
import { computed, onBeforeMount, onMounted } from 'vue'
import store from '@/store'

// composables
import usePriceProgram from '@/composables/registration/usePriceProgram'
import useConfirmation from '@/composables/registration/useConfirmation'

// components
import Consents from '@/components/registrationWizard/Consents.vue'

// utils
import { isCountry } from '@/utils/isCountry'
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'
import formatDecimal from '@/utils/formatDecimal'
import markdownParser from '@/utils/markdownParser'
import showMessageArray from '@/utils/errorMessageArray'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { dispatch, state } = store

const RFID_PRODUCT_ID = 1

const {
    form,
    isFormValid,
    requestCard,
    hasError,
    chargingCard,
    getTranslationOrder,
    getTranslation,
    consents,
    getConsent,
    setConsent,
    finishRegistration
} = useConfirmation()

const { userTariffData, selectedTariff } = usePriceProgram()

const clientData = computed(() => state.account)
const totalPrice = computed(() => {
    const tariffPrice = userTariffData.value.aliquot_price
    const charginCardPrice = requestCard.value ? chargingCard.value.price : 0

    return `${formatDecimal(tariffPrice + charginCardPrice)} ${userTariffData.value.currency}`
})

const loadProducts = async () => {
    try {
        const { data } = await apiRequest('/eshop/products')
        chargingCard.value = data.find((item) => item.id === RFID_PRODUCT_ID) || null
    } catch (error) {
        showMessageArray(error.errors)
    }
}
const loadPaymentInfo = async () => {
    await dispatch('account/getPaymentInfo').catch((error) => {
        showMessage(error)
    })
}

onBeforeMount(async () => {
    await loadProducts()
    await loadPaymentInfo()
})

onMounted(() => {
    const params = {
        registration_step: 'confirmation'
    }
    dispatch('consents/getConsents', params).then((cnsts) => {
        consents.value = cnsts
    })
})
</script>

<template>
    <div class="confirmation">
        <div class="heading text-center mb-6 mx-auto">
            <h1 class="text-h5 font-weight-bold mb-2">
                {{ t('registration.confirmation') }}
            </h1>
            <span class="text-body-2">{{ t('registration.reviewYourOrder') }}</span>
        </div>
        <v-divider class="divider pb-6 mx-auto" />
        <main class="mx-auto">
            <template v-if="!chargingCard || !userTariffData">
                <v-skeleton-loader class="mb-6" type="list-item-three-line, list-item-three-line" />
                <v-skeleton-loader class="mb-6 mx-auto" height="288px" type="image" width="288px" />
                <v-skeleton-loader class="mb-6 mx-auto" height="68px" type="image" width="288px" />
                <v-skeleton-loader type="list-item-three-line, list-item-three-line" />
            </template>
            <template v-else>
                <section class="subject-data-section mb-8">
                    <h3 class="text-center text-grey-darken-3 mb-4 border-b pb-7">
                        <template v-if="clientData.isCompany">
                            {{ t('registration.companyData') }}
                        </template>
                        <template v-else>
                            {{ t('registration.personalData') }}
                        </template>
                    </h3>
                    <div class="d-flex justify-space-between">
                        <div>
                            <template v-if="clientData.isCompany">
                                <h5
                                    class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                    data-testid="companyName"
                                >
                                    {{ clientData.companyName }}
                                </h5>
                                <h5
                                    v-if="clientData.businessId"
                                    class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                    data-testid="businessId"
                                >
                                    {{ t('Client.ICO') }}: {{ clientData.businessId }}
                                </h5>
                                <h5
                                    v-if="clientData.taxId"
                                    class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                    data-testid="taxId"
                                >
                                    {{ t('Client.TaxId') }}: {{ clientData.taxId }}
                                </h5>
                                <h5
                                    v-if="clientData.vatId && !isCountry.isPoland(clientData.country)"
                                    class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                    data-testid="vatId"
                                >
                                    {{ t('Client.VatId') }}: {{ clientData.vatId }}
                                </h5>
                                <br />
                            </template>
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="name"
                            >
                                {{ clientData.firstName }} {{ clientData.lastName }}
                            </h5>
                            <h5
                                v-if="clientData.street"
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="address"
                            >
                                {{ clientData.street }} {{ clientData.houseNumber }}
                            </h5>
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="city"
                            >
                                {{ clientData.city }}
                            </h5>
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="zipCode"
                            >
                                {{ clientData.zipCode }}
                            </h5>
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="country"
                            >
                                {{ clientData.country }}
                            </h5>
                            <br />
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="email"
                            >
                                {{ clientData.email }}
                            </h5>
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="phone"
                            >
                                {{ clientData.phone }}
                            </h5>
                        </div>
                        <div v-if="clientData.deliveryAddress" class="pt-4 pt-sm-0">
                            <h4 class="font-weight-bold">{{ t('personalData.DeliveryAddress') }}:</h4>
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="deliveryName"
                            >
                                {{ clientData.deliveryAddress.name }}
                            </h5>
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="deliveryStreet"
                            >
                                {{ clientData.deliveryAddress.street }} {{ clientData.deliveryAddress.houseNumber }}
                            </h5>
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="deliveryCity"
                            >
                                {{ clientData.deliveryAddress.city }}
                            </h5>
                            <h5
                                class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                                data-testid="deliveryCountry"
                            >
                                {{ clientData.deliveryAddress.country }}
                            </h5>
                        </div>
                    </div>
                </section>
                <section class="price-program-section mb-8">
                    <h3 class="text-center text-grey-darken-3 mb-4 border-b pb-7" data-testid="priceProgram">
                        {{ t('driverInfo.PriceProgram') }}
                    </h3>
                    <div class="wrapper justify-space-between">
                        <div
                            class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center"
                            data-testid="tariffName"
                        >
                            {{ selectedTariff.name }}
                        </div>
                        <div class="text-h6" data-testid="priceValue">
                            <template v-if="selectedTariff.priceList.subscription">
                                {{ formatDecimal(selectedTariff.priceList.subscription.monthlyPrice) }}
                            </template>
                            <template v-else>
                                {{ formatDecimal(userTariffData.tariff_price) }}
                            </template>
                            {{ userTariffData.currency }}
                        </div>
                    </div>
                </section>
                <section class="payment-method--section mb-8">
                    <h3 class="text-center text-grey-darken-3 mb-4 border-b pb-7">
                        {{ t('registration.PaymentMethod') }}
                    </h3>
                    <div class="wrapper justify-space-between">
                        <div class="text-subtitle-2 font-weight-regular grey--text text--darken-2 d-flex align-center">
                            {{ t('registration.paymentCard') }}
                        </div>
                        <div class="text-h6" data-testid="cardNumber">
                            {{ clientData.paymentInfo.number }}
                        </div>
                    </div>
                </section>
                <section v-if="chargingCard" class="charging-card-section mb-8" data-testid="chargingCardSection">
                    <h3 class="text-center text-grey-darken-3 mb-4 border-b pb-7">
                        {{ t('registration.chargingCard') }}*
                    </h3>
                    <v-card class="mx-auto pa-4" elevation="0" outlined>
                        <div class="wrapper justify-space-between">
                            <v-radio-group
                                v-model="requestCard"
                                :error-messages="hasError"
                                class="grey--text text--darken-4 radio-group"
                                column
                                data-testid="chargingCard"
                            >
                                <v-radio :value="true" data-testid="chargingCardRadioButton">
                                    <template v-slot:label>
                                        <div v-html="markdownParser(t(getTranslationOrder()))" />
                                    </template>
                                </v-radio>
                                <v-radio
                                    :label="getTranslation()"
                                    :value="false"
                                    data-testid="mobileAppRadioButton"
                                    class="mt-5"
                                />
                            </v-radio-group>
                            <div class="price align-center">
                                <h4 class="text-h6" data-testid="chargingCardPrice">
                                    {{ formatDecimal(chargingCard.price) }} {{ chargingCard.currency }}
                                </h4>
                                <h4 class="text-h6">{{ formatDecimal(0.0) }} {{ chargingCard.currency }}</h4>
                            </div>
                        </div>
                    </v-card>
                </section>
                <section class="summary-section mb-8">
                    <h3 class="text-center text-grey-darken-3 mb-4">
                        {{ t('registration.summary') }}
                    </h3>
                    <v-card class="mx-auto" elevation="0" max-width="420px" outlined>
                        <ul class="pa-0">
                            <li class="mb-4">
                                <div class="left d-flex flex-column justify-center px-3 pt-3">
                                    <h5 class="text-body-2" data-testid="summaryTariffName">
                                        {{ selectedTariff.name }}
                                    </h5>
                                    <h6 class="text-caption">
                                        {{ t('registration.monthlyUntil') }}
                                    </h6>
                                </div>
                                <div
                                    class="right text-right text-h6 d-flex align-center text-no-wrap px-3 pt-3"
                                    data-testid="summaryTarriffPrice"
                                >
                                    <template v-if="selectedTariff.priceList.priceOff">
                                        {{
                                            formatDecimal(
                                                userTariffData.aliquot_price / selectedTariff.priceList.priceOff.value
                                            )
                                        }}
                                    </template>
                                    <template v-else>
                                        {{ formatDecimal(userTariffData.aliquot_price) }}
                                    </template>
                                    {{ userTariffData.currency }}
                                </div>
                            </li>
                            <li
                                v-if="
                                    selectedTariff.priceList.priceOff &&
                                    selectedTariff.priceList.priceOff.tariffSummaryTile
                                "
                                class="priceOff primary"
                            >
                                <div class="left d-flex flex-column justify-center pa-3">
                                    <div
                                        v-html="
                                            markdownParser(t(`${selectedTariff.priceList.priceOff.tariffSummaryTile}`))
                                        "
                                    />
                                </div>
                                <div class="right text-right text-h6 d-flex align-center text-no-wrap pa-3">
                                    -{{ formatDecimal(userTariffData.aliquot_price) }} {{ userTariffData.currency }}
                                </div>
                            </li>
                            <li v-if="requestCard && chargingCard" class="mb-4">
                                <div class="left d-flex flex-column justify-center px-3 pt-3">
                                    <h5 class="text-body-2">
                                        {{ t('registration.chargingCard') }}
                                    </h5>
                                    <h6 class="text-caption">
                                        {{ t('registration.oneTimeFee') }}
                                    </h6>
                                </div>
                                <div
                                    class="right text-right text-h6 d-flex align-center text-no-wrap px-3"
                                    data-testid="chargingCardFee"
                                >
                                    {{ formatDecimal(chargingCard.price) }} {{ userTariffData.currency }}
                                </div>
                            </li>
                        </ul>
                        <v-divider class="pb-2" />
                        <div class="sum" data-testid="totalPriceParent">
                            <div class="left d-flex align-center text-body-2 px-3 pb-3">
                                {{ t('registration.sum') }}
                            </div>
                            <div class="right text-right d-flex align-center text-h6 text-no-wrap px-3 pb-3">
                                {{ totalPrice }}
                            </div>
                        </div>
                    </v-card>
                </section>
                <section class="monthly-payments-section mb-8">
                    <h3 class="text-center text-grey-darken-3 mb-4">
                        {{ t('registration.followingPayments') }}
                    </h3>
                    <v-card class="mx-auto pa-3" elevation="0" max-width="420px" outlined>
                        <ul class="pa-0">
                            <li>
                                <div class="left d-flex flex-column justify-center">
                                    <h5 class="text-body-2">
                                        {{ selectedTariff.name }}
                                    </h5>
                                    <h6 class="text-caption">
                                        {{ t('registration.monthlyFee') }}
                                    </h6>
                                </div>
                                <div
                                    class="right text-right text-h6 d-flex align-center text-no-wrap"
                                    data-testid="monthlyFee"
                                >
                                    <template v-if="selectedTariff.priceList.subscription">
                                        {{ formatDecimal(selectedTariff.priceList.subscription.monthlyPrice) }}
                                    </template>
                                    <template v-else>
                                        {{ formatDecimal(userTariffData.tariff_price) }}
                                    </template>
                                    {{ userTariffData.currency }}
                                </div>
                            </li>
                        </ul>
                    </v-card>
                </section>
                <section class="consent-section mb-8">
                    <h3 class="text-center text-grey-darken-3 mb-4">
                        {{ t('personalData.Consents') }}
                    </h3>
                    <v-form ref="form" v-model="isFormValid" class="form mt-6">
                        <Consents :display-consents="consents" @getConsent="getConsent" @setConsent="setConsent" />
                    </v-form>
                    <v-btn
                        :loading="store.getters['isLoading']"
                        class="d-flex mx-auto"
                        color="primary"
                        data-testid="finishRegistration"
                        large
                        rounded="xl"
                        @click="finishRegistration"
                    >
                        {{ t('registrationWizard.Finish') }}
                    </v-btn>
                </section>
            </template>
        </main>
    </div>
</template>
