<script setup>
// utils
import { useI18n } from 'vue-i18n'
import markdownParser from '@/utils/markdownParser.js'

const { t } = useI18n()

defineProps(['data'])

const getArticleTitleFromCategory = (category) => {
    if (category == 'danger') {
        return t('news.Personal')
    }
    if (category == 'warning') {
        return t('news.Marketing')
    }
    return t('news.' + category)
}
</script>

<template>
    <div class="news-form-wrapper">
        <article v-for="(article, index) in data" :key="index" class="news-card min-height-200" :id="[article.category === 'danger' ? `danger-notification-${index+1}` : '']">
            <div class="news-card__aside">
                <v-chip class="ma-2 text-capitalize no-hover"
                        variant="outlined"
                        :class="{
                            'news-card__warning': article.category === 'warning',
                            'news-card__danger': article.category === 'danger'
                        }"
                        color="green"
                        label
                >
                    {{ getArticleTitleFromCategory(article.category) }}
                </v-chip>
            </div>
            <main class="news-card__main">
                <h2 class="news-card__title">
                    {{ article.title }}
                    <v-icon v-if="article.category === 'danger' || article.category === 'warning'"
                            :class="{
                                'icon-danger': article.category === 'danger',
                                'icon-warning': article.category === 'warning'
                            }"
                    >
                        mdi-alert
                    </v-icon>
                </h2>
                <p class="news-card__date">
                    {{ article.publishedAt }}
                </p>
                <div class="news-card__info">
                    <p v-html="markdownParser(article.content)" />
                </div>
            </main>
        </article>
    </div>
</template>