<script setup>
import { onBeforeMount } from 'vue'
import store from '@/store'

// composables
import useRegistration from '@/composables/registration/useRegistration'
import useContactInfo from '@/composables/registration/useContactInfo'

// Components
import CompanyForm from '@/components/registrationWizard/CompanyForm.vue'
import BillingForm from '@/components/registrationWizard/BillingForm.vue'
import PersonalForm from '@/components/registrationWizard/PersonalForm.vue'
import VehicleInformation from '@/components/registrationWizard/VehicleInformation.vue'

// utils
import { useI18n } from 'vue-i18n'

const { dispatch, getters } = store
const { t } = useI18n()
const { formData, isPhoneVerified, isSkipVerificationPhone, errors } = useRegistration()

const {
    form,
    vehicle,
    personalTitle,
    setFormField,
    setIsPhoneVerified,
    setCarFields,
    unsetIsPhoneVerified,
    skipVerificationPhone,
    setRequiredVatValidation,
    next
} = useContactInfo()

onBeforeMount(() => {
    formData.value = { ...store.state.account }
    if (store.state.account.primaryDriverId) {
        dispatch('drivers/getDriver', store.state.account.primaryDriverId).then(() => {
            if (store.state.drivers.driver.vehicle.id) {
                vehicle.value = store.state.drivers.driver.vehicle
            }
        })
    }
})

const clearError = (key) => {
    errors.value[key] = ''
}
</script>

<template>
    <div class="personal-details">
        <div class="heading text-center">
            <h1 class="text-h5 font-weight-bold mb-2" data-testid="personalDetailsHeader">
                {{ t('registrationWizard.PersonalDetails') }}
            </h1>
            <span class="text-body-2">{{ t('registrationWizard.PleaseCompleteTheData') }}</span>
        </div>
        <main>
            <div class="tabs d-flex my-6 pa-1 mx-auto">
                <div
                    class="tab text-center"
                    :class="{ selected: formData.isCompany === 0 }"
                    data-testid="personButton"
                    @click="formData.isCompany = 0"
                >
                    {{ t('Client.Individual') }}
                </div>
                <div
                    class="tab text-center"
                    :class="{ selected: formData.isCompany === 1 }"
                    data-testid="companyButton"
                    @click="formData.isCompany = 1"
                >
                    {{ t('Client.Company') }}
                </div>
            </div>
            <v-form ref="form">
                <section v-if="formData.isCompany" class="section mt-5">
                    <h2 class="text-subtitle-1 font-weight-medium text-center mb-6">
                        {{ t('personalData.CompanyInformation') }}
                    </h2>
                    <CompanyForm
                        :form-data="formData"
                        @setFormField="setFormField"
                        @isVatPayer="setRequiredVatValidation"
                    />
                </section>
                <section>
                    <h2 class="text-subtitle-1 font-weight-medium text-center mb-6">
                        {{ t(`Client.${personalTitle}`) }}
                    </h2>
                    <v-container fluid class="pa-0">
                        <PersonalForm
                            :is-phone-verified="isPhoneVerified"
                            :is-skip-verification-phone="isSkipVerificationPhone"
                            :form-data="formData"
                            :errors="errors"
                            @clearError="clearError"
                            @setFormField="setFormField"
                            @setIsPhoneVerified="setIsPhoneVerified"
                            @unsetIsPhoneVerified="unsetIsPhoneVerified"
                            @skipVerificationPhone="skipVerificationPhone"
                        />
                    </v-container>
                </section>
                <section>
                    <h2 class="text-subtitle-1 font-weight-medium text-center mb-6">
                        {{
                            formData.isCompany
                                ? t('personalData.BillingAddressCompany')
                                : t('personalData.BillingAddressIndividual')
                        }}
                    </h2>
                    <BillingForm :is-registration="true" :form-data="formData" @setFormField="setFormField" />
                </section>
                <section>
                    <h2 class="text-subtitle-1 font-weight-medium text-center mb-6 mt-6 mt-md-0">
                        {{ t('Car.CarInformation') }}
                    </h2>
                    <p class="mb-8">{{ t('Car.ByProvidingTheVehicleDataYouAgree') }}.</p>
                    <VehicleInformation
                        :title="t('Car.CarInformation')"
                        :info-message="t('Car.EnteringDataAboutYourCar')"
                        :is-wizard-registration-title="true"
                        :vehicle="vehicle"
                        @setCarFields="setCarFields"
                    />
                </section>
            </v-form>
            <v-btn
                :loading="getters['isLoading']"
                rounded="xl"
                color="primary"
                large
                class="d-flex mx-auto"
                data-testid="nextStep"
                @click="next"
            >
                {{ t('registrationWizard.SaveAndNext') }}
            </v-btn>
        </main>
    </div>
</template>
