<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

// components
import FreeKWh from '@/components/FreeKWh.vue'
import Btn from '@/components/inputs/Btn.vue'
import Discounts from '@/components/Discounts.vue'
import CouponsLoader from '@/loaders/CouponsLoader.vue'
import CouponsHistory from '@/components/CouponsHistory.vue'
import CreditBoxWrapper from '@/components/CreditBoxWrapper.vue'
import CouponsForm from '@/pages/coupons/components/CouponsForm.vue'
import CouponsList from '@/pages/coupons/components/CouponsList.vue'

// utils
import { getEnvironment } from '@/utils/isFeatureAvailable'
import setPageTitle from '@/utils/pageTitle'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { dispatch, getters, commit } = store

const packages = computed(() => store.state.freeKwhPackages.freeKwhPerDriver)
const creditBoxFeatureFlag = computed(
    () => store.state.featureFlags.enabledFeatures[`${getEnvironment(window.location.origin)}_credit_box`] || false
)
const discountsFeatureFlag = computed(
    () => store.state.featureFlags.enabledFeatures[`${getEnvironment(window.location.origin)}_discounts`] || false
)
const couponsHistoryFeatureFlag = computed(
    () => store.state.featureFlags.enabledFeatures[`${getEnvironment(window.location.origin)}_coupons_history`] || false
)

const tabs = computed(() => {
    let data = [{ content: 'Coupons', title: t('coupons.couponsAndDiscounts') }]
    if (couponsHistoryFeatureFlag.value) {
        data.push({ content: 'History', title: t('discounts.history'), disabled: !couponsHistoryFeatureFlag.value })
    }
    return data
})

const tabIndex = computed({
    get() {
        return store.state.coupons.tabIndex
    },
    set(tabIndex) {
        commit('coupons/setTab', {
            tabIndex
        })
    }
})

const isLoading = computed(() => {
    return getters['isLoading']
})

const isLoadMore = computed(() => {
    return getters['coupons/isLoadMore']
})

const getFreeKWhPerDriver = async () => {
    return store.dispatch('freeKwhPackages/getFreeKWhPerDriver')
}

const loadCoupons = () => {
    commit('coupons/setPage', store.state.coupons.page + 1)
    dispatch('coupons/loadMoreCoupons')
}

onMounted(async () => {
    setPageTitle(t('coupons.title'))
    commit('coupons/clearCoupons')
    await dispatch('coupons/getCoupons')
    await dispatch('coupons/getCouponsHistory')
    await dispatch('coupons/getCredits')
    await getFreeKWhPerDriver()
})
</script>

<template>
    <div class="coupons-page">
        <header class="coupons-page__header align-self-center">
            <h1 class="coupons-page__title">
                {{ t('coupons.title') }}
            </h1>
            <div class="coupons-page__filters-options">
                <v-tabs v-model="tabIndex" background-color="transparent" class="coupons__tabs">
                    <v-tab v-for="{ title, disabled } in tabs" :key="title" :disabled="disabled">
                        {{ title }}
                    </v-tab>
                </v-tabs>
            </div>
        </header>
        <v-window v-model="tabIndex" :touch="false">
            <v-window-item v-for="item in tabs" :key="item.title" :disabled="item.disabled">
                <template v-if="item.content === 'Coupons'">
                    <div class="coupons">
                        <main class="coupons__main">
                            <CouponsForm />
                            <CouponsLoader v-if="isLoading" />
                            <CouponsList v-else :data="store.state.coupons.coupons" />
                            <Btn v-if="isLoadMore" :disable="store.getters['isLoading']" large @click="loadCoupons()">
                                {{ t('news.loadMore') }}
                            </Btn>
                        </main>
                    </div>
                    <v-row class="px-8">
                        <v-col v-if="packages.length" cols="12" lg="6">
                            <FreeKWh :packages="packages" />
                        </v-col>
                        <v-col v-if="creditBoxFeatureFlag && !isLoading" cols="12" lg="6">
                            <CreditBoxWrapper />
                        </v-col>
                    </v-row>
                    <Discounts v-if="discountsFeatureFlag" :is-loading="isLoading" />
                </template>

                <CouponsHistory
                    v-if="item.content === 'History' && couponsHistoryFeatureFlag"
                    :data="store.state.coupons.couponsHistory"
                    :is-loading="isLoading"
                />
            </v-window-item>
        </v-window>
    </div>
</template>
