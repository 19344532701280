<script setup>
import { useStore } from 'vuex'
import { reactive, computed, watch, onMounted } from 'vue'

// configs
import { Locale, Country, CountryISO } from '@/configs/index.js'

// components
import CountrySelect from '@/components/registrationWizard/CountrySelect.vue'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty, isValid1Character, isValid2Characters, maxCharsExceeded } = useValidations()

// traslations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const emit = defineEmits(['setFormField'])

const props = defineProps({
    isEditView: {
        type: Boolean,
        required: false,
        default: true
    },
    isRegistration: {
        type: Boolean,
        required: false,
        default: false
    },
    formData: {
        type: Object,
        required: true
    }
})

const state = reactive({
    isDeliveryAddress: !!store.state.account.deliveryAddress,
    deliveryAddress: {
        name: '',
        city: '',
        street:
            null !== store.state.account.deliveryAddress && store.state.account.deliveryAddress.street
                ? store.state.account.deliveryAddress.street
                : '',
        houseNumber: '',
        zipCode: '',
        country_iso3: ''
    }
})

const city = computed({
    get() {
        return props.formData.city
    },
    set(value) {
        setValue('city', value)
    }
})

const street = computed({
    get() {
        return props.formData.street
    },
    set(value) {
        setValue('street', value)
    }
})

const house_number = computed({
    get() {
        return props.formData.houseNumber
    },
    set(value) {
        setValue('houseNumber', value)
    }
})

const zip_code = computed({
    get() {
        return props.formData.zipCode
    },
    set(value) {
        setValue('zipCode', value)
    }
})

const isCompany = computed(() => {
    return props.formData.isCompany
})

watch(
    () => state.deliveryAddress,
    (val) => {
        setValue('deliveryAddress', getDeliveryAddressObject(val))
    },
    { deep: true }
)

watch(
    () => state.isDeliveryAddress,
    (val) => {
        if (!val) {
            setValue('deliveryAddress', null)
            return
        }
        setValue('deliveryAddress', getDeliveryAddressObject(state.deliveryAddress))
        if (!props.formData.deliveryAddress.country_iso3) {
            switch (store.state.account.locale) {
                case Locale.pl:
                    setDeliveryCountryValue(null, CountryISO.Poland)
                    break
                case Locale.hr:
                    setDeliveryCountryValue(null, CountryISO.Croatia)
                    break
                default:
                    setDeliveryCountryValue(null, CountryISO.Slovakia)
            }
        }
    }
)

watch(
    () => props.formData,
    () => {
        if (props.formData.deliveryAddress !== null) {
            setDeliveryAddressData()
            return
        }
        state.isDeliveryAddress = false
        setValue('deliveryAddress', null)
        state.deliveryAddress.name = ''
        state.deliveryAddress.city = ''
        state.deliveryAddress.street = ''
        state.deliveryAddress.houseNumber = ''
        state.deliveryAddress.zipCode = ''
        if (store.state.account.locale === Locale.pl) {
            setDeliveryCountryValue(null, CountryISO.Poland)
            return
        }
        setDeliveryCountryValue(null, CountryISO.Slovakia)
    }
)

const setValue = (field, value) => {
    emit('setFormField', { field, value })
}

const setDeliveryCountryValue = (_, val) => {
    if (store.state.filters.countries) {
        const countries = store.state.filters.countries
        const country = countries.filter((x) => x.value === val)
        state.deliveryAddress.country_iso3 = country[0].value
    }
}

const getDeliveryAddressObject = (data) => {
    return {
        name: data.name,
        street: data.street,
        houseNumber: data.houseNumber,
        zipCode: data.zipCode,
        city: data.city,
        country_iso3: data.country_iso3
    }
}

const setDeliveryAddressData = () => {
    state.deliveryAddress.name = props.formData.deliveryAddress.name
    state.deliveryAddress.city = props.formData.deliveryAddress.city
    state.deliveryAddress.street = props.formData.deliveryAddress.street
    state.deliveryAddress.houseNumber = props.formData.deliveryAddress.houseNumber
    state.deliveryAddress.zipCode = props.formData.deliveryAddress.zipCode
    state.deliveryAddress.country_iso3 = props.formData.deliveryAddress.country_iso3
}
const prefillCountry = () => {
    switch (store.state.account.locale) {
        case Locale.pl:
            setValue('country_iso3', CountryISO.Poland)
            break
        case Locale.hr:
            setValue('country_iso3', CountryISO.Croatia)
            break
        default:
            setValue('country_iso3', CountryISO.Slovakia)
            break
    }
}

onMounted(() => {
    if (props.formData.deliveryAddress !== null) {
        setDeliveryAddressData()
    }

    store.subscribeAction({
        after: (action) => {
            if (action.type === 'account/getAccount') {
                state.isDeliveryAddress = !!store.state.account.deliveryAddress
                if (props.isRegistration && !store.state.account.country) {
                    prefillCountry()
                }
            }
        }
    })
    if (props.formData.deliveryAddress !== null) {
        setDeliveryCountryValue('', props.formData.deliveryAddress.country_iso3)
    }
})
</script>

<template>
    <div class="billing-form">
        <v-container fluid class="pa-0">
            <v-row no-gutters>
                <v-col v-if="!isCompany" class="py-0" md="4" cols="12">
                    <CountrySelect
                        :is-edit-view="isEditView"
                        :country="formData.country_iso3"
                        @setValue="setValue('country_iso3', $event)"
                        data-testid="country"
                    />
                </v-col>
                <v-col class="py-0" :class="{ 'pl-md-8': !formData.isCompany }" md="4" cols="12">
                    <v-text-field
                        v-model="city"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.City') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                        data-testid="city"
                    />
                </v-col>
                <v-col class="py-0 pl-md-8" md="4" cols="12">
                    <v-text-field
                        v-model="street"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.Street') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                        data-testid="street"
                    />
                </v-col>
                <v-col class="py-0" :class="{ 'pl-md-8': formData.isCompany }" md="4" cols="12">
                    <v-text-field
                        v-model="house_number"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.HouseNumber') + '*'"
                        :rules="[isNotEmpty, isValid1Character, maxCharsExceeded(45)]"
                        data-testid="houseNumber"
                    />
                </v-col>
                <v-col class="py-0" :class="{ 'pl-md-8': !formData.isCompany }" md="4" cols="12">
                    <v-text-field
                        v-if="formData.country === Country.Slovakia"
                        v-model="zip_code"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.ZipCode') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                        data-testid="zipCode"
                    />
                    <v-text-field
                        v-else-if="formData.country === Country.Poland"
                        v-model="zip_code"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.ZipCode') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                        data-testid="zipCode"
                    />
                    <v-text-field
                        v-else
                        v-model="zip_code"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.ZipCode') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                        data-testid="zipCode"
                    />
                </v-col>
            </v-row>
        </v-container>
        <v-checkbox
            v-model="state.isDeliveryAddress"
            class="delivery-checkbox"
            hide-details
            :disabled="!isEditView"
            :label="t('personalData.DifferentDeliveryAddress')"
        />
        <v-container v-show="state.isDeliveryAddress" fluid class="pa-0">
            <h2 class="text-subtitle-1 font-weight-medium text-center mb-6">
                {{ t('personalData.DeliveryAddress') }}
            </h2>
            <v-row no-gutters>
                <v-col class="py-0" md="4" cols="12">
                    <v-text-field
                        v-model="state.deliveryAddress.name"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.DeliveryName') + '*'"
                        :rules="state.isDeliveryAddress ? [isNotEmpty, isValid2Characters, maxCharsExceeded(45)] : []"
                        data-testid="diffName"
                    />
                </v-col>
                <v-col class="py-0 pl-md-8" md="4" cols="12">
                    <v-text-field
                        v-model="state.deliveryAddress.street"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.Street') + '*'"
                        :rules="state.isDeliveryAddress ? [isNotEmpty, isValid2Characters, maxCharsExceeded(45)] : []"
                        data-testid="diffStreet"
                    />
                </v-col>
                <v-col class="py-0 pl-md-8" md="4" cols="12">
                    <v-text-field
                        v-model="state.deliveryAddress.houseNumber"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.HouseNumber') + '*'"
                        :rules="state.isDeliveryAddress ? [isNotEmpty, isValid1Character, maxCharsExceeded(45)] : []"
                        data-testid="diffHouseNumber"
                    />
                </v-col>
                <v-col class="py-0" md="4" cols="12">
                    <v-text-field
                        v-model="state.deliveryAddress.city"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.City') + '*'"
                        :rules="state.isDeliveryAddress ? [isNotEmpty, isValid2Characters, maxCharsExceeded(45)] : []"
                        data-testid="diffCity"
                    />
                </v-col>
                <v-col class="py-0 pl-md-8" md="4" cols="12">
                    <v-text-field
                        v-if="formData.country === Country.Slovakia"
                        v-model="state.deliveryAddress.zipCode"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.ZipCode') + '*'"
                        :rules="state.isDeliveryAddress ? [isNotEmpty, isValid2Characters] : []"
                        data-testid="diffZip"
                    />
                    <v-text-field
                        v-else-if="formData.country === Country.Poland"
                        v-model="state.deliveryAddress.zipCode"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.ZipCode') + '*'"
                        :rules="state.isDeliveryAddress ? [isNotEmpty, isValid2Characters] : []"
                        data-testid="diffZip"
                    />
                    <v-text-field
                        v-else
                        v-model="state.deliveryAddress.zipCode"
                        variant="outlined"
                        :disabled="!isEditView"
                        :label="t('Client.ZipCode') + '*'"
                        :rules="state.isDeliveryAddress ? [isNotEmpty, isValid2Characters] : []"
                        data-testid="diffZip"
                    />
                </v-col>
                <v-col class="py-0 pl-md-8" md="4" cols="12">
                    <CountrySelect
                        :is-edit-view="isEditView"
                        :country="state.deliveryAddress.country_iso3"
                        :is-delivery-country="true"
                        :is-delivery-address="state.isDeliveryAddress"
                        @setDeliveryCountry="setDeliveryCountryValue('deliveryCountry', $event)"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
