<script setup>
import { reactive, ref } from 'vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

defineProps({
    value: String
})

const emit = defineEmits(['input'])

const state = reactive({
    delayTimer: null
})

const startChargingSearchRer = ref()

const insertCharacter = (character) => {
    let search = startChargingSearchRer.value
    search.value = `${search.value}${character}`
    search.focus()
}

const triggerFiltering = (value) => {
    clearTimeout(state.delayTimer)
    state.delayTimer = setTimeout(() => {
        emit('input', value)
    }, 500)
}

</script>

<template>
    <div>
        <v-text-field
            ref="startChargingSearchRer"
            :placeholder="t('Search')"
            :model-value="value"
            class="mx-4 mb-2"
            density="compact"
            full-width
            hide-details
            outlined
            prepend-inner-icon="mdi-magnify"
            @update:model-value="triggerFiltering($event)"
            data-testid="searchField"
        />
        <div class="mt-2 mb-3 pl-2 d-none">
            <small
                class="active-button active-icon"
                @click="insertCharacter('ł')"
            >
                <v-icon class="pl-3 pr-2 text-h6">
                    mdi-information-outline
                </v-icon>
                <span>{{ t('drivers.insertSpecialCharacter') }}</span>
            </small>
        </div>
    </div>
</template>