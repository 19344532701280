<script setup>
// composables
import useCharging from '@/composables/charging/useCharging'
import usePriceFormat from '@/composables/charging/usePriceFormat'

// components
import IconConnector from '@/components/icons/IconConnector.vue'

// utils
import isEmptyObj from '@/utils/isEmptyObj'
import { PaymentProviders } from '@/utils/paymentProviders'

// translations
const { t } = useI18n()
import { useI18n } from 'vue-i18n'

const {
    isLoggedIn,
    connectorData,
    connectorAvailable,
    isUnknownConnectorStatus,
    driverBlocked,
    getPriceListUrl,
    initiateCharging,
    backToMap,
    hasOtheRunningSession,
    lastCommandConnectorCode,
    loginToStart,
    drivers,
    selectedDriver,
    isDriverAccount,
    requestGuestPaymentToken,
    oneTimeProvider,
    activeScreen
} = useCharging()

const { pricePerKWH, pricePerMin, bestPricePerKWH, bestPricePerMin } = usePriceFormat()

const toOneTimeChargingScreen = async () => {
    if (oneTimeProvider.value === PaymentProviders.Braintree) {
        await requestGuestPaymentToken()
        return
    }
    activeScreen.value = 6
}

const toActiveSession = () => {
    window.location.href = `${window.location.origin}/charging?connectorCode=${lastCommandConnectorCode.value}`
}

const driverInitials = (val) => {
    let names = val.split(' '),
        initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
}
</script>

<template>
    <v-card :elevation="3" class="start-charging mr-auto ml-auto mt-md-8 mt-2 pa-4">
        <div
            class="connector-icon mx-auto my-4 d-flex align-center justify-center"
            :class="{ 'is-available': connectorAvailable && !isUnknownConnectorStatus }"
        >
            <IconConnector
                :connector-type="connectorData.type"
                :size="100"
                :fill-color="connectorAvailable ? '#7FB239' : '#666666'"
                data-testid="connectorIcon"
            />
            <div class="d-flex align-center justify-center mark" :class="{ unknown: isUnknownConnectorStatus }">
                <v-icon v-if="isUnknownConnectorStatus" color="white"> mdi-help </v-icon>
                <span v-else />
            </div>
        </div>
        <h2 class="location--name subtitle-1 text-center">
            {{ connectorData.location.name }}
        </h2>
        <div class="connector text-center mb-4">
            <h3 class="connector--code text-h6 text-center mb-4">
                {{ t('startCharging.connector') }} #{{ connectorData.printed_label }}
            </h3>
            <span class="connector--type text-body-2">{{ connectorData.type.split('_')[1] }} plug,</span>
            <span v-if="connectorData.max_power" class="connector--power text-body-2"
                >&nbsp;{{ connectorData.max_power }}kW</span
            >
            <span v-else class="connector--power text-body-2">&nbsp;? kW</span>
        </div>
        <div
            v-if="
                !isLoggedIn &&
                connectorData.best_price &&
                !isEmptyObj(connectorData.best_price) &&
                connectorData.is_one_time_charging_allowed
            "
            class="best-price text-body-2 text-center mb-4"
        >
            <h4 class="font-weight-regular grey--text text--lighten-1">
                {{ t('startCharging.bestPriceTitle') }}
            </h4>
            <span class="priceKWH">{{ bestPricePerKWH }}/kWh</span>
            <span class="priceMinute"> + {{ bestPricePerMin }}/min </span>
            <span>({{ t('startCharging.after') }} {{ connectorData.best_price.postponeMinutes }} min)</span>
        </div>
        <div v-if="connectorData.price" class="price text-body-2 text-center mb-4">
            <template v-if="connectorData.is_one_time_charging_allowed">
                <h4
                    v-if="!isLoggedIn && !isEmptyObj(connectorData.price)"
                    class="font-weight-regular grey--text text--lighten-1"
                >
                    {{ t('startCharging.oneTimePriceTitle') }}
                </h4>
            </template>
            <template v-else>
                <h4 class="font-weight-regular grey--text text--lighten-1">
                    {{ t('startCharging.chargingPriceTitle') }}
                </h4>
            </template>
            <span class="priceKWH">{{ pricePerKWH }}/kWh</span>
            <span class="priceMinute"> + {{ pricePerMin }}/min </span>
            <span>({{ t('startCharging.after') }} {{ connectorData.price.postponeMinutes }} min)</span>
        </div>
        <v-row class="mb-4">
            <v-col class="text-center">
                <a :href="getPriceListUrl" target="_blank" class="text-body-2">{{
                    t('startCharging.showPriceList')
                }}</a>
            </v-col>
        </v-row>
        <v-row v-if="!connectorAvailable">
            <v-col>
                <h3 class="location--name subtitle-1 text-center red--text mb-4">
                    {{ t('startCharging.connectorIsNotAvailable') }}
                </h3>
            </v-col>
        </v-row>
        <v-row v-if="isUnknownConnectorStatus">
            <v-col>
                <p class="text-subtitle-2 text-center" style="color: rgb(var(--v-theme-sc-unknown))">
                    {{ t('startCharging.connectorStatusUnknown') }}
                </p>
            </v-col>
        </v-row>
        <v-row
            v-if="connectorAvailable && isLoggedIn && !driverBlocked && selectedDriver && !isDriverAccount"
            class="mb-2"
        >
            <v-col>
                <v-select
                    v-model="selectedDriver"
                    :items="drivers"
                    item-title="name"
                    item-value="id"
                    placeholder="Driver"
                    title="Driver"
                    hide-details
                    return-object
                    single-line
                    rounded="xl"
                    variant="outlined"
                    class="driver-picker"
                >
                    <template v-slot:selection="{ item }">
                        <div class="driver-data d-flex flex-column overflow-hidden">
                            <h3 class="text-caption font-weight-bold text-no-wrap">
                                {{ item.raw.name }} - {{ item.raw.tariff.name }}
                            </h3>
                            <h4 class="text-caption text-no-wrap">
                                {{ item.raw.email }}
                            </h4>
                        </div>
                    </template>
                    <template v-slot:prepend-inner>
                        <div class="driver-avatar d-flex align-center justify-center font-weight-bold mr-2">
                            {{ driverInitials(selectedDriver.name) }}
                        </div>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <div class="buttons">
            <template v-if="connectorAvailable">
                <template v-if="isLoggedIn && !driverBlocked">
                    <v-btn
                        class="text-white mb-4"
                        :loading="!selectedDriver && isLoggedIn"
                        rounded="xl"
                        :color="
                            isUnknownConnectorStatus ? 'rgb(var(--v-theme-sc-unknown))' : 'rgb(var(--v-theme-primary))'
                        "
                        data-testid="comenceChargingButton"
                        @click="initiateCharging()"
                    >
                        <template v-if="isUnknownConnectorStatus">
                            {{ t('startCharging.confirmStartChargingAnyways') }}
                        </template>
                        <template v-else>
                            {{ t('startCharging.confirmStartCharging') }}
                        </template>
                    </v-btn>
                </template>
                <template v-else-if="isLoggedIn && driverBlocked">
                    <v-btn
                        class="text-white mb-4"
                        rounded="xl"
                        color="rgb(var(--v-theme-error))"
                        data-testid="comenceChargingButton"
                    >
                        <router-link to="/news" style="text-decoration: none; color: inherit">
                            {{ t('startCharging.blockedUser') }}
                        </router-link>
                    </v-btn>
                </template>
                <template v-else>
                    <template v-if="hasOtheRunningSession">
                        <v-btn
                            class="text-white mb-4"
                            rounded="xl"
                            color="rgb(var(--v-theme-sc-blue))"
                            data-testid="comenceChargingButton"
                            @click.prevent="toActiveSession()"
                        >
                            {{ t('startCharging.runningSessionElsewhere') }}
                        </v-btn>
                    </template>
                    <template v-else>
                        <v-btn
                            class="text-white mb-4 bg-blue"
                            rounded="xl"
                            color="rgb(var(--v-theme-sc-blue))"
                            @click="loginToStart"
                        >
                            {{ t('startCharging.loginToStart') }}
                        </v-btn>
                        <v-btn
                            v-if="connectorData.is_one_time_charging_allowed"
                            class="mb-4"
                            rounded="xl"
                            variant="outlined"
                            color="rgb(var(--v-theme-sc-blue))"
                            @click.prevent="toOneTimeChargingScreen()"
                        >
                            {{ t('startCharging.goToOneTimeCharging') }}
                        </v-btn>
                    </template>
                </template>
            </template>
        </div>
        <v-row>
            <v-col cols="12">
                <v-btn variant="outlined" rounded="xl" data-testid="cancelButton" @click="backToMap()">
                    {{ t('startCharging.cancel') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
