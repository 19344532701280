<script setup>
// translations
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const emit = defineEmits(['change'])

defineProps({
    active: {
        required: false
    },
    isDisabled: {
        required: false,
        default: false
    }
})
</script>

<template>
    <v-btn
        :class="{'filter-toggle filter-by-toggle-wrapper': true, 'filter-toggle--active filter-by-toggle-wrapper--active': active}"
        variant="text"
        data-testid="filterButton"
        :disabled="isDisabled"
        @click="emit('change', !active)"
    >
        <v-icon class="mr-2 active-icon pl-2">
            mdi-filter-variant
        </v-icon>
        <small>{{ t('sessions.FilterBy') }}</small>
        <v-icon class="icon active-icon">
            {{ active ? 'mdi-menu-up' : 'mdi-menu-down' }}
        </v-icon>
    </v-btn>
</template>
