<script setup>
import qs from 'qs'
import { useStore } from 'vuex'
import router from '@/router.js'

// config
import { Roles } from '@/configs/index.js'

// composables
import useChargers from '@/composables/chargers.js'
import useWindowWidth from '@/composables/windowWidth'
import useFacilitySessions from '@/composables/facilitySessions'

// components
import ExportBtn from '@/components/ExportBtn.vue'
import FilterByToggle from '@/components/inputs/FilterByToggle.vue'

import { computed, onBeforeMount, onMounted, reactive } from 'vue'

// utils
import { apiRequest } from '@/utils/apiRequest.js'
import showMessage from '@/utils/errorMessage.js'
import { fileDownload } from '@/utils/fileDownload.js'
import showMessageArray from '@/utils/errorMessageArray.js'
import setPageTitle from '@/utils/pageTitle'

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()
const { dispatch, commit } = store

const { handleChargersDownload } = useChargers()

const { handleFacilityDownload } = useFacilitySessions()

const { windowWidth } = useWindowWidth()

const state = reactive({
    isFiltersVisible: false,
    isLoading: false
})

const tab = computed({
    get() {
        return store.state.settings.chargerManagement.tab
    },
    set(tabIndex) {
        commit('settings/setTab', {
            tabIndex
        })
    }
})

const isHomeChargersFleetManager = computed(() => {
    return store.state.account.roles.includes(Roles.fleetHomeChargingManager)
})

const isPayer = computed(() => {
    return store.state.account.roles.includes(Roles.payer)
})

const tabs = computed(() => {
    let tabs = [{ content: 'ChargersList', title: t('chargerManagement.Chargers') }]

    if (isPayer.value) {
        tabs.unshift({ content: 'LocationsList', title: t('chargerManagement.Locations') })
        tabs.push({ content: 'ConnectorsList', title: t('chargerManagement.Connectors') })
        tabs.push({ content: 'FacilitySessionsList', title: t('chargerManagement.MyChargersSessions') })
    }

    if (isHomeChargersFleetManager.value || hasHomeChargers.value) {
        tabs.push({ content: 'ConsumptionList', title: t('chargerManagement.Consumption') })
    }

    return tabs
})

const changeTab = () => {
    state.isFiltersVisible = false
}

const handleExportDownload = async (url, getter, type) => {
    state.isLoading = true
    const selectedFilters = store.getters[getter]
    await apiRequest(url, {
        params: { filters: selectedFilters, type },
        paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
        responseType: 'blob'
    })
        .then((response) => {
            if (response.data.error) {
                showMessage(response.data.error)
            } else {
                fileDownload(response.data, 'Consumptions', type)
            }
        })
        .catch((err) => {
            if (typeof err.message !== 'undefined' && typeof err.message === 'string') {
                showMessage(err.message)
            } else {
                showMessageArray(err.response.data.errors)
            }
        })
        .finally(() => {
            state.isLoading = false
        })
}

const handleHomeCharging = (homeChargers) => {
    // if null return
    if (!homeChargers) {
        return
    }
    switch (homeChargers.length) {
        case 1:
            router.push(`/charger/${homeChargers[0]}`)
        case 0:
        case 2:
        default:
            return
    }
}

const hasHomeChargers = computed(() => {
    return store.state.account.homeChargers && store.state.account.homeChargers.length
})

onBeforeMount(() => {
    // If is Payer - needs to be homecharger manager
    if (isHomeChargersFleetManager.value || hasHomeChargers.value) {
        handleHomeCharging(store.state.account.homeChargers)
    }
})

const getDownloadCallback = (type, tabContent) => {
    switch (tabContent) {
        case 'FacilitySessionsList':
            return handleFacilityDownload(type)
        case 'ChargersList':
            return handleChargersDownload(type)
        case 'ConsumptionList':
            return handleExportDownload('client/consumption/export', 'consumptions/selectedFilters', type)
        case 'LocationsList':
        case 'ConnectorsList':
        default:
            return null
    }
}

const isExportAvailable = (tabContent) => {
    switch (tabContent) {
        case 'FacilitySessionsList':
        case 'ChargersList':
        case 'ConsumptionList':
            return true
        case 'LocationsList':
        case 'ConnectorsList':
        default:
            return false
    }
}

onMounted(() => {
    setPageTitle(t('chargerManagement.ChargerManagement'))
    // we don't need to load this again if it exists -> to minimize requests
    if (!store.state.chargers.filters) dispatch('chargers/getFilters')
    if (!store.state.locations.filters) dispatch('locations/getFilters')
    if (!store.state.connectors.filters) dispatch('connectors/getFilters')
    if (!store.state.consumptions.filters && isHomeChargersFleetManager.value) dispatch('consumptions/getFilters')
})
</script>

<script>
import ChargersList from '@/pages/chargerManagement/components/ChargersList.vue'
import LocationsList from '@/pages/chargerManagement/components/LocationsList.vue'
import ConnectorsList from '@/pages/chargerManagement/components/ConnectorsList.vue'
import FacilitySessionsList from '@/pages/chargerManagement/components/FacilitySessionsList.vue'
import ConsumptionList from '@/pages/chargerManagement/components/ConsumptionsList.vue'

export default {
    components: {
        ChargersList,
        LocationsList,
        ConnectorsList,
        FacilitySessionsList,
        ConsumptionList
    }
}
</script>

<template>
    <div class="chargers">
        <header class="chargers__header pb-0 pt-6 px-md-8 pt-md-8">
            <div class="chargers__header-head">
                <h1 class="home-page-title mb-6">
                    {{ t('chargerManagement.ChargerManagement') }}
                </h1>
            </div>
            <div class="d-flex justify-space-between">
                <v-tabs v-model="tab" background-color="transparent" class="chargers__tabs">
                    <v-tab v-for="{ title } in tabs" :key="title" @click="changeTab()">
                        {{ title }}
                    </v-tab>
                </v-tabs>
                <div class="chargers__options d-flex align-center">
                    <template v-for="({ content }, index) in tabs">
                        <ExportBtn
                            v-if="tab === index && isExportAvailable(content)"
                            :loading="state.isLoading"
                            @handleDownload="getDownloadCallback($event, content)"
                        />
                    </template>
                    <FilterByToggle
                        :active="state.isFiltersVisible"
                        @change="state.isFiltersVisible = !state.isFiltersVisible"
                    />
                </div>
            </div>
        </header>
        <main class="chargers__main">
            <v-window v-model="tab" :touch="false">
                <v-window-item v-for="item in tabs" :key="item.title" :transition="false">
                    <component
                        :is="item.content"
                        :is-filters-visible="state.isFiltersVisible"
                        :window-width="windowWidth"
                    />
                </v-window-item>
            </v-window>
        </main>
    </div>
</template>
