import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import qs from 'qs'

// Utils
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'
import { fileDownload } from '@/utils/fileDownload'
import showMessageArray from '@/utils/errorMessageArray'

// Composables
import useWindowWidth from './windowWidth'
import useFilterableList from './filterableList'
import useDetailsCard from './detailsCard'

// Translations
import { useI18n } from 'vue-i18n'

const { getters, state, commit } = store

export default function useSessions() {
    const {
        isCardView,
        isTable,
        isFiltersVisible,
        filtersSelected,
        exportFilters,
        options,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting
    } = useFilterableList({
        filters: {
            ac_dc_categories: null,
            connector_type_code: null,
            invoice_status: null,
            running_session: null,
            location_id: null,
            driver_id: null,
            drivers: [],
            invoice_id: null,
            price: {
                min: null,
                max: null
            },
            is_roaming: null,
            total_minutes: {
                min: null,
                max: null
            },
            total_kwh: {
                min: null,
                max: null
            },
            max_power: {
                min: null,
                max: null
            },
            connector_max_power: {
                min: null,
                max: null
            },
            average_power: {
                min: null,
                max: null
            },
            date: {
                from: null,
                to: null
            },
            charger_type: null
        },
        options: {
            sortBy: ['connected_from'],
            sortDesc: false
        },
        action: 'sessions/getSessions'
    })

    const { t } = useI18n()

    const { windowWidth, setWindowWidth } = useWindowWidth()

    const { isPickedItem, pickedItem, selectedRow, showDetails, closeDetails, rowSelect, highlightElement } =
        useDetailsCard()

    const isCustomVisible = ref(false)
    const isSessionsExportLoading = ref(false)
    const selected = ref([])
    const actions = ref([
        {
            title: t('invoices.DownloadInvoice'),
            action: 'Download invoice',
            icon: 'mdi-download'
        },
        {
            title: t('invoices.DownloadListOfSessions'),
            action: 'Download list of sessions',
            icon: 'mdi-file-download-outline'
        }
    ])

    const headers = computed(() => {
        return getters['sessions/tableHeaders']
    })
    const sessions = computed(() => {
        return getters['sessions/sessions']
    })
    const isCharging = computed(() => {
        let data = []
        sessions.value.forEach((el) => {
            if (el.charging_to === null) {
                data.push(el)
            }
        })
        return data
    })
    const pagination = computed(() => {
        return state.sessions.pagination
    })
    const countSelectedItems = computed(() => {
        return selected.value.length
    })
    const isLoading = computed(() => {
        return getters['isLoading']
    })

    function checkChargingToRowClass(item) {
        if (typeof item !== 'undefined' && !item.charging_to) {
            return 'highlight-row'
        }
    }

    async function handleSessionsDownload(type) {
        isSessionsExportLoading.value = true
        return await apiRequest('client/sessions/export', {
            params: {
                filters: exportFilters.value,
                type
            },
            responseType: 'blob',
            paramsSerializer: (params) => qs.stringify(params, { skipNulls: true })
        })
            .then((response) => {
                if (typeof response.data.error !== 'undefined' && response.data.error) {
                    showMessage(response.data.error)
                } else {
                    fileDownload(response.data, 'Sessions', type)
                }
            })
            .catch((error) => {
                showMessageArray(error.response.data.errors)
            })
            .finally(() => {
                isSessionsExportLoading.value = false
            })
    }

    onMounted(() => {
        commit('sessions/setSessions', [])
    })

    return {
        isPickedItem,
        isTable,
        isFiltersVisible,
        isCustomVisible,
        isCardView,
        isSessionsExportLoading,
        windowWidth,
        selected,
        selectedRow,
        pickedItem,
        filtersSelected,
        options,
        actions,
        headers,
        sessions,
        isCharging,
        pagination,
        countSelectedItems,
        isLoading,
        checkChargingToRowClass,
        handleSessionsDownload,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting,
        showDetails,
        closeDetails,
        rowSelect,
        highlightElement,
        setWindowWidth
    }
}
