<script setup>
import { ref, computed, watch, onMounted, onBeforeMount } from 'vue'
import store from '@/store'

// utils & configs
import { useI18n } from 'vue-i18n'
import markdownParser from '@/utils/markdownParser'
import { PaymentProviders } from '@/utils/paymentProviders'

// components
import Popup from '@/components/modals/Popup.vue'
import PaymentCard from '@/components/PaymentCard.vue'
import InfoMessage from '@/components/InfoMessage.vue'

// composables
import usePayout from '@/composables/usePayout'
import usePaymentMethod from '@/composables/registration/usePaymentMethod'

const { state, getters, dispatch } = store
const { t } = useI18n()

const PENDING_CHECKOUT_INTERVAL = import.meta.env.VITE_APP_PENDING_CHECKOUT_INTERVAL

const {
    popupData,
    isPopupAskDelete,
    removeCard,
    continueRegistration,
    paymentInfo,
    loadPaymentInfo,
    loadPaymentMethods
} = usePaymentMethod()

const { checkStatus, addPaymentCard, checkoutStatus, checkingStatus } = usePayout()

const interval = ref(null)

const cardHoldAmount = computed(
    () =>
        `${import.meta.env.VITE_APP_BRAINTREE_VERIFY_AMOUNT}${
            import.meta.env[`VITE_APP_I18N_CURRENCY_${state.account.locale.toUpperCase()}`]
        }`
)
const paymentGatewayProvider = computed(() => getters['account/paymentGatewayProvider'])
const isLoading = computed(() => {
    return getters['isLoading']
})

watch(checkoutStatus, (val) => {
    if (val === 'pending') {
        interval.value = setInterval(() => {
            checkStatus()
            if (getters['account/paymentInfo'].provider) clearInterval(interval.value)
        }, PENDING_CHECKOUT_INTERVAL)
        return
    }
    clearInterval(interval.value)
    dispatch('account/getPaymentInfo')
})
const showPopup = () => {
    isPopupAskDelete.value = true
}

onBeforeMount(() => {
    if (paymentGatewayProvider.value === PaymentProviders.Payout) checkStatus()
})

onMounted(async () => {
    await loadPaymentInfo()
    if (paymentGatewayProvider.value === PaymentProviders.Braintree && !paymentInfo.value.provider)
        await loadPaymentMethods()
})
</script>

<template>
    <div class="payment-method">
        <div class="heading text-center mb-6 mx-auto">
            <h1 class="text-h5 font-weight-bold mb-2">
                {{ t('registration.cardDetails') }}
            </h1>
            <span class="text-body-2">
                {{ t('registration.amountReserved', { amount: cardHoldAmount }) }}
            </span>
        </div>
        <main class="mx-auto">
            <v-row v-if="paymentInfo.provider">
                <v-col
                    v-if="
                        paymentInfo.provider === PaymentProviders.Payout ||
                        paymentInfo.provider === PaymentProviders.Braintree
                    "
                >
                    <PaymentCard :payment-info="paymentInfo" @showPopup="showPopup" />
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col v-if="paymentGatewayProvider === PaymentProviders.Payout">
                    <v-btn
                        color="primary"
                        :loading="isLoading || checkingStatus"
                        class="d-flex mx-auto"
                        elevation="2"
                        data-testid="addPayoutCard"
                        @click="addPaymentCard"
                    >
                        <v-icon class="mr-1"> mdi-credit-card </v-icon>
                        {{ t('account.AddPaymentCard') }}
                    </v-btn>
                    <h4 v-if="checkoutStatus === 'pending'" class="text-center pt-4">
                        {{ t('account.pendingCheckoutMessage') }}
                    </h4>
                </v-col>
                <v-col v-else>
                    <div id="dropin-container" class="my-4" data-testid="braintreeComponent" />
                </v-col>
            </v-row>
            <Popup
                :popup-data="popupData.questionDelete"
                :active="isPopupAskDelete"
                :loading="isLoading"
                @cancel="isPopupAskDelete = false"
                @accept="removeCard"
            />
            <InfoMessage v-if="paymentGatewayProvider === PaymentProviders.Braintree" class="mt-6 mb-4">
                <div class="info-panel blue lighten-4 blue--text" data-testid="paypalDisclaimer">
                    <div class="text-caption">
                        <div v-html="markdownParser(t('managePayment.providingCardDetails'))" />
                    </div>
                </div>
            </InfoMessage>
            <InfoMessage v-if="paymentGatewayProvider === PaymentProviders.Payout" class="mt-6 mb-4">
                <div class="info-panel blue lighten-4 blue--text" data-testid="paypalDisclaimer">
                    <div class="text-caption">
                        <div v-html="markdownParser(t('managePayment.providingCardDetailsPayout'))" />
                    </div>
                </div>
            </InfoMessage>
            <v-btn
                class="d-flex mx-auto"
                rounded
                color="primary"
                large
                :loading="isLoading"
                data-testid="nextStep"
                @click="continueRegistration"
            >
                {{ t('registrationWizard.SaveAndNext') }}
            </v-btn>
        </main>
    </div>
</template>
