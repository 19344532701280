<script setup>
import { ref, onMounted, onBeforeMount, watch } from 'vue'
import store from '@/store'

// composables
import useSessions from '@/composables/sessions'

// components
import Table from '@/components/Table.vue'
import SessionDetail from './SessionDetail.vue'
import SessionsFilters from './SessionsFilters.vue'

// translations
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
const { t } = useI18n()

const { dispatch } = store

const props = defineProps({
    showSessionsFilter: {
        type: Boolean,
        required: false,
        default: () => {
            return true
        }
    },
    inline: {
        type: Boolean,
        default: false
    },
    isHeader: {
        type: Boolean,
        default: true
    },
    hideDetails: {
        type: Boolean,
        default: false
    },
    driverId: {
        type: Array,
        default: null
    },
    invoiceId: {
        type: Number,
        default: () => {
            return null
        }
    },
    isDriverDetail: {
        type: Boolean,
        required: false,
        default: false
    }
})

const {
    sessions,
    pickedItem,
    isTable,
    windowWidth,
    options,
    getItems,
    highlightElement,
    setFilter,
    isCardView,
    isFiltersVisible,
    isPickedItem,
    headers,
    checkChargingToRowClass,
    pagination,
    showDetails,
    sorting,
    filtersSelected,
    getDefaultFilters,
    clearFilters,
    closeDetails
} = useSessions()

const loadindData = ref(false)

watch(sessions, () => {
    pickedItem.value = null
})
watch(windowWidth, () => {
    isTable.value = !(windowWidth.value < 600 && pickedItem.value)
})
watch(options, async () => {
    loadindData.value = true
    await getItems()
    loadindData.value = false
})
watch(pickedItem, () => {
    isTable.value = !(windowWidth.value < 600 && pickedItem.value)
    highlightElement(sessions.value.indexOf(pickedItem.value) || null)
})

onBeforeMount(() => {
    const route = useRoute()

    // we need to set it, but dont trigger the call
    if (props.hideDetails) {
        setFilter({ field: 'driver_id', value: props.driverId })
    }
    if (route.query.invoiceId) {
        setFilter({ field: 'invoice_id', value: route.query.invoiceId })
        // router.replace({'query': null})
    }
})
onMounted(async () => {
    dispatch('filters/getSessionsFilters')
    dispatch('filters/getFilterLocation', {
        value: null
    })
})
</script>

<template>
    <div class="my-charging-sessions pa-0">
        <v-row class="my-charging-sessions__header ma-0 d-none">
            <v-col class="px-4 px-sm-0 py-2 py-sm-0" cols="12">
                <div v-if="windowWidth < 960" class="grid-switcher py-5 px-5 bg-white border-b text-center">
                    <v-btn
                        :class="{ active: isCardView }"
                        :ripple="false"
                        class="active-button px-0 mr-3"
                        color="grey"
                        elevation="1"
                        icon
                        variant="outlined"
                        @click="isCardView = true"
                    >
                        <v-icon class="active-icon"> mdi-view-grid </v-icon>
                    </v-btn>
                    <v-btn
                        :class="{ active: !isCardView }"
                        :ripple="false"
                        class="active-button px-0"
                        color="grey"
                        elevation="1"
                        icon
                        variant="outlined"
                        @click="isCardView = false"
                    >
                        <v-icon class="active-icon"> mdi-view-sequential </v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <SessionsFilters
            v-if="isFiltersVisible"
            :default-filters="getDefaultFilters()"
            :filters-selected="filtersSelected"
            :is-driver-detail="isDriverDetail"
            @clearFilters="clearFilters"
            @getItems="getItems"
            @setFilter="setFilter"
        />
        <div
            ref="my-charging-sessions-table"
            :class="isPickedItem ? 'sessions__table-container-extended' : 'sessions__table-container'"
        >
            <Table
                :card-view="isCardView"
                :class="{ 'table-view-mobile-size': !isCardView }"
                :headers="headers"
                :is-descending="options.sortDesc ? options.sortDesc : true"
                :item-class="checkChargingToRowClass"
                :items="sessions"
                :pagination="pagination"
                :data-loading="loadindData"
                :server-items="pagination.itemsLength"
                :sort-column-name="options.sortBy[0]"
                class="my-charging-sessions__table"
                @options="(v) => (options = v)"
                @sorting="sorting"
                data-testid="sessionsTable"
            >
                <template v-slot:connected_from="props">
                    <span data-testid="connectedFrom">{{ props.item.connected_from }}</span>
                </template>
                <template v-slot:connected_to="props">
                    <template v-if="!props.item.connected_to">
                        <span data-testid="chargingTo" class="text-primary">
                            {{ t('driverInfo.InProgress') }}
                        </span>
                        <v-progress-linear
                            :height="7"
                            :rounded="true"
                            color="primary"
                            indeterminate
                            data-testid="charging"
                        />
                    </template>
                    <template v-else>
                        <span data-testid="connectedTo">{{ props.item.connected_to }}</span>
                    </template>
                </template>
                <template v-slot:total_minutes="props">
                    <template v-if="!props.item.charging_to">
                        <span data-testid="chargingTo" class="text-primary">{{ t('driverInfo.InProgress') }}</span>
                        <v-progress-linear
                            v-if="!props.item.charging_to && headers.length === 9"
                            :height="7"
                            :rounded="true"
                            color="primary"
                            indeterminate
                            data-testid="chargingTo"
                        />
                    </template>
                    <template v-else>
                        <span data-testid="totalMinutes">{{ props.item.total_minutes }}</span>
                    </template>
                </template>
                <template v-slot:total_kwh="props">
                    <template v-if="!props.item.charging_to">
                        <span>-</span>
                    </template>
                    <template v-else>
                        <span data-testid="totalKwh">{{ props.item.total_kwh }}</span>
                    </template>
                </template>
                <template v-slot:free_kwh="props">
                    <template v-if="!props.item.charging_to">
                        <span data-testid="totalFreeKwh">-</span>
                    </template>
                    <template v-else>
                        <span data-testid="totalFreeKwh">{{ props.item.free_kwh }}</span>
                    </template>
                </template>
                <template v-slot:connector_max_power="props">
                    <span data-testid="connectorMaxPower">{{ props.item.connector_max_power }}</span>
                </template>
                <template v-slot:detail="props">
                    <v-btn
                        data-testid="showDetails"
                        color="primary"
                        icon="mdi-magnify"
                        variant="text"
                        @click="showDetails(props.item)"
                    />
                </template>
            </Table>
            <div data-testid="discount" class="px-5 py-2 fees-text">
                {{ t('sessions.DiscountsAndMonthlyFees') }}
            </div>
        </div>
        <v-navigation-drawer
            v-model="isPickedItem"
            :temporary="true"
            :width="windowWidth < 960 ? '100%' : 550"
            class="my-charging-sessions__drawer"
            location="right"
            @update:model-value="closeDetails"
            data-testid="pickedItem"
        >
            <div class="my-charging-sessions__side">
                <SessionDetail data-testid="detail" v-if="isPickedItem" :session="pickedItem" @close="closeDetails" />
            </div>
        </v-navigation-drawer>
    </div>
</template>
