<script setup>
import { useStore } from 'vuex'
import { computed, reactive, onMounted } from 'vue'

// components
import CountrySelect from '@/components/inputs/CountrySelect.vue'

// utils
import { Country } from '@/configs'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty, isValid2Characters, isValidSlovakTax, isValidCroatianTax, maxCharsExceeded } = useValidations()

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { commit } = store

const state = reactive({
    currentBusinessId: store.state.account.businessId,
    canValidateVat: false
})

const account = computed(() => store.state.account)
const accountTemp = computed(() => store.state.settings.accountTemp)
const isEditView = computed(() => store.state.account.personalDataIsEditView)
const isSelfEmployed = computed(() => store.state.account.isSelfEmployed)
const isVatPayer = computed(() => (store.state.account.vatId ? true : false))
const vatId = computed(() => store.state.account.vatId)
const isPolishCompany = computed(() =>
    isEditView.value
        ? accountTemp.value?.country === Country.Poland || false
        : account.value?.country === Country.Poland || false
)
const isSlovakCompany = computed(() =>
    isEditView.value
        ? accountTemp.value?.country === Country.Slovakia || false
        : account.value?.country === Country.Slovakia || false
)
const isCroatianCompany = computed(() =>
    isEditView.value
        ? accountTemp.value?.country === Country.Croatia || false
        : account.value?.country === Country.Croatia || false
)
const companyIdPlaceholder = computed(() => {
    if (isPolishCompany.value) return 'NIP'
    if (isSlovakCompany.value) return 'ICO'
    if (isCroatianCompany.value) return 'OIB'
    return 'CompanyBusinessId'
})
const companyTaxIdPlaceholder = computed(() => {
    if (isCroatianCompany.value) return 'MBS'
    return 'TaxId'
})

const isValidTax = (v) => {
    if (isSlovakCompany.value) return isValidSlovakTax(v)
    if (isCroatianCompany.value) return isValidCroatianTax(v)
    return isValid2Characters(v)
}
const updateAccountTemp = (field, value) => {
    commit('settings/setAccountTempFieldValue', {
        field,
        value
    })
}

onMounted(() => {
    store.subscribeAction({
        after: (action) => {
            if (action.type === 'account/getAccount') {
                state.currentBusinessId = store.state.account.businessId
            }
        }
    })
})
</script>

<template>
    <v-row>
        <v-col class="pa-lg-5 bg-white" cols="12" lg="8" offset-lg="2">
            <v-row>
                <v-col class="d-flex align-items-center">
                    <h2 class="home-page-title">
                        {{ t('personalData.CompanyInformation') }}
                    </h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <CountrySelect :country="account.country_iso3" :is-disabled="true" data-testid="countrySelect" />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="account.businessId"
                        :label="t(`Client.${companyIdPlaceholder}`) + '*'"
                        ref="business_id"
                        disabled
                        data-testid="ico"
                        density="compact"
                        variant="outlined"
                    />
                </v-col>
                <v-col class="py-0" cols="12">
                    <v-text-field
                        :disabled="!isEditView"
                        :label="t('Client.CompanyName') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(200)]"
                        :model-value="isEditView ? accountTemp.companyName : account.companyName"
                        data-testid="companyName"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp('companyName', $event)"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <div class="d-flex flex-column">
                        <v-checkbox
                            v-model="isSelfEmployed"
                            :label="t('Client.IAmSelfEmployedPerson')"
                            disabled
                            data-testid="selfEmplyed"
                        />
                        <v-text-field
                            v-if="!isPolishCompany"
                            v-model="account.taxId"
                            :label="t(`Client.${companyTaxIdPlaceholder}`)"
                            :rules="[isValidTax]"
                            disabled
                            density="compact"
                            variant="outlined"
                            data-testid="taxId"
                        />
                    </div>
                </v-col>
                <v-col v-if="vatId" cols="12" md="6">
                    <div class="d-flex flex-column">
                        <v-checkbox
                            v-model="isVatPayer"
                            disabled
                            :label="t('Client.IAmVatPayer')"
                            data-testid="vatPayer"
                        />
                        <v-text-field
                            v-model="vatId"
                            :label="t('Client.VatId') + '*'"
                            density="compact"
                            variant="outlined"
                            disabled
                            data-testid="isVatPayer"
                        />
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
