// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

// Utils
import { setCookieDomain } from '@/utils/setCookieDomain'
import { apiNoAuth, apiRequest } from '@/utils/apiRequest'

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({ dispatch }, error) => {
        if (
            error.response &&
            typeof error.response.data !== 'undefined' &&
            typeof error.response.data.errors !== 'undefined'
        ) {
            throw error.response.data.errors
        }
        if (typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if (typeof error.errors !== 'undefined') {
                throw error.errors
            } else if (
                typeof error.response !== 'undefined' &&
                typeof error.response.data !== 'undefined' &&
                error.response.data.error
            ) {
                throw error.response.data.error
            } else if (typeof error.message !== 'undefined') {
                throw error.message
            } else if (typeof error.msg !== 'undefined') {
                throw error.msg
            } else if (typeof error.messages !== 'undefined' && error.messages.length) {
                throw error.messages
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, dispatch }, data = {}) => {
        if (data) {
            await apiRequest
                .post('auth/logout', data)
                .then(() => {
                    if (typeof window.$zoho !== 'undefined') {
                        try {
                            window.$zoho.salesiq.reset()
                        } catch (e) {
                            console.log(e)
                        }
                    }
                })
                .catch((error) => {
                    dispatch('checkStatus', error)
                })
                .finally(() => {
                    localStorage.removeItem('vuex')
                })
        }

        commit('resetStore')
        cookies.remove('mobile-token')
    },
    refreshToken: async ({ commit, dispatch }, rememberMe) => {
        commit('setLoader', 1, { root: true })
        const { data } = await apiRequest
            .post('auth/refresh-token', {
                remember_me: rememberMe
            })
            .catch((error) => {
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
        await commit('auth', data)
    },
    auth: async ({ commit, dispatch }, credentials) => {
        commit('setLoader', 1, { root: true })
        if (localStorage.getItem('GW_isGuest')) localStorage.removeItem('GW_isGuest')

        return await apiNoAuth
            .post('auth/login', credentials)
            .then(({ data }) => {
                commit('auth', data)
            })
            .catch((error) => {
                return dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
    },
    authToken: async ({ commit, state }, credentials) => {
        const DOMAIN = import.meta.env.VITE_APP_COOKIES_DOMAIN
        commit('setLoader', 1, { root: true })
        try {
            if (cookies.get('mobile-token') != credentials.token) {
                const { data } = await apiNoAuth.post('auth/token', credentials)
                cookies.set('mobile-token', credentials.token, '8h', '/', DOMAIN)
                //if auth and user is diferent log out old a login new
                if (state.id && state.id != data.id) {
                    await apiRequest.post('auth/logout', { 'current-token': true })
                }
                commit('auth', data)
            }
        } catch (e) {
            cookies.remove('mobile-token')
            const errorStatus = e?.response.status
            const errorMessage = e?.response.data.message
            switch (errorStatus) {
                case 422:
                    throw 'Wrong credentials'
                default:
                    throw errorMessage
            }
        } finally {
            commit('setLoader', -1, { root: true })
        }
    },
    register: async ({ commit, dispatch }, formData) => {
        commit('setLoader', 1, { root: true })
        try {
            const { data } = await apiNoAuth.post('auth/register', formData)
            commit('auth', data)
        } catch (error) {
            dispatch('checkStatus', error)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    },
    getAccount: async ({ commit }) => {
        commit('setLoader', 1, { root: true })
        try {
            const { data } = await apiRequest('client')
            if (Object.keys(data).length !== 0) {
                commit('setAccountData', { ...data, ...data.driver, ...data.payer })

                // need to also save rest of the data
                if (cookies.get('token_urlBearer') === 'true') {
                    const token = {
                        access_token: {
                            token: cookies.get('gw_token'),
                            expires_at: cookies.get('gw_token_expires_at')
                        }
                    }
                    commit('authUrlBearer', { ...data, ...data.payer, ...token })
                    cookies.remove('token_urlBearer', '/', setCookieDomain())
                }

                return data
            }
            return []
        } finally {
            commit('setLoader', -1, { root: true })
        }
    },
    setAccount: async ({ commit, dispatch }, account) => {
        commit('setLoader', 1, { root: true })
        try {
            await apiRequest.put('client', account)
            const { data } = await dispatch('getAccount')
            return data
        } catch (error) {
            dispatch('checkStatus', error)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    },
    getCompanyInfo: async ({ commit, dispatch }, { business_id, country_iso3 }) => {
        commit('setLoader', 1, { root: true })
        return await apiRequest('company-info', {
            params: {
                business_id,
                country_iso3
            }
        })
            .catch((error) => {
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
    },
    braintreeSaveCard: async ({ commit, dispatch }, card) => {
        const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
        // eslint-disable-next-line
        grecaptcha.enterprise.ready(async () => {
            // eslint-disable-next-line
            const recaptchaToken = await grecaptcha.enterprise.execute(recaptchaSiteKey, { action: 'payment_add' })
            try {
                commit('setLoader', 1, { root: true })
                await apiRequest.post('client/braintree/save-card', {
                    ...card,
                    'g-recaptcha-response': recaptchaToken
                })
                await dispatch('getPaymentInfo')
            } catch (error) {
                dispatch('checkStatus', error)
            } finally {
                commit('setLoader', -1, { root: true })
            }
        })
    },
    removeCard: async ({ commit, state, dispatch }, card) => {
        commit('setLoader', 1, { root: true })
        if (state.paymentInfo.provider === 'tpay') {
            await apiRequest
                .post('client/tpay/remove-card')
                .catch((error) => {
                    dispatch('checkStatus', error)
                })
                .finally(() => {
                    commit('setLoader', -1, { root: true })
                })
            return
        }
        await apiRequest
            .post('client/braintree/remove-card', card)
            .catch((error) => {
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
    },
    finishRegistration: async ({ commit, dispatch }, info) => {
        commit('setLoader', 1, { root: true })
        return await apiRequest
            .post('auth/finish-registration', info)
            .then(({ data }) => {
                return data
            })
            .catch((error) => {
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
    },
    getPaymentInfo: async ({ commit, dispatch }) => {
        commit('setLoader', 1, { root: true })
        await apiRequest('client/payment-info')
            .then(({ data }) => {
                commit('setPaymentInfo', data)
            })
            .catch((error) => {
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
    },
    verifyEmail: async ({ commit, dispatch }, info) => {
        commit('setLoader', 1, { root: true })
        await apiRequest
            .post('client/resend-verification-email', info)
            .catch((error) => {
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })

        commit('setLoader', -1, { root: true })
    },
    getNotifyAfterSession: async ({ commit, dispatch }) => {
        commit('setLoader', 1, { root: true })
        try {
            const { data } = await apiRequest('client/setting/notify-after-session')
            if (data.value === null) {
                // because notifications are ON by default
                commit('setNotifyAfterSession', true)
            } else {
                commit('setNotifyAfterSession', data.value)
            }
        } catch (error) {
            dispatch('checkStatus', error)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    },
    setNotifyAfterSession: async ({ commit, dispatch }, payload) => {
        commit('setLoader', 1, { root: true })
        try {
            await apiRequest.post('client/setting/notify-after-session', { value: payload })
            commit('setNotifyAfterSession', payload)
        } catch (error) {
            dispatch('checkStatus', error)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }
}
