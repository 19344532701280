<script setup>
import {computed, reactive} from 'vue'

// Components
import BarGraph from '@/pages/dashboard/components/BarGraph.vue'
import CircleGraph from '@/pages/dashboard/components/CircleGraph.vue'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

// Utils
import {toCamelCase} from '@/utils'

const props = defineProps({
    data: {
        required: true,
        type: Object,
        default: null
    }
})

const state = reactive({
  utilizationPercentEnabled: false
})

const connectorStatuses = computed(() => {
	if(props.data.statuses) {
		let data = []
		Object.keys(props.data.statuses.connector_status).forEach(key => {
			data.push({
				title: t(`dashboard.${toCamelCase(key)}`),
				value: props.data.statuses.connector_status[key]
			})
		})
		return data
	}
	return []
})

const assetStatus = computed(() => {
	if(props.data.statuses) {
		let data = []
		Object.keys(props.data.statuses.asset_status).forEach(key => {
			data.push({
				title: t(`dashboard.${key.toLowerCase()}`),
				value: props.data.statuses.asset_status[key]
			})
		})
		return data
	}
	return []
})

</script>

<template>
    <v-row v-if="data">
        <v-col v-if="data.utilizationPercent && state.utilizationPercentEnabled" cols="12" md="6">
            <p class="card__title mb-5">
                {{ t('dashboard.Utilization') }}
            </p>
            <v-card class="d-flex justify-center pa-6 min-height-300" flat>
                <CircleGraph
                    :percent="data.utilizationPercent"
                    :title="t('dashboard.TimeOfCharging')"
                    :value-text="Number(data.utilizationPercent).toFixed(2) + ' %'"
                />
            </v-card>
        </v-col>
        <v-col cols="12" md="6">
            <p class="card__title mb-5">
                {{ t('dashboard.ChargingSessions') }}
            </p>
            <v-card class="d-flex justify-center vertical-graph min-height-300" flat>
                <BarGraph
                    v-if="data.chargingSessions"
                    :ac-data-field="'ac'"
                    :data="data.chargingSessions"
                    :dc-data-field="'dc'"
                    :name="t('dashboard.ChargingSessions')"
                    :total-data-field="'all'"
                />
            </v-card>
        </v-col>
        <v-col cols="12" md="6">
            <p class="card__title mb-5">
                {{ t('dashboard.TimeSpentCharging') }}
            </p>
            <v-card class="d-flex justify-center vertical-graph min-height-300" flat>
                <BarGraph
                    v-if="data.chargingTime"
                    :ac-data-field="'ac'"
                    :data="data.chargingTime"
                    :dc-data-field="'dc'"
                    :name="t('dashboard.TimeSpentCharging')"
                    :total-data-field="'all'"
                />
            </v-card>
        </v-col>
        <v-col cols="12" md="6">
            <p class="card__title mb-5">
                {{ t('dashboard.ConsumedEnergy') }}
            </p>
            <v-card class="d-flex justify-center vertical-graph min-height-300" flat>
                <BarGraph
                    v-if="data.energy"
                    :ac-data-field="'ac'"
                    :data="data.energy"
                    :dc-data-field="'dc'"
                    :name="t('dashboard.ConsumedEnergy')"
                    :total-data-field="'all'"
                />
            </v-card>
        </v-col>
        <v-col cols="12" md="6"></v-col>
	    <v-col cols="12" md="6">
		    <p class="card__title mb-5">
			    {{ t('dashboard.ConnectorsStatus') }}
		    </p>
		    <v-card color="min-height-300" flat>
			    <v-card-text>
				    <v-row class="pb-3">
					    <v-col v-for="status in connectorStatuses" :key="status.title" md="6">
						    <p class="ma-0">
							    {{ status.title }}:
							    <span class="font-weight-bold">
		                            {{ status.value }}
	                            </span>
						    </p>
					    </v-col>
				    </v-row>
			    </v-card-text>
		    </v-card>
	    </v-col>
	    <v-col cols="12" md="6">
		    <p class="card__title mb-5">
			    {{ t('dashboard.AssetStatus') }}
		    </p>
		    <v-card color="min-height-300" flat>
			    <v-card-text>
				    <v-row class="pt-3">
					    <v-col v-for="status in assetStatus" :key="status.title" md="6">
						    <p class="ma-0">
							    {{ status.title }}:
							    <span class="font-weight-bold">
		                            {{ status.value }}
	                            </span>
						    </p>
					    </v-col>
				    </v-row>
			    </v-card-text>
		    </v-card>
	    </v-col>
    </v-row>
</template>