<script setup>
import { computed, ref } from 'vue'

// store
import store from '@/store'
const { getters } = store

// translations
const { t } = useI18n()
import { useI18n } from 'vue-i18n'

// utils
import { currencies } from '@/utils/currencies'
import { PaymentProviders } from '@/utils/paymentProviders'
import { PayoutCheckoutStatus } from '@/utils/payoutCheckoutStatus'

// composables
import useCharging from '@/composables/charging/useCharging'

// components
import InfoMessage from '@/components/InfoMessage.vue'

const {
    activeScreen,
    instanceRequestPaymentMethod,
    blockInstanceRequestPaymentMethodButton,
    oneTimeFees,
    selectedOneTimeFee,
    oneTimeProvider,
    oneTimePreAutorization,
    checkoutStatus,
    toCurrentPayoutCheckout,
    cancelPayoutCheckout
} = useCharging()

const returnsPolicy = ref(false)

// DON'T CHANGE TO VITE_APP, because on production we have keys VUE_APP - REPLACED BY NEW TRANSLATIONS
const generalTermsAndConditionsLinks = computed(() => t('otCard.VITE_APP_GTC'))
const currency = computed(() => {
    return getters['account/locale'] === 'pl' ? currencies.PLN : currencies.EUR
})

const setSelectedFee = async (val) => {
    selectedOneTimeFee.value = val
    if (oneTimeProvider.value === PaymentProviders.Payout) await oneTimePreAutorization()
}
</script>

<template>
    <v-card
        :elevation="3"
        class="start-charging mr-auto ml-auto mt-md-8 mt-2 pa-4 d-flex flex-column align-center justify-center text-center"
    >
        <v-btn class="d-flex mr-auto pa-0" variant="plain" width="60px" @click.prevent="activeScreen = 0">
            <v-icon> mdi-chevron-left </v-icon>
            {{ t('startCharging.back') }}
        </v-btn>
        <h3 class="text-h6 text-center mb-2 mt-4">
            {{ t('startCharging.cardDetails') }}
        </h3>
        <template v-if="checkoutStatus === PayoutCheckoutStatus.Pending">
            <v-btn :variant="'flat'" color="primary" class="mb-6" @click="toCurrentPayoutCheckout"
                >{{ t('startCharging.tryAgain') }}
            </v-btn>
            <v-btn color="error" variant="plain" @click="cancelPayoutCheckout" :loading="getters['isLoading']">
                {{ t('startCharging.cancelPyoutCheckout') }}
            </v-btn>
        </template>
        <template v-else>
            <p class="mb-8">
                {{ t('startCharging.pleaseSelectFee') }}
            </p>
            <v-row class="mb-8">
                <v-col v-for="fee in oneTimeFees" :key="fee">
                    <v-btn
                        :variant="selectedOneTimeFee === fee ? 'flat' : 'outlined'"
                        color="primary"
                        :disabled="getters['isLoading']"
                        @click="setSelectedFee(fee)"
                        :data-testid="`one-time-fee-${fee}`"
                        >{{ fee }}{{ currency }}
                    </v-btn>
                </v-col>
            </v-row>
            <template v-if="oneTimeProvider === PaymentProviders.Payout">
                <InfoMessage class="my-2 text-left">
                    <div class="info-panel blue lighten-4 blue--text">
                        <div class="text-caption">
                            <a
                                :href="generalTermsAndConditionsLinks"
                                target="_blank"
                                data-testid="iAgreeOTtext"
                                @click.stop
                                >{{ t('startCharging.gtcApproval') }}</a
                            >
                        </div>
                    </div>
                </InfoMessage>
            </template>
            <template v-if="oneTimeProvider === PaymentProviders.Braintree">
                <v-checkbox
                    v-model="returnsPolicy"
                    data-testid="iAgreeOT"
                    class="mr-auto d-inline-flex text-left"
                    hide-details
                >
                    <template v-slot:label>
                        <a
                            :href="generalTermsAndConditionsLinks"
                            target="_blank"
                            data-testid="iAgreeOTtext"
                            @click.stop
                        >
                            {{ '*' + t(`startCharging.returnsPolicy`) }}
                        </a>
                    </template>
                </v-checkbox>
                <v-container class="my-4 pa-0" fluid>
                    <div id="dropin-container" class="mb-4" />
                </v-container>
                <v-btn
                    :disabled="!returnsPolicy || blockInstanceRequestPaymentMethodButton || !selectedOneTimeFee"
                    :loading="blockInstanceRequestPaymentMethodButton"
                    color="blue"
                    rounded="xl"
                    data-testid="paymentSubmit"
                    @click.prevent="instanceRequestPaymentMethod()"
                >
                    {{ t('startCharging.submit') }}
                </v-btn>
            </template>
        </template>
    </v-card>
</template>
