<script setup>
import { computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const { getters } = store

const emit = defineEmits(['countSelectedItems', 'clickRow', 'changePage', 'sorting', 'options', 'updateSelected'])

const props = defineProps({
    model: {
        type: Array,
        default: () => []
    },
    showSelect: {
        type: Boolean,
        default: false
    },
    singleSelect: {
        type: Boolean,
        default: false
    },
    headers: {
        type: Array
    },
    items: {
        type: Array
    },
    itemClass: {
        type: Function,
        required: false,
        default: () => {
            return
        }
    },
    itemKey: {
        type: String,
        default: 'number'
    },
    sortColumnName: {
        type: String,
        default: 'id'
    },
    isDescending: {
        type: Boolean,
        default: false
    },
    pagination: {
        type: Object,
        default: null
    },
    footerProps: {
        type: Object,
        default: null
    },
    serverItems: {
        type: Number,
        default: null
    },
    dataLoading: {
        type: Boolean,
        required: false
    }
})

const state = reactive({
    itemsPerPageOptions: [
        { value: 5, title: '5' },
        { value: 10, title: '10' },
        { value: 15, title: '15' }
    ],
    page: 1,
    defaultSortColumn: props.sortColumnName,
    isSortDescending: props.isDescending,
    selectedItems: []
})

const isLoading = computed(() => {
    return getters['isLoading']
})

watch(props.model, () => {
    emit('countSelectedItems', props.model)
})

const changePage = (value) => {
    state.page = value
    emit('changePage', value)
}

const updateSelectedItems = (val) => {
    emit('updateSelected', val)
}

const options = (val) => {
    const opt = {
        page: 1,
        limit: 10,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: false,
        sort: {
            field_name: '',
            type: ''
        }
    }
    opt.page = state.page
    opt.limit = val.itemsPerPage
    if (!val.sortBy[0]) {
        opt.sortBy[0] = state.defaultSortColumn
        opt.sortDesc = false
    }
    opt.sort = {
        field_name: val.sortBy[0] ? val.sortBy[0].key : state.defaultSortColumn,
        type: val.sortBy[0] ? val.sortBy[0].order.toUpperCase() : 'DESC'
    }
    emit('sorting', opt)
    emit('options', opt)
}
</script>

<template>
    <v-data-table-server
        v-model="state.selectedItems"
        :headers="headers"
        :items="items"
        :items-length="serverItems"
        :loading="isLoading || dataLoading"
        :show-select="showSelect"
        :items-per-page-options="state.itemsPerPageOptions"
        return-object
        @update:model-value="updateSelectedItems"
        @update:page="changePage"
        @update:options="options"
    >
        <template v-slot:[`item.detail`]="row">
            <slot :item="row.item" name="detail" />
        </template>
        <template v-slot:[`item.driver_detail`]="row">
            <slot :item="row.item" name="driver_detail" />
        </template>
        <template v-slot:[`item.name`]="row">
            <slot :item="row.item" name="name" data-testid="name" />
        </template>
        <template v-slot:[`item.code`]="row">
            <slot :item="row.item" name="code" data-testid="code" />
        </template>
        <template v-slot:[`status`]="row">
            <slot :item="row.item" name="status" data-testid="status" />
        </template>
        <template v-slot:[`item.email`]="row">
            <slot :item="row.item" name="email" data-testid="email" />
        </template>
        <template v-slot:[`item.phone`]="row">
            <slot :item="row.item" name="phone" data-testid="phone" />
        </template>
        <template v-slot:[`item.vehicle`]="row">
            <slot :item="row.item" name="vehicle" data-testid="vehicle" />
        </template>
        <template v-slot:[`item.priceProgram`]="row">
            <slot :item="row.item" name="priceProgram" data-testid="priceProgram" />
        </template>
        <template v-slot:[`item.charger_code`]="row">
            <slot :item="row.item" name="charger_code" data-testid="chargerCode" />
        </template>
        <template v-slot:[`item.charger_label`]="row">
            <slot :item="row.item" name="charger_label" data-testid="chargerLabel" />
        </template>
        <template v-slot:[`item.manufacture`]="row">
            <slot :item="row.item" name="manufacture" data-testid="manufacturer" />
        </template>
        <template v-slot:[`item.connected_from`]="row">
            <slot :item="row.item" name="connected_from" data-testid="connectedFrom" />
        </template>
        <template v-slot:[`item.connected_to`]="row">
            <slot :item="row.item" name="connected_to" data-testid="connectedTo" />
        </template>
        <template v-slot:[`item.total_minutes`]="row">
            <slot :item="row.item" name="total_minutes" data-testid="totalMinutes" />
        </template>
        <template v-slot:[`item.total_kwh`]="row">
            <slot :item="row.item" name="total_kwh" data-testid="totalKwh" />
        </template>
        <template v-slot:[`item.free_kwh`]="row">
            <slot :item="row.item" name="free_kwh" data-testid="freeKwh" />
        </template>
        <template v-slot:[`item.connector_max_power`]="row">
            <slot :item="row.item" name="connector_max_power" data-testid="connectorMaxPower" />
        </template>
        <template v-slot:[`item.actions`]="row">
            <slot :item="row.item" name="actions" data-testid="actions" />
        </template>
        <template v-slot:[`item.actionButtons`]="row">
            <slot :item="row.item" name="actionButtons" data-testid="actionButtons" />
        </template>
        <template v-slot:[`item.status_key`]="row">
            <slot :item="row.item" name="status_key" data-testid="statusKey" />
        </template>
        <template v-slot:[`item.freeKWh`]="row">
            <slot :item="row.item" name="freeKWh" data-testid="freeKwh" />
        </template>
    </v-data-table-server>
</template>
