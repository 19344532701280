<script setup>
import { onMounted } from 'vue'

onMounted(() => {
    window.addEventListener('lcw:ready', function handleLivechatReadyEvent() {
        // Handle LiveChat SDK ready event
        // SDK methods are ready for use now
        // Initiate a chat using startChat SDK
        window.Microsoft.Omnichannel.LiveChatWidget.SDK.startChat()
    })

    window.addEventListener('lcw:error', function handleLivechatErrorEvent(errorEvent) {
        // Handle LiveChat error event
        console.log(errorEvent)
    })
})
</script>

<template>
    <div class="live-chat"></div>
</template>
