import { reactive, ref, toRefs, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import store from '@/store'

// utils & configs
import { useI18n } from 'vue-i18n'
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'
import { ConnectorStatus } from '@/utils/connectorStatus'
import errorMessageArray from '@/utils/errorMessageArray'
import { getConnectorParamFromQuery } from '@/utils/connectors.js'
import { ChargingStatus, ChargingCommandName } from '@/configs'
import { PaymentProviders } from '@/utils/paymentProviders'
import { PayoutCheckoutStatus } from '@/utils/payoutCheckoutStatus'

// components
import dropIn from 'braintree-web-drop-in'

// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

// composables
import useLocale from '@/composables/locale'
import useSessionSummary from '@/composables/charging/useSessionSummary'

const CHARGING_COMMAND = parseInt(import.meta.env.VITE_APP_CHARGING_COMMAND) || 5000
const END_CHARGING = parseInt(import.meta.env.VITE_APP_END_CHARGING) || 3000
const CHARGING = parseInt(import.meta.env.VITE_APP_CHARGING) || 3000
const CHARGING_DETAIL = parseInt(import.meta.env.VITE_APP_CHARGING_DETAIL) || 60000
const SESSION_SUMMARY = parseInt(import.meta.env.VITE_APP_SESSION_SUMMARY) || 15000
const DOMAIN = import.meta.env.VITE_APP_COOKIES_DOMAIN
const CHECKOUT_STATUS = 10000

const { state, getters, commit, dispatch } = store
const { receiptEmail } = useSessionSummary()

const globalState = reactive({
    connectorAvailable: false,
    isUnknownConnectorStatus: false,
    isLoggedIn: false,
    activeScreen: null,
    chargingStarted: false,
    chargingStopped: false,
    chargingDetails: null,
    noCommunication: false,
    sessionEnded: false,
    sessionTerminated: false,
    selectedDriver: null,
    hasConnectorData: false,
    connectorData: null,
    connectorNotFound: false,
    guestToken: null,
    elapsedTime: {
        label: '',
        value: '',
        unit: ''
    },
    sessionSummary: null,
    fetchingOTChargingSummary: false,
    fetchingChargingDetails: false,
    guestClosed: false,
    blockingUserInput: false,
    commandMessage: '',
    stopChargingPromptVisible: false,
    hasOtheRunningSession: false,
    errorMessage: null,
    isDriverAccount: false,
    oneTimeFees: [],
    selectedOneTimeFee: null,
    oneTimeProvider: null,
    oneTimePreAuthStatus: null,
    checkoutStatus: null,
    checkoutUrl: null
})

const commandId = ref(null)
const lastCommandName = ref()
const lastCommandConnectorId = ref()
const lastCommandConnectorCode = ref()
const chargingCommandStatusEnum = ref('')
const chargingCommandMessage = ref('')
const chargingCommandIsFinalState = ref(false)
const isDisabled = ref(false)
const visibleOfflineDetails = ref(false)
const chargingDetailsInterval = ref()
const sessionSummaryInterval = ref()
const dropinInstance = ref(null)
const blockInstanceRequestPaymentMethodButton = ref(false)
const timeout = ref(null)
const checkoutStatusTimeout = ref(null)
const startChargingCommandInterval = ref(null)
const stopChargingCommandInterval = ref(null)
const endChargingSessionCommandInterval = ref(null)
const loadingRecaptcha = ref(false)
const fetchingCommandId = ref(false)

const driverBlocked = computed(() => (getters['account/isBlocked'] ? true : false))
const fetchingData = computed(() => getters['isLoading'])
const isGuest = computed(() => (!globalState.isLoggedIn && globalState.guestToken !== null ? true : false))
const drivers = computed(() => getters['drivers/drivers'])

const sanitizeEnum = (value) => {
    return value.toLowerCase().trim()
}

export default function useStartCharging() {
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()

    const attrs = reactive({
        class: 'mr-auto ml-auto mt-md-8 mt-2 pa-4',
        elevation: 3
    })

    const pages = ref([
        'add-driver',
        'driverDetails',
        'chargerDetails',
        'locationDetails',
        'pricePrograms',
        'driverPriceProgram'
    ])

    const getPriceListUrl = computed(() => {
        return t('footer.VITE_APP_PRICE_LIST_LINK')
    })

    const cleanUpGuestUserDataAndRedirectToMap = () => {
        cookies.remove('gw_token', '/', DOMAIN)
        localStorage.removeItem('GW_isGuest')
        window.location.href = import.meta.env.VITE_APP_LOCATION_MAP_IFRAME_URL
    }

    const cancelGuestPayoutSession = () => {
        cookies.remove('gw_token', '/', DOMAIN)
        localStorage.removeItem('GW_isGuest')
        globalState.activeScreen = 0
    }

    const setGuestUserData = (data) => {
        cookies.set('gw_token', data.access_token, data.access_token.expires_at, '/', DOMAIN)
        localStorage.setItem('GW_isGuest', true)
    }

    const init = async () => {
        const connectorInfo = getConnectorParamFromQuery(route)
        await getConnectorInfo(connectorInfo)
        if (getters['account/isAuth']) {
            await dispatch('account/getAccount')
            await dispatch('drivers/getDrivers', { limit: 100 })
            if (state.account.roles.length === 1 && state.account.roles[0] === 'driver') {
                globalState.selectedDriver = getters['drivers/drivers'][0]
                globalState.isDriverAccount = true
            } else {
                globalState.selectedDriver = getters['drivers/drivers'].find(
                    (x) => x.id === state.account.primaryDriverId
                )
            }
            globalState.isLoggedIn = true
        }
        if (globalState.hasConnectorData) {
            if (globalState.isLoggedIn) {
                checkForChargingProcess()
            } else if (localStorage.getItem('GW_isGuest') && cookies.get('gw_token')) {
                globalState.guestToken = cookies.get('gw_token')
                await fetchOneTimeFeesAndProvider()
                if (globalState.oneTimeProvider === PaymentProviders.Payout) {
                    await oneTimePreAutorizationStatus()
                    return
                }
                checkForChargingProcess()
            } else {
                globalState.activeScreen = 0
                await fetchOneTimeFeesAndProvider()
            }
        }
    }

    const fetchOneTimeFeesAndProvider = async () => {
        try {
            const { data } = await apiRequest('ot-tariff')
            globalState.oneTimeFees = data.authorization_amounts_one_time_charging
            globalState.oneTimeProvider = data.payment_gateway_provider
        } catch (e) {
            errorMessageArray(e.errors)
        }
    }

    const oneTimePreAutorizationStatus = async () => {
        try {
            const { data } = await apiRequest('ot-pre-auth-status')
            globalState.checkoutStatus = data.status
            switch (globalState.checkoutStatus) {
                case PayoutCheckoutStatus.Error:
                    cancelGuestPayoutSession()
                    clearTimeout(checkoutStatusTimeout.value)
                    break
                case PayoutCheckoutStatus.Pending:
                    globalState.checkoutUrl = data.data.checkout_url
                    globalState.activeScreen = 6
                    checkoutStatusTimeout.value = setTimeout(oneTimePreAutorizationStatus, CHECKOUT_STATUS)
                    break
                case PayoutCheckoutStatus.PreAuthorized:
                    globalState.checkoutUrl = data.data.checkout_url
                    initiateCharging()
                    clearTimeout(checkoutStatusTimeout.value)
                    break
                default:
                    clearTimeout(checkoutStatusTimeout.value)
            }
        } catch (error) {
            errorMessageArray(error.errors)
        }
    }

    const oneTimePreAutorization = async () => {
        const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
        if (recaptchaSiteKey) {
            globalState.activeScreen = 6
            try {
                commit('setLoader', 1, { root: true })
                // eslint-disable-next-line
                grecaptcha.enterprise.ready(async () => {
                    // eslint-disable-next-line
                    const recaptchaToken = await grecaptcha.enterprise.execute(recaptchaSiteKey, {
                        action: 'payment_add'
                    })
                    try {
                        commit('setLoader', 1, { root: true })
                        const { data } = await apiRequest.post('ot-pre-auth', {
                            amount: globalState.selectedOneTimeFee,
                            redirect_url: window.location.href, // https://client.dev.gway.cloud/
                            'g-recaptcha-response': recaptchaToken
                        })
                        cookies.set('gw_token', data.access_token, data.token_expires_at, '/', DOMAIN)
                        localStorage.setItem('GW_isGuest', true)

                        window.location = data.redirect_url
                    } catch (error) {
                        errorMessageArray(error.errors)
                    } finally {
                        commit('setLoader', -1, { root: true })
                    }
                })
            } catch (e) {
                showMessage(e)
            } finally {
                commit('setLoader', -1, { root: true })
            }
        }
    }

    const toCurrentPayoutCheckout = () => {
        window.location = globalState.checkoutUrl
    }

    const cancelPayoutCheckout = async () => {
        try {
            commit('setLoader', 1, { root: true })
            await apiRequest.post('ot-cancel-checkout')
            clearTimeout(checkoutStatusTimeout.value)
            globalState.checkoutStatus = null
            showMessage(t('startCharging.checkoutCanceled'), 'primary')
            cancelGuestPayoutSession()
        } catch (error) {
            errorMessageArray(error.errors)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }

    const initiateCharging = () => {
        globalState.errorMessage = null
        globalState.activeScreen = 1
        createChargingCommand()
    }

    const getConnectorInfo = async ({ param, value }) => {
        commit('setLoader', 1, { root: true })
        try {
            const { data } = await apiRequest(`charging/public/connectors?${param}=${value}&limit=1&page=1`)
            if (data.length) {
                globalState.connectorData = data[0]
                if (ConnectorStatus.isAvailable(globalState.connectorData.availability)) {
                    globalState.connectorAvailable = true
                    if (globalState.connectorData.availability === null) globalState.isUnknownConnectorStatus = true
                }
                globalState.hasConnectorData = true
            } else {
                globalState.connectorData = []
                globalState.connectorNotFound = true
            }
        } catch (error) {
            globalState.connectorNotFound = true
            dispatch('connectors/checkStatus', error)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }

    const checkStatesForCanceledSession = async () => {
        try {
            const { data } = await apiRequest('command/charging/states')
            if (data.length) {
                globalState.chargingDetails =
                    data.find((x) => x.connector.connector_id === globalState.connectorData.connector_id) || null
                getSessionSummary()
            }
        } catch (error) {
            showMessage(error.msg)
        }
    }

    const cancelChargingCommand = async () => {
        try {
            const { data } = await apiRequest.post(`command/charging/cancel?command_id=${commandId.value}`)
            if (sanitizeEnum(data.command) === ChargingCommandName.StopCharging) {
                clearInterval(startChargingCommandInterval.value)
                await checkStatesForCanceledSession()
            }
        } catch (error) {
            showMessage(error.msg)
        }
    }

    const resolveDriver = () => {
        if (globalState.selectedDriver) return `&driver_id=${globalState.selectedDriver.id}`
        return ''
    }

    const createChargingCommand = async () => {
        try {
            fetchingCommandId.value = true
            const { data } = await apiRequest.post(
                `command/charging/start?connector_id=${globalState.connectorData.connector_id}${resolveDriver()}`
            )
            commandId.value = data.id
            chargingCommandIsFinalState.value = data.is_final_state
            chargingCommandStatusEnum.value = data.status_enum

            startChargingCommandInterval.value = setInterval(() => {
                if (chargingCommandIsFinalState.value === true) {
                    if (sanitizeEnum(chargingCommandStatusEnum.value) === ChargingStatus.Finished) {
                        clearInterval(startChargingCommandInterval.value)
                        clearTimeout(timeout.value)
                        chargingStarted()
                        return
                    } else {
                        clearInterval(startChargingCommandInterval.value)
                        clearTimeout(timeout.value)
                        chargingDidNotStart()
                        return
                    }
                }
                chargingCommandDetail()
            }, CHARGING_COMMAND)
        } catch (error) {
            errorMessageArray(error.errors)
            globalState.activeScreen = 0
        } finally {
            fetchingCommandId.value = false
        }
    }

    const chargingCommandDetail = async () => {
        try {
            const { data } = await apiRequest.post(
                `command/charging/state?command_id=${commandId.value}${resolveDriver()}`
            )
            chargingCommandIsFinalState.value = data.is_final_state
            chargingCommandStatusEnum.value = sanitizeEnum(data.status_enum)
            if (chargingCommandIsFinalState.value && chargingCommandStatusEnum.value === ChargingStatus.Error) {
                clearInterval(endChargingSessionCommandInterval.value)
                chargingDidNotStart()
            } else if (
                chargingCommandIsFinalState.value &&
                chargingCommandStatusEnum.value === ChargingStatus.SimultaniousChargingDenied
            ) {
                globalState.errorMessage = data.message
                clearInterval(endChargingSessionCommandInterval.value)
                chargingDidNotStart()
            }
        } catch (error) {
            showMessage(error.msg)
        }
    }

    const endChargingSessionCommand = async () => {
        clearInterval(chargingDetailsInterval.value)
        isDisabled.value = true
        try {
            const { data } = await apiRequest.post(
                `command/charging/stop?connector_id=${globalState.connectorData.connector_id}`
            )
            commandId.value = data.id
            chargingCommandIsFinalState.value = data.is_final_state
            chargingCommandStatusEnum.value = data.status_enum
            endChargingSessionCommandInterval.value = setInterval(() => {
                if (
                    chargingCommandIsFinalState.value &&
                    sanitizeEnum(chargingCommandStatusEnum.value) === ChargingStatus.Finished
                ) {
                    globalState.sessionTerminated = true
                    chargingStopped()
                    clearInterval(endChargingSessionCommandInterval.value)
                    return
                }
                chargingCommandDetail()
            }, END_CHARGING)
        } catch (error) {
            showMessage(error.msg)
        }
    }

    const closeGuestUser = async () => {
        try {
            clearInterval(SESSION_SUMMARY)
            globalState.blockingUserInput = true
            await apiRequest.post('guest/close', { email: receiptEmail.value })
            globalState.guestClosed = true
        } catch (error) {
            errorMessageArray(error.errors.email)
        } finally {
            globalState.blockingUserInput = false
        }
    }

    const closeChargingSession = async () => {
        globalState.errorMessage = null
        if (isGuest.value) {
            await closeGuestUser()
            if (globalState.guestClosed) {
                cleanUpGuestUserDataAndRedirectToMap()
            }
            return
        }
        router.push({ path: 'locationMap', query: { locationId: globalState.connectorData.location.location_id } })
    }

    const closeChargingSessionAndSendReceipt = async () => {
        if (isGuest.value) {
            await closeGuestUser()
            if (globalState.guestClosed) {
                cleanUpGuestUserDataAndRedirectToMap()
            }
            return
        }
        router.push({ path: 'locationMap', query: { locationId: globalState.connectorData.location.location_id } })
    }

    const backToMap = () => {
        globalState.errorMessage = null
        if (!globalState.isLoggedIn) {
            if (isGuest.value) {
                if (globalState.noCommunication) {
                    cleanUpGuestUserDataAndRedirectToMap()
                    return
                }
                cookies.set('gw_location_id', globalState.connectorData.location.location_id, '8h', '/', DOMAIN)
                cookies.set('gw_charging_status', ChargingStatus.Cancelled, '8h', '/', DOMAIN)
                window.location.href = `${import.meta.env.VITE_APP_LOCATION_MAP_IFRAME_URL}?locationId=${
                    globalState.connectorData.location.location_id
                }&bearerToken=${cookies.get('gw_token')}`
                return
            }
            cookies.set('gw_location_id', globalState.connectorData.location.location_id, '8h', '/', DOMAIN)
            cookies.set('gw_charging_status', ChargingStatus.Cancelled, '8h', '/', DOMAIN)
            window.location.href = `${import.meta.env.VITE_APP_LOCATION_MAP_IFRAME_URL}?locationId=${
                globalState.connectorData.location.location_id
            }`
            return
        }

        cookies.set('gw_location_id', globalState.connectorData.location.location_id, '8h', '/', DOMAIN)
        cookies.set('gw_charging_status', ChargingStatus.Cancelled, '8h', '/', DOMAIN)
        router.push({ path: 'locationMap', query: { locationId: globalState.connectorData.location.location_id } })
    }

    const resolveStartChargingCommandState = () => {
        if (chargingCommandStatusEnum.value === ChargingStatus.Finished) {
            chargingStarted()
            return
        }
        if (chargingCommandStatusEnum.value === ChargingStatus.SimultaniousChargingDenied) {
            globalState.errorMessage = chargingCommandMessage.value
        }
        chargingDidNotStart()
    }

    const resolveStopChargingCommandState = () => {
        if (chargingCommandStatusEnum.value === ChargingStatus.Finished && isGuest.value) {
            getSessionSummary()
            return
        }
        globalState.activeScreen = 0
    }

    const resolveLastCommandState = (data) => {
        commandId.value = data.id
        chargingCommandIsFinalState.value = data.is_final_state
        lastCommandName.value = sanitizeEnum(data.command)
        lastCommandConnectorId.value = data.parameters.connectorId
        lastCommandConnectorCode.value = data.parameters.connector_code
        chargingCommandStatusEnum.value = sanitizeEnum(data.status_enum)
        chargingCommandMessage.value = data.message
        // check if command finished
        if (chargingCommandIsFinalState.value) {
            if (lastCommandConnectorId.value === globalState.connectorData.connector_id) {
                if (lastCommandName.value === ChargingCommandName.StartCharging) resolveStartChargingCommandState()
                if (lastCommandName.value === ChargingCommandName.StopCharging) resolveStopChargingCommandState()
                return
            }
            globalState.activeScreen = 0
            if (isGuest.value) globalState.hasOtheRunningSession = true
            return
        }
        if (lastCommandName.value === ChargingCommandName.StartCharging) {
            globalState.activeScreen = 1
            startChargingCommandInterval.value = setInterval(() => {
                if (chargingCommandStatusEnum.value === ChargingStatus.Finished) {
                    clearInterval(startChargingCommandInterval.value)
                    chargingStarted()
                    return
                }
                chargingCommandDetail()
            }, CHARGING_COMMAND)
        }
        if (lastCommandName.value === ChargingCommandName.StopCharging) {
            if (isGuest.value) {
                stopChargingCommandInterval.value = setInterval(() => {
                    if (chargingCommandStatusEnum.value === ChargingStatus.Finished) {
                        clearInterval(stopChargingCommandInterval.value)
                        return
                    }
                    chargingCommandDetail()
                }, CHARGING_COMMAND)
            } else {
                globalState.activeScreen = 0
            }
        }
    }

    const checkForChargingProcess = async () => {
        commit('setLoader', 1, { root: true })
        try {
            const { data } = await apiRequest.post('command/charging/state')
            if (Object.keys(data).length) {
                if (sanitizeEnum(data.status) !== ChargingStatus.Error) {
                    resolveLastCommandState(data)
                    return
                }
                globalState.activeScreen = 0
            } else {
                await chargingStarted()
            }
        } catch (error) {
            showMessage(error.msg)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }

    const chargingStarted = async () => {
        try {
            const { data } = await apiRequest('command/charging/states')
            if (data.length && !data[0].no_communication) {
                globalState.chargingDetails =
                    data.find((x) => x.connector.connector_id === globalState.connectorData.connector_id) || null
                globalState.chargingStarted = true
                globalState.chargingStopped = false
                if (route.query.action && route.query.action === 'stopCharging') {
                    globalState.stopChargingPromptVisible = true
                    const url = new URL(window.location)
                    url.searchParams.delete('action')
                    window.history.pushState({}, '', url)
                    globalState.activeScreen = 3
                } else {
                    globalState.activeScreen = 2
                }
                timeout.value = setTimeout(() => {
                    showChargingDetails()
                }, CHARGING)
            } else {
                if (isGuest.value) {
                    getSessionSummary()
                    return
                }
                globalState.activeScreen = 0
            }
        } catch (error) {
            showMessage(error.msg)
        }
    }

    const chargingStopped = () => {
        globalState.chargingStopped = true
        globalState.sessionEnded = true
        globalState.chargingStarted = false
        globalState.activeScreen = 4
        isDisabled.value = false
        getSessionSummary()
    }

    const chargingDidNotStart = () => {
        clearInterval(startChargingCommandInterval.value)
        globalState.activeScreen = 2
        globalState.chargingStarted = false
    }

    const showChargingDetails = () => {
        clearTimeout(timeout)
        chargingDetailsInterval.value = setInterval(() => {
            globalState.noCommunication = globalState.chargingDetails.no_communication
            if (globalState.chargingStopped) {
                clearInterval(chargingDetailsInterval.value)
                return
            }
            refreshChargingDetails()
        }, CHARGING_DETAIL)
    }

    const refreshChargingDetails = async () => {
        try {
            const { data } = await apiRequest('command/charging/states')
            if (data.length) {
                globalState.chargingDetails =
                    data.find((x) => x.connector.connector_id === globalState.connectorData.connector_id) || null
                if (globalState.chargingDetails === null || globalState.chargingDetails.measurements.length === 0) {
                    chargingStopped()
                }
            } else {
                chargingStopped()
            }
        } catch (error) {
            showMessage(error.msg)
        }
    }

    const requestGuestPaymentToken = async () => {
        const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
        if (recaptchaSiteKey) {
            globalState.activeScreen = 6
            try {
                // eslint-disable-next-line
                grecaptcha.enterprise.ready(async () => {
                    // eslint-disable-next-line
                    const recaptchaToken = await grecaptcha.enterprise.execute(recaptchaSiteKey, {
                        action: 'payment_add'
                    })

                    try {
                        loadingRecaptcha.value = true
                        const { data } = await apiRequest('guest/payment-token', {
                            params: {
                                'g-recaptcha-response': recaptchaToken
                            }
                        })

                        globalState.guestToken = data
                        createDropIn()
                    } catch (error) {
                        showMessage(error)
                    } finally {
                        loadingRecaptcha.value = false
                    }
                })
            } catch (e) {
                showMessage(e)
            }
        }
    }

    const createDropIn = async () => {
        const {lang} = useLocale()
        await dropIn.create(
            {
                authorization: globalState.guestToken,
                container: '#dropin-container',
                locale: `${lang.value}_${lang.value.toUpperCase()}`,
                dataCollector: true,
                threeDSecure: true
            },
            (createErr, instance) => {
                if (createErr) {
                    return
                }
                dropinInstance.value = instance
            }
        )
    }

    const instanceRequestPaymentMethod = () => {
        dropinInstance.value
            .requestPaymentMethod({ threeDSecure: { amount: globalState.selectedOneTimeFee.toFixed(2) } })
            .then((response) => {
                blockInstanceRequestPaymentMethodButton.value = true
                try {
                    registerGuest(response)
                } catch (error) {
                    errorMessageArray(error.errors)
                } finally {
                    commit('setLoader', -1, { root: true })
                }
            })
            .catch((error) => {
                showMessage(error.message)
            })
    }

    const registerGuest = async (resp) => {
        try {
            const { data } = await apiRequest.post(
                `guest/register?payment_method_nonce=${resp.nonce}&device_data=${resp.deviceData}&connector_id=${globalState.connectorData.connector_id}`
            )
            setGuestUserData(data)
            initiateCharging()
        } catch (error) {
            errorMessageArray(error.errors)
            dropinInstance.value.teardown()
            createDropIn()
        } finally {
            blockInstanceRequestPaymentMethodButton.value = false
        }
    }

    const getSessionSummary = () => {
        globalState.activeScreen = 4
        fetchChargingSummary()
        sessionSummaryInterval.value = setInterval(() => {
            fetchChargingSummary()
        }, SESSION_SUMMARY)
    }

    const fetchChargingSummary = async () => {
        if (isGuest.value) globalState.fetchingOTChargingSummary = true
        try {
            const { data } = isGuest.value
                ? await apiRequest('command/charging/summary')
                : await apiRequest(`command/charging/summary/${globalState.chargingDetails.session.session_id}`)
            if (data && data.length !== 0) {
                globalState.sessionSummary = data
                clearInterval(sessionSummaryInterval.value)
                globalState.fetchingOTChargingSummary = false
            }
        } catch (error) {
            showMessage(error.msg)
        }
    }

    const loginToStart = () => {
        cookies.set(
            'redirect_after_login',
            `/charging?connectorCode=${globalState.connectorData.code}`,
            '8h',
            '/',
            DOMAIN
        )
        router.push({ path: 'login' })
    }

    return {
        pages,
        driverBlocked,
        attrs,
        isDisabled,
        visibleOfflineDetails,
        commandId,
        fetchingData,
        isGuest,
        loadingRecaptcha,
        blockInstanceRequestPaymentMethodButton,
        lastCommandName,
        lastCommandConnectorCode,
        fetchingCommandId,
        drivers,
        getPriceListUrl,
        ...toRefs(globalState),
        init,
        initiateCharging,
        cancelChargingCommand,
        showChargingDetails,
        endChargingSessionCommand,
        requestGuestPaymentToken,
        instanceRequestPaymentMethod,
        backToMap,
        closeChargingSessionAndSendReceipt,
        closeChargingSession,
        loginToStart,
        oneTimePreAutorization,
        toCurrentPayoutCheckout,
        cancelPayoutCheckout
    }
}
