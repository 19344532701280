<script setup>
import { computed, reactive, watch, onMounted } from 'vue'
import moment from 'moment'

const emit = defineEmits(['date'])

const componentProps = defineProps({
    isClear: {
        type: Boolean,
        default: false,
        required: false
    },
    label1: {
        type: String,
        default: 'Date from',
        required: false
    },
    label2: {
        type: String,
        default: 'Date to',
        required: false
    },
    fromDate: {
        type: String,
        default: null,
        required: false
    },
    toDate: {
        type: String,
        default: null,
        required: false
    }
})

const formatDate = (date) => {
    if (!date) return null
    return moment(date).format('DD.MM.YYYY')
}

const state = reactive({
    dateFrom: '',
    dateTo: '',
    dateFormatted: formatDate(new Date().toISOString().substr(0, 10)),
    isMenuFrom: false,
    isMenuTo: false
})

const computedDateFromFormatted = computed({
    get() {
        return formatDate(state.dateFrom)
    },
    set(val) {
        state.dateFrom = val
    }
})

const computedDateToFormatted = computed({
    get() {
        return formatDate(state.dateTo)
    },
    set(val) {
        state.dateTo = val
    }
})

watch(
    () => state.dateFrom,
    () => {
        emit('date', {
            dateFrom: computedDateFromFormatted.value,
            dateTo: computedDateToFormatted.value,
            isClear: false
        })
    }
)
watch(
    () => state.dateTo,
    () => {
        emit('date', {
            dateFrom: computedDateFromFormatted.value,
            dateTo: computedDateToFormatted.value,
            isClear: false
        })
    }
)

watch(
    () => componentProps.isClear,
    () => {
        state.dateFrom = null
        state.dateTo = null
    }
)

const allowedDatesFrom = (val) => {
    if (state.dateTo) {
        return new Date(val) <= new Date(state.dateTo)
    }
    return new Date()
}

const allowedDatesTo = (val) => {
    const calendarDate = moment(val)
    const dateFromDate = moment(state.dateFrom)
    const defaultDate = moment('2010-01-01')

    if ('' !== state.dateFrom) {
        return calendarDate.isSameOrAfter(dateFromDate, 'day')
    }
    return calendarDate.isSameOrAfter(defaultDate, 'day')
}

const updateDateFrom = (val) => {
    state.dateFrom = moment(val)
}
const updateDateTo = (val) => {
    state.dateTo = moment(val).format('YYYY-MM-DD')
}
const clearFrom = () => {
    emit('date', {
        dateFrom: null,
        dateTo: computedDateToFormatted.value,
        isClear: false
    })
}
const clearTo = () => {
    emit('date', {
        dateFrom: computedDateFromFormatted.value,
        dateTo: null,
        isClear: false
    })
}

onMounted(() => {
    if (componentProps.dateFrom) state.dateFrom = componentProps.dateFrom
    if (componentProps.dateTo) state.dateTo = componentProps.dateTo
})
</script>

<template>
    <v-container class="py-0 px-3 ma-0 date-picker-wrapper" fluid>
        <v-row>
            <v-col class="py-0 ma-0" cols="12" sm="6">
                <v-menu :close-on-content-click="false" :v-model="state.isMenuFrom">
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-model="computedDateFromFormatted"
                            :label="label1"
                            clearable
                            density="compact"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="props"
                            variant="outlined"
                            @click:clear="clearFrom"
                            data-testid="dateClearForm"
                        />
                    </template>
                    <v-date-picker
                        :allowed-dates="allowedDatesFrom"
                        :v-model="state.dateFrom"
                        color="primary"
                        show-adjacent-months
                        @update:model-value="updateDateFrom"
                        data-testid="datePickerFrom"
                    />
                </v-menu>
            </v-col>
            <v-col class="py-0 ma-0" cols="12" sm="6">
                <v-menu :close-on-content-click="false" :v-model="state.isMenuTo">
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-model="computedDateToFormatted"
                            :label="label2"
                            clearable
                            density="compact"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="props"
                            variant="outlined"
                            @click:clear="clearTo"
                            data-testid="dateClearTo"
                        />
                    </template>
                    <v-date-picker
                        :allowed-dates="allowedDatesTo"
                        :v-model="state.dateTo"
                        color="primary"
                        show-adjacent-months
                        @update:model-value="updateDateTo"
                        data-testid="datePickerTo"
                    />
                </v-menu>
            </v-col>
        </v-row>
    </v-container>
</template>
