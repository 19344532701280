<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const { dispatch } = store

const emit = defineEmits(['setDeliveryCountry', 'setValue'])

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty } = useValidations()

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
    isEditView: Boolean,
    isDisabled: {
        type: Boolean,
        default: false
    },
    isDeliveryCountry: {
        type: Boolean,
        required: false
    },
    isDeliveryAddress: {
        type: Boolean,
        required: false
    },
    country: {
        type: String,
        required: false,
        default: ''
    }
})

const countryValue = ref(props.country)

const setCountry = (value) => {
    if (props.isDeliveryCountry) {
        emit('setDeliveryCountry', value)
        return
    }
    emit('setValue', value)
}

onMounted(() => {
    if (!store.state.filters.countries) dispatch('filters/getCountries')
})
</script>

<template>
    <v-select
        v-model="countryValue"
        :items="store.state.filters.countries || []"
        :disabled="!isEditView || isDisabled"
        :placeholder="country ? '' : t('Client.Country') + '*'"
        :label="t('Client.Country') + '*'"
        density="compact"
        variant="outlined"
        item-title="name"
        item-value="value"
        :rules="isDeliveryAddress ? [isNotEmpty] : []"
        @update:model-value="setCountry"
    />
</template>
