<script setup>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const { commit } = store

// configs
import { Locale, Client } from '@/configs/index.js'

// components
import LanguageSelect from '@/components/LanguageSelect.vue'
import PhoneEmailComponent from '@/pages/account/components/helpers/PhoneEmailComponent.vue'
import BirthdateComponent from '@/pages/account/components/helpers/BirthdateComponent.vue'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty, maxCharsExceeded, isValidPassword, isLongPassword, isValid2Characters } = useValidations()

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const emit = defineEmits(['setError'])

defineProps({
    isRegistration: {
        type: Boolean,
        required: false,
        default: false
    },
    errors: {
        type: Object,
        required: true
    }
})

const state = reactive({
    isPasswordVisible: false
})

const account = computed(() => store.state.account)
const titles = computed(() => store.state.filters.titles)
const accountTemp = computed(() => store.state.settings.accountTemp)
const isEditView = computed(() => store.state.account.personalDataIsEditView)

const password = computed({
    get() {
        return isEditView.value
            ? typeof accountTemp.value.password !== 'undefined'
                ? accountTemp.value.password
                : null
            : null
    },
    set(value) {
        updateAccountTemp(value, 'password')
    }
})

const isIndividual = computed(() => {
    return account.value?.typeRegistration === Client.isIndividual
})

const updateAccountTemp = (value, field) => {
    commit('settings/setAccountTempFieldValue', {
        field,
        value
    })
}
</script>

<template>
    <v-row class="personal-form-component-wrapper" v-if="account.id">
        <v-col class="pa-lg-5 bg-white" cols="12" lg="8" offset-lg="2">
            <v-row>
                <v-col class="d-flex align-items-center">
                    <h2 class="home-page-title">
                        {{ t('personalData.personalData') }}
                    </h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-select
                        :disabled="!isEditView"
                        :items="titles || []"
                        :placeholder="t('Client.Title')"
                        :label="t('Client.Title')"
                        :model-value="isEditView ? accountTemp.gender : account.gender"
                        data-testid="genderSelect"
                        density="compact"
                        variant="outlined"
                        item-title="name"
                        item-value="value"
                        @update:model-value="updateAccountTemp($event, 'gender')"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <LanguageSelect
                        :is-edit-view="isEditView"
                        :language="isEditView ? accountTemp.communicationLanguage : account.communicationLanguage"
                        data-testid="languageSelection"
                        @setValue="updateAccountTemp($event, 'communicationLanguage')"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        :disabled="!isEditView"
                        :label="t('Client.FirstName') + '*'"
                        :placeholder="t('Client.FirstName') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                        :model-value="isEditView ? accountTemp.firstName : account.firstName"
                        data-testid="firstName"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'firstName')"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        :disabled="!isEditView"
                        :label="t('Client.LastName') + '*'"
                        :placeholder="t('Client.LastName') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                        :model-value="isEditView ? accountTemp.lastName : account.lastName"
                        data-testid="lastName"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'lastName')"
                    />
                </v-col>

                <v-col cols="12">
                    <PhoneEmailComponent data-testid="phoneEmailComponent" />
                </v-col>
                <v-col v-if="store.getters['account/locale'] === Locale.sk && isIndividual && account.id" cols="6">
                    <BirthdateComponent data-testid="dateOfBirthComponent" />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        :disabled="!isEditView"
                        :error-messages="errors?.password"
                        :label="t('driverInfo.Password')"
                        :placeholder="t('driverInfo.Password')"
                        :rules="password ? [isValidPassword, isLongPassword] : []"
                        :type="state.isPasswordVisible ? 'text' : 'password'"
                        :model-value="password"
                        autocomplete="new-password"
                        data-testid="password"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'password')"
                        @click="emit('setError', { key: 'password', value: '' })"
                    >
                        <template v-slot:append-inner>
                            <v-icon
                                :icon="state.isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                                @click="state.isPasswordVisible = !state.isPasswordVisible"
                            />
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
