import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

// Utils
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessage'

import { useI18n } from 'vue-i18n'

// Store
import store from '@/store'
const { state, commit } = store

export default function useConfirmation() {
    const { t } = useI18n()
    const router = useRouter()
    const chargingCard = ref(null)
    const form = ref()
    const isFormValid = ref(false)
    const consents = ref([])
    const items = ref([])
    const payerConsents = ref([])

    const country = computed(() => {
        return state.account.country
    })

    const hasError = computed(() => {
        return requestCardHasError.value ? t('error.FieldIsRequired') : null
    })

    const requestCardHasError = computed({
        get() {
            return state.eshop.requestCardHasError
        },
        set(value) {
            commit('eshop/setFieldValue', {
                field: 'requestCardHasError',
                value
            })
        }
    })

    const requestCard = computed({
        get() {
            return state.eshop.requestCard
        },
        set(value) {
            commit('eshop/setFieldValue', {
                field: 'requestCard',
                value
            })
            requestCardHasError.value = false
        }
    })

    const getTranslationOrder = () => {
        switch (country.value.trim().toLowerCase()) {
            case 'slovakia':
                return t('orderCard.requestDescriptionSK')
            case 'poland':
                return t('orderCard.requestDescriptionPL')
            default:
                return t('orderCard.requestDescription')
        }
    }
    const getTranslation = () => {
        switch (country.value.trim().toLowerCase()) {
            case 'slovakia':
                return t('orderCard.allINeedSK')
            case 'poland':
                return t('orderCard.allINeedPL')
            default:
                return t('orderCard.allINeed')
        }
    }

    const getConsent = (id) => {
        return payerConsents.value?.some((e) => e.id === id) || false
    }

    const setConsent = (key, value) => {
        if (value) {
            payerConsents.value.push(key)
        } else {
            payerConsents.value = payerConsents.value.filter((item) => item !== key)
        }
    }

    const getSelectedShopItem = () => {
        items.value = []
        if (requestCard.value && chargingCard.value) {
            items.value.push({
                product_id: chargingCard.value.id,
                quantity: 1
            })
        }
        return items.value
    }

    const scrollToErrorSection = () => {
        const element = document.getElementsByClassName('v-messages__message')
        element[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    const finishRegistration = async () => {
        const { valid } = await form.value.validate()

        if (requestCard.value === null) {
            requestCardHasError.value = true
        }
        if (!valid) {
            showMessage(t('error.FormContainsErrors'))
        }
        if (!valid || requestCard.value === null) {
            scrollToErrorSection()
            return
        }
        try {
            await apiRequest.post('auth/finish-registration', {
                items: getSelectedShopItem(),
                consents: payerConsents.value
            })
            commit('account/setActionsAfterLogin', null)
            router.push({ name: 'news', query: { welcome: true } })
        } catch (error) {
            if (error.errors.rfid_card) showMessage(error.errors.rfid_card[0])
            if (error.errors.payer_consents) showMessage(error.errors.payer_consents[0])
        }
    }

    return {
        form,
        isFormValid,
        requestCard,
        hasError,
        chargingCard,
        consents,
        payerConsents,
        getTranslationOrder,
        getTranslation,
        getConsent,
        setConsent,
        finishRegistration
    }
}
