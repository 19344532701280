<script setup>
import store from '@/store'
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

// configs
import { Roles } from '@/configs/index.js'

// composables
import useSessions from '@/composables/sessions'
import useFacilitySessions from '@/composables/facilitySessions'

// components
import ExportBtn from '@/components/ExportBtn.vue'
import FilterByToggle from '@/components/inputs/FilterByToggle.vue'

import MyChargingSessions from './components/MyChargingSessions.vue'
import MyChargersSessions from './components/MyChargersSessions.vue'

// utils
import { useI18n } from 'vue-i18n'
import setPageTitle from '@/utils/pageTitle'

const route = useRoute()

const props = defineProps({
    showSessionsFilter: {
        type: Boolean,
        required: false,
        default: () => {
            return true
        }
    },
    inline: {
        type: Boolean,
        default: false
    },
    isHeader: {
        type: Boolean,
        default: true
    },
    hideDetails: {
        type: Boolean,
        default: false
    },
    driverId: {
        type: Array,
        default: null
    },
    invoiceId: {
        type: Number,
        default: () => {
            return null
        }
    },
    isDriverDetail: {
        type: Boolean,
        required: false,
        default: false
    }
})

const { commit } = store
const { t } = useI18n()
const { isFiltersVisible, handleSessionsDownload, isSessionsExportLoading, windowWidth } = useSessions()

const { facilityExportLoading, handleFacilityDownload } = useFacilitySessions()

const myChargingSessionsTab = computed(() => {
    return { content: MyChargingSessions, title: t('chargerManagement.MyChargingSessions') }
})
const myChargersSessions = computed(() => {
    return { content: MyChargersSessions, title: t('chargerManagement.MyChargersSessions') }
})
const tabs = computed(() => {
    if (
        store.state.account.roles?.includes(Roles.facilityManager) ||
        store.state.account.roles?.includes(Roles.fleetHomeChargingManager)
    ) {
        if (props.isDriverDetail) {
            return [myChargingSessionsTab.value]
        }
        return [myChargingSessionsTab.value, myChargersSessions.value]
    }
    return [myChargingSessionsTab.value]
})
const tab = computed({
    get() {
        return store.state.settings.sessions.tab
    },
    set(tabIndex) {
        commit('settings/setSessionTab', {
            tabIndex
        })
    }
})

const changeTab = () => {
    isFiltersVisible.value = false
}

onMounted(() => {
    setPageTitle(t('sessions.Sessions'))
})
</script>

<template>
    <div class="sessions">
        <header class="sessions__header pb-0 pt-6 px-md-8 pt-md-8">
            <div class="sessions__header-head d-flex justify-space-between mb-6 px-4 px-md-0">
                <h1 class="home-page-title">
                    {{ t('sessions.Sessions') }}
                    <span v-if="route.query.invoiceId"
                        >- {{ t('pdfGeneratorInvoice.Invoice') }}: {{ route.query.invoiceId }}</span
                    >
                </h1>
                <div class="d-flex">
                    <ExportBtn
                        :loading="facilityExportLoading || isSessionsExportLoading"
                        @handleDownload="tab === 0 ? handleSessionsDownload($event) : handleFacilityDownload($event)"
                    />
                    <FilterByToggle
                        v-if="showSessionsFilter"
                        :active="isFiltersVisible"
                        @change="isFiltersVisible = !isFiltersVisible"
                    />
                </div>
            </div>
            <div class="sessions__filters-options">
                <v-tabs v-model="tab" class="sessions__tabs" background-color="transparent">
                    <v-tab v-for="{ title } in tabs" :key="title" @click="changeTab()">
                        {{ title }}
                    </v-tab>
                </v-tabs>
            </div>
        </header>
        <main class="sessions__main">
            <v-window v-model="tab" :touch="false">
                <v-window-item v-for="item in tabs" :key="item.title" :transition="false">
                    <component
                        :is="item.content"
                        :window-width="windowWidth"
                        :is-filters-visible="isFiltersVisible"
                        :is-driver-detail="isDriverDetail"
                        data-testid="sessionsTable"
                        v-bind="{ ...props }"
                    />
                </v-window-item>
            </v-window>
        </main>
    </div>
</template>
