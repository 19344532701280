{
  "general": {
    "loadingItems": "Loading items..."
  },
  "consumptionDetail": {
    "lockConsumption": "Lock consumption (Disable modifications for driver)",
    "consumptionDetail": "Consumption detail",
    "basicInfo": "Basic info",
    "charger": "Charger",
    "from": "From",
    "to": "To",
    "pricePerKWh": "Price per kWh",
    "consumption": "Consumption (kWh)",
    "consumptionCost": "Consumption Cost",
    "fixedCost": "Fixed cost",
    "totalCost": "Total cost",
    "odometer": "Odometer (km)",
    "averageConsumption": "Average consumption (kWh)",
    "odometerFrom": "Odometer from",
    "note": "Note",
    "noteUser": "Note user",
    "successMessage": "Consumption updated successfully",
    "company_home_online": "Company Home Online",
    "company_home_offline": "Company Home Offline"
  },
  "oAuth": {
    "title": "Authorization Request",
    "message": "{client} is requesting permission to access your account.",
    "authorize": "Authorize",
    "cancel": "Cancel"
  },
  "locationMap": {
    "locationMap": "Location Map",
    "loading": "Loading...",
    "moreInfo": "More info",
    "chargingPowerInfo": "Charging power info"
  },
  "etrelstatus": {
    "offline": "Unavailable (offline)",
    "evse": "Occupied (EVSE)",
    "unavailable": "Unavailable",
    "fault": "Unavailable (fault) ",
    "available": "Available",
    "reserved": "Unavailable (reserved)",
    "charging": "Occupied (charging)",
    "finishing": "Occupied (finishing)",
    "outOfOrder": "Unavailable (out of order) ",
    "inserted": "Occupied (inserted)",
    "connected": "Occupied (connected)",
    "suspendedEvse": "Occupied (suspended EVSE)",
    "suspendedEv": "Occupied (suspended EV)",
    "unknown": "unknown",
    "ConnectorStatus_Out_Of_Communication": "ConnectorStatus Out Of Communication",
    "ConnectorStatus_Unavailable": "ConnectorStatus Unavailable",
    "ConnectorStatus_In_Fault": "ConnectorStatus In Fault",
    "ConnectorStatus_Available": "ConnectorStatus Available",
    "ConnectorStatus_Reserved": "ConnectorStatus Reserved",
    "ConnectorStatus_Occupied": "ConnectorStatus Occupied",
    "ConnectorStatus_Finishing": "ConnectorStatus Finishing",
    "ConnectorStatus_Out_Of_Working_Time": "ConnectorStatus Out Of Working Time",
    "ConnectorStatus_Newly_Inserted": "ConnectorStatus Newly Inserted",
    "ConnectorStatus_Preparing": "ConnectorStatus Preparing",
    "ConnectorStatus_Suspended_EVSE": "ConnectorStatus Suspended EVSE",
    "ConnectorStatus_Suspended_EV": "ConnectorStatus Suspended EV"
  },
  "weekdays": {
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday"
  },
  "zsiq": {
    "chatbuttonOnlineMessage": "Hello, how can I help you?",
    "chatbuttonOnline": "Talk to us",
    "chatbuttonOfflineMessage": "We are offline, leave us a message",
    "chatbuttonOffline": "Offline",
    "waiting": "Please wait while we connect you to the support representative",
    "offlinecomplete": "Sorry, We are not available at the moment",
    "busy": "Sorry, All the agents are busy right now, Please try again later",
    "busycomplete": "Thanks for submitting your details, WIll call you back",
    "engaged": "Sorry! All the users are engaged in supporting other customers.",
    "engagedcomplete": "Thanks for submitting your details, You will hear from us shortly.",
    "ratingtitle": "Please provide your valuable rating poor/average/excellent",
    "ratingcomplete": "Thanks for your valuable rating"
  },
  "registration": {
    "Title": "Sign up",
    "AlreadyHaveAnAccount?": "Already have an account?",
    "LogIn": " Log in",
    "FirstName": "First name",
    "LastName": "Last name",
    "PhoneNumber": "Phone number",
    "Email": "Email",
    "Password": "Password",
    "PaymentMethods": "Payment methods",
    "PaymentMethod": "Payment method",
    "InformationForClient": "Information for client",
    "orLoginWith": "or Log in With",
    "PrivacyPolicy": "Privacy policy",
    "and": "and",
    "Contacts": "Contacts",
    "LegalInformation": "Legal information",
    "GeneralTermsAndConditions": "General Terms and Conditions",
    "GreenWaInfrastructure": "GreenWay Infrastructure s. r. o.",
    "AndThe": "and the",
    "PriceList": "Price List",
    "InformationOnPersonalDataProtection": "Information on personal data protection",
    "PlatformForAlternativeDisputeResolutionForConsumers": "Platform for alternative dispute resolution for consumers",
    "Download": "Download",
    "Step": "Step",
    "Payment": "Payment",
    "Client data": "Client data",
    "Car data": "Car data",
    "Pricing": "Pricing",
    "RequiredFields": "* Required fields",
    "version": "Version",
    "privacySettings": "Privacy Settings",
    "31.roaming.title": "Roaming",
    "31.roaming.content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "32.roaming.title": "Roaming",
    "32.roaming.content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "32.unusedKwh.title": "Unused Kwh",
    "32.unusedKwh.content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "33.roaming.title": "Roaming",
    "33.roaming.content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "33.unusedKwh.title": "Unused Kwh",
    "33.unusedKwh.content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "cardDetails": "Card details",
    "personalData": "Personal data",
    "companyData": "Company data",
    "paymentCard": "Payment card",
    "amountReserved": "We need to verify that you have functional card for future payments. In this step, we will reserve {amount} on hold. This amount will be returned within X working days.",
    "chargingCard": "Charging card",
    "reviewYourOrder": "Please review your order",
    "confirmation": "Confirmation",
    "summary": "Summary",
    "monthlyUntil": "Monthly fee - until end of current month",
    "oneTimeFee": "One time fee",
    "sum": "Sum",
    "followingPayments": "Following monthly payments",
    "monthlyFee": "Monthly fee",
    "promotionTitle": "Summer campaign 2024",
    "promotionDescription": "Get promo package of 50 kWh for 20 EUR which you can use in GreenWay network but also on all available roaming stations, regardless of connector type and power. This promotion is limited to first 300 users. If you order charging card with this promo offer, you will get an extra 50 kWh coupon.",
    "promotionItemTitle": "50 kWh for charging in GreenWay and roaming network"
  },
  "IAccept": {
    "Main": "* I accept {terms} and the {pricelist}",
    "Terms": "General Terms and Conditions"
  },
  "registrationWizard": {
    "Welcome": "Welcome",
    "closeRegistration": "Close registration",
    "PleaseCompleteTheData": "Please, complete the data.",
    "EnterSmsCode": "Please enter the SMS code we just sent you to {phoneNo}",
    "YouCanSkipThisStep": "You can skip this step, but we need you to verify your number in 24 hours.",
    "YouWantToCreateAccountWithSubscription": "You want to create account with subscription fee?",
    "Back": "Back",
    "SaveAndNext": "Save & Next",
    "AccountSetup": "Account setup",
    "PricePlan": "Price plan",
    "CheckPlanForYou": "Check out which plan is for you",
    "PersonalDetails": "Personal details",
    "Yes": "Yes",
    "Cancel": "Cancel",
    "Finish": "Finish",
    "charging": "Charging",
    "freeKWhs": "Free kWh in program",
    "recommended": "Recommended",
    "bestChoice": "Best choice",
    "selectForMonthlyPrice": "Choose for {price}{currency} per month",
    "overstayFeeTitle": "Overstay fee",
    "overstayFee": "rate per 1 minute after exceeding the time free of charge",
    "overstayACTime": "free connection time for AC*",
    "overstayDCTime": "free connection time for DC"
  },
  "Pricing": {
    "SelectPriceProgram": "Select price program",
    "AC&DC": "AC & DC",
    "FreeKwh": "{freekwh} kWh FREE",
    "NoFreeKwh": "NO FREE kWh",
    "FreeKwhComment": "You will get {freekwh} kWh free in this package, monthly",
    "NoFreeKwhComment": "You will dont get free kWh in this package",
    "CurrentlyActive": "Currently active",
    "ChangePriceProgramNote": "Price program can be changed only once a month. The changed price program is activated the next day only. The change is possible only after 30 days of using an ordered program.",
    "energiaPlusPriceOffDescription": "50% off",
    "energiaPlusPriceOffSummaryTile": "Summer campaign",
    "energiaMaxPriceOffDescription": "50% off",
    "energiaMaxPriceOffSummaryTile": "Summer campaign"
  },
  "Client": {
    "SelectTypeOfRegistration": "Select type of registration",
    "Individual": "Individual",
    "Company": "Company",
    "LegalEntityOrSelfEmployedPerson": "Legal entity or self-employed person",
    "CompanyBusinessId": "Company business ID",
    "NIP": "NIP",
    "ICO": "IČO",
    "OIB": "OIB",
    "CompanyName": "Company name",
    "IAmSelfEmployedPerson": "I'm self-employed person",
    "TaxId": "Tax ID",
    "MBS": "MBS",
    "IAmVatPayer": "I'm VAT payer",
    "VatId": "Vat ID",
    "PersonalInformation": "Personal information",
    "AuthorizedPerson": "Authorized person",
    "Title": "Title",
    "FirstName": "First Name",
    "LastName": "Last Name",
    "DateOfBirth": "Date of birth",
    "CommunicationLanguage": "Communication language",
    "Phone": "Phone",
    "Verify": "Verify",
    "SaveAndVerify": "Save and verify",
    "Email": "Email",
    "MoreDriversCouldBeAddedAfterFinishingRegistration": "More drivers could be added after finishing registration",
    "MoreInfo": "more info",
    "BillingAddress": "Billing address",
    "Country": "Country",
    "City": "City",
    "Street": "Street",
    "HouseNumber": "House number",
    "ZipCode": "Zip code",
    "DeliveryAddress": "Delivery address",
    "BlockedChargingServices": "Blocked charging services - view more",
    "DifferentDeliveryAddressForSendingRechargingCardAndCorrespondence": "Different delivery address for sending recharging card and correspondence",
    "IGrantMyConsent": "I grant my consent for processing and using of my personal data in order to execute the service rendered by GreenWay, in accordance with the general regulation on the protection of personal data of 27 April 2016 (Journal of Laws UE L 119 of 04.05.2016), i.e. after 25 May 2018, in accordance with art. 6 (1) a) of this regulation. I acknowledge that: (1) Administrator of personal data is GreenWay Polska Sp z o.o. with its registered office in Gdynia, data may be transferred to entities which belong to the GreenWay Holding S.A. Group, especially to GreenWay Infrastructure s.r.o., with its registered office in Bratislava, Slovakia (2) I have the right to access to my personal data and to make appropriate corrections (3) My consent may be revoked at any time, however it will not be possible to use the service rendered by Greenway",
    "ResendVerificationEmail": "Resend verification email",
    "DeliveryName": "Name"
  },
  "PhoneVerification": {
    "VerifyPhoneNumber": "Verify Phone Number",
    "AlreadyVerified": "Phone number {phoneNo} was already verified",
    "SentTheCode": "We have sent the code via SMS. Please, enter the code. Didn't you get the SMS?",
    "SendAgain": "Send again",
    "EnterCode": "Enter verification code",
    "Skip": "Skip",
    "Verify": "Verify",
    "Ok": "Ok",
    "PleaseVerifyMobile": "Please verify your mobile number within 24 hours, otherwise access to recharging services will be blocked.",
    "CustomerServiceHotline": "If you have problem to verify the phone number, please contact our Customer Service hotline at"
  },
  "Car": {
    "EnteringDataAboutYourCar": "Entering data about your car enables us to provide you more specific information about charging and chargers compatible with your car. Your car details will also help us to solve potential issues faster",
    "CarInformation": "Car information",
    "Producer": "Producer",
    "Model": "Model",
    "PleaseSelectCarProducer": "Please, select car Producer",
    "Version": "Version",
    "PleaseSelectCarModel": "Please, select car Model",
    "MyElectricVehicleIsNotInTheList": "My electric vehicle is not in the list",
    "PleaseFillInTheProducerModelAndVersionOfYourCar": "Please, fill in the Producer, Model and Version of your car",
    "YearOfRegistration": "Year of registration",
    "YearOfProduction": "Year of production",
    "PlateNumber": "Plate number",
    "ByProvidingTheVehicleDataYouAgree": "By providing the vehicle data you agree to their processing for the purpose of providing assistance relating to recharging services and for the purpose of analyzing e-mobility and recharging services topics; the analysis will only refer to cumulative and generalized data"
  },
  "payment": {
    "startCharging": "Start charging",
    "or": "or",
    "marketingInformation": "I agree with sending of marketing information.",
    "ok": "ok",
    "back": "back",
    "continue": "Continue anyway",
    "payWithCard": "Pay with card",
    "ExpireAt": "Expire at",
    "IfYouWillNot": "If you will not provide your consent:",
    "IAgreeToProvideSK": "\"I agree to provide the charging service based on my request before the expiration of the withdrawal period (14 days) and I am informed that by providing this service I will lose the right to withdraw from the contract with GreenWay Infrastructure s. r. o.\"",
    "IAgreeToProvidePL": "\"According to art. 21 par. 2 of the Act on Consumer Rights, I demand the commencement of service by GreenWay before the deadline for withdrawal from the contract.\"",
    "YouWillNotBeAble": "you will not be able to use our services, due to legislative restrictions, for first 14 days from the successful completion of the registration",
    "ConsentMayBeProvided": "Consent may be provided after registration in the Client zone settings.",
    "youGaveToAgree": "If you want to use charging services immediately, you have to agree with consent:",
    "CheckBoxAgreeSK": "I wish and agree to start using recharging services prior the expiration of period of 14 days to withdraw from the contract. I confirm that I have been\\n' +\n          'informed that, by giving this consent, I am forfeiting the right to withdraw from the contract in case, and after provision of the service in full.",
    "CheckBoxAgreePL": "According to art. 21 par. 2 of the Act on Consumer Rights, I demand the commencement of service by GreenWay before the deadline for withdrawal from the contract.",
    "ifYouNeedStartCharging": "If you need to start charging immediately, you can do it now. After your charging, our charging services will be available again only after you complete your registration.",
    "ifyouneedstartcharging-pl": "EN-PL If you need to start charging immediately, you can do it now. After your charging, our charging services will be available again only after you complete your registration.",
    "ifyouneedstartcharging-sk": "EN-SK If you need to start charging immediately, you can do it now. After your charging, our charging services will be available again only after you complete your registration.",
    "PleaseNoteThatPrices": "Please note, that prices for Energia Standard program will apply and that reservation from your payment card will be applied for max. 24hours.",
    "pleasenotethatprices-pl": "EN-PL Please note, that prices for Energia Standard program will apply and that reservation from your payment card will be applied for max. 24hours.",
    "pleasenotethatprices-sk": "EN-SK Please note, that prices for Energia Standard program will apply and that reservation from your payment card will be applied for max. 24hours.",
    "IfYouWantToStartCharging": "If you want to start charging immediately, you must explicitly give the following consent:"
  },
  "thankYou": {
    "thankYou": "Thank you!",
    "accountActivated": "Your account was activated and you can start charging your vehicle immediatelly. Go to our mobile application",
    "or": "or",
    "driverZone": "Driver Zone",
    "startCharging": "to start charging.",
    "download": "Download our application:",
    "first24Hours": "Account is activated for first charging or next 24 hours.",
    "continueUsing": "To continue using the charging services, is needed to finish registration.",
    "anyQuestions": "In case of any questions, please contact our 24/7 hotline support on:"
  },
  "logIn": {
    "OneTimeCharging": "One-time charging",
    "Don’tHaveAnAccount?": "Don’t have an account?",
    "SignUp": "Sign up",
    "Email": "Email",
    "Password": "Password",
    "RememberMe": "Remember me",
    "ForgotPassword?": "Forgot password?",
    "LogIn": "Log in",
    "Recaptcha": "Recaptcha",
    "VerifyEmail": "Please login to verify your email",
    "EmailVerified": "Email was successfully verified",
    "VerifyEmailError": "Error occurred on email verification",
    "PrivacyPolicy": "Privacy policy",
    "and": "and",
    "GeneralTermsAndConditions": "General Terms and Conditions"
  },
  "welcome": {
    "SignUp": "Sign up",
    "LogIn": "Log In",
    "OneTimeCharging": "One-time charging"
  },
  "aside": {
    "EverythingForCharging": "Everything for charging",
    "yourElectricVehicle": "your electric vehicle"
  },
  "news": {
    "News": "News",
    "Personal": "Personal",
    "Marketing": "Marketing",
    "SearchNews": "Search in news...",
    "loadMore": "Load More"
  },
  "ModalWelcome": {
    "WelcomeToGreenWayNetwork": "welcome to GreenWay network",
    "GreenWayNetworkIsTheLargest": "GreenWay network is the largest electric vehicle charging network in Central & Eastern Europe! There are many benefits for you to experience on your electric journeys.",
    "IfYouHaveAnyQuestions": "If you have any questions, please feel free to contact us.",
    "ThankYouForDrivingElectric": "Thank you for driving electric and we wish you many happy and safe electric kilometres.",
    "GoToClientPortal": "Go to Client portal"
  },
  "customizeColumn": {
    "CustomView": "Custom view",
    "Customize": "Customize"
  },
  "chargerManagement": {
    "ChargerManagement": "Charger management",
    "Status": "status",
    "Locations": "locations",
    "Chargers": "Chargers",
    "Connectors": "Connectors",
    "Consumption": "Consumption",
    "MyChargersSessions": "My chargers sessions",
    "MyChargingSessions": "My charging sessions",
    "ChargerBeenStopped": "Charger has been stopped",
    "ChargerBeenStarted": "Charger has been started",
    "ChargerBeenRestarted": "Charger has been restarted",
    "ConnectorBeenStarted": "Connector has been started",
    "ConnectorBeenStopped": "Connector has been stopped",
    "YouWantToRestartCharging": "You want to restart charging",
    "YouWantToStopCharging": "You want to stop charging",
    "YouWantToStartCharging": "You want to start charging",
    "startCharging": "Start charging",
    "stopCharging": "Stop charging",
    "restartCharging": "Restart charging"
  },
  "chargerDetails": {
    "ChargerDetail": "Charger details",
    "ChargePoint": "Charge point",
    "Status": "Status",
    "Information": "Information",
    "Sessions": "Sessions",
    "ChargePointInformation": "Charge point information",
    "ChargePointCode": "Charge point code",
    "FirmwareVersion": "Firmware version",
    "SerialNumber": "Serial number",
    "InOperationSince": "In operation since",
    "ChargePointModel": "Charge point model",
    "LastFirmwareChangeTime": "Last firmware change time",
    "ChasisNumber": "Chasis number",
    "ConnectorCode": "Connector code",
    "ConnectorInformation": "Connector information",
    "MaxPower": "Max power",
    "ConnectorChargingType": "Connector charging type",
    "ConnectorStatus": "Connector status",
    "EVSE Code": "EVSE Code",
    "EVSE ID": "EVSE ID",
    "EIPA ID": "EIPA ID",
    "EIPA status": "EIPA status",
    "EIPA website": "EIPA WEBsite",
    "ConnectorType": "Connector type",
    "Connected": "Connected",
    "NotConnected": "Not connected",
    "refresh": "Refresh connector statuses",
    "ChargerInfo": "Charger Info",
    "RefreshText": "Refresh",
    "IconConnectorType2Cable": "with cable",
    "IconConnectorType2Socket": "without cable",
    "locationName": "Location name",
    "address": "Address",
    "gps": "GPS",
    "manufacturer": "Manufacturer",
    "firmwareVersion": "Firmware version",
    "assignedTo": "Assigned to",
    "locationInformation": "Location information"
  },
  "locationDetails": {
    "LocationDetail": "Location detail",
    "SystemLocationInformation": "System location information",
    "PublicLocationInformation": "Public location information",
    "LocationCode": "Location code",
    "LocationName": "Location name",
    "LocationStatus": "Location status",
    "Sessions": "Sessions",
    "AccessType": "Access type",
    "Address": "Address",
    "OpeningHours": "Opening hours",
    "Open": "Open",
    "Closed": "Closed",
    "Access": "Access",
    "Images": "Images",
    "GPSLatitude": "GPS latitude",
    "GPSLongitude": "GPS longitude"
  },
  "chargers": {
    "Code": "Code",
    "Id": "Id",
    "Name": "Name",
    "Charger": "Charger",
    "ChargerCode": "Charger code",
    "chargerStatus": "Charger status",
    "Location": "Location",
    "LocationCode": "Location Code"
  },
  "locations": {
    "Code": "Code",
    "Id": "Id",
    "Name": "Name",
    "Status": "Status",
    "Location": "Location",
    "LocationCode": "Location code",
    "LocationStatus": "Location status"
  },
  "connectors": {
    "Code": "Code",
    "Id": "Id",
    "ConnectorID": "Connector ID",
    "ConnectorStatus": "Connector status",
    "ConnectorType": "Connector type"
  },
  "sessions": {
    "deviceCategory": "Device category",
    "user": "User",
    "charger": "Charger",
    "Sessions": "Sessions",
    "Export": "Export",
    "Customize": "Customize",
    "FilterBy": "Filter by",
    "FilterByDriver": "Filter by driver",
    "DriversGroup": "Drivers group",
    "Date": "Date",
    "AC/DC": "AC / DC",
    "Location": "Location",
    "InvoiceID": "Invoice ID",
    "InvoiceNumber": "Invoice number",
    "PriceMin": "Price min",
    "PriceMax": "Price max",
    "someCustomFields": "some custom fields",
    "connectorType": "Connector type",
    "invoiceStatus": "Invoice status",
    "statusSession": "Status session",
    "DurationMin": "Duration min",
    "DurationMax": "Duration max",
    "RunningSession": "Running session",
    "Roaming": "Roaming",
    "Device": "Device",
    "sessionDetail": "Session Detail",
    "locationCode": "location ID",
    "locationName": "Location name",
    "locationAddress": "Location address",
    "totalkWh": "Total kWh",
    "totalkWhMin": "Total kWh min",
    "totalkWhMax": "Total kWh max",
    "MaxkWhMin": "Max kWh min",
    "MaxkWhMax": "Max kWh max",
    "totalMinutes": "Total minutes",
    "maxChargingPower": "Max charging power",
    "maxConnectorPower": "Connector power max",
    "minConnectorPower": "Connector power min",
    "averagePower": "Average power",
    "averagePowerMin": "Average power min",
    "averagePowerMax": "Average power max",
    "chargingType": "Charging type",
    "chargingFrom": "Charging from",
    "chargingTo": "Charging to",
    "connectedFrom": "Connected from",
    "connectedTo": "Connected to",
    "priceIncludedVAT": "Price included VAT",
    "Driver": "Driver",
    "RoamingSession": "Roaming session",
    "DiscountsAndMonthlyFees": "* Discounts and monthly fees are not included in the price for session.",
    "chargerType": "Charger type"
  },
  "facilitySessions": {
    "chargersSessions": "Chargers` sessions",
    "chargerSessionDetail": "Charger session Detail",
    "ChargerId": "Charger Id",
    "ChargerManufacture": "Charger Manufacture",
    "ChargerModel": "Charger model"
  },
  "invoices": {
    "notAvailable": "is not available",
    "Invoices": "Invoices",
    "All": "All",
    "paid": "Paid",
    "unpaid": "Unpaid",
    "overpaid": "Overpaid",
    "spendings": "Spending",
    "partialy_paid": "Partially Paid",
    "in_preparation": "In preparation",
    "Customize": "Customize",
    "FilterByDriver": "Filter by driver",
    "Status": "Status",
    "DriversGroup": "Drivers group",
    "Date": "Date",
    "AC/DC": "AC / DC",
    "Location": "Location",
    "DownloadInvoice": "Download invoice",
    "DownloadListOfSessions": "Download list of sessions",
    "GoToListOfSessions": "Go to list of the sessions",
    "ByInvoiceNumber": "By invoice number",
    "itemsAreSelected": "items are selected",
    "InvoiceDetail": "Invoice detail",
    "InvoiceNumber": "Invoice number",
    "VariableSymbol": "Variable symbol",
    "Amount": "Amount",
    "AmountNet": "Amount net",
    "TaxAmount": "Tax Amount",
    "IssuedDate": "Issued date",
    "DueDate": "DueDate",
    "DateFrom": "Date from",
    "DateTo": "Date to",
    "BillingPeriod": "Billing period",
    "GreenWayBankAccount": "GreenWay bank account",
    "PayViaQRCode": "Pay via QR code",
    "ClearFilters": "Clear all filters",
    "PayInvoice": "Pay invoice",
    "PayInvoices": "Pay unpaid invoices",
    "InvoiceWasSuccessfullyPaid": "Invoice was successfully paid",
    "InvoicesWereSuccessfullyPaid": "Invoices were successfully paid",
    "More": "More",
    "Pay": "Pay",
    "downloadInvoiceError": "Not possible to download invoice, please contact customer support.",
    "downloadStatementError": "Not possible to download list of sessions, please contact customer support.",
    "period": "This is spending of invoice that will be issued for GreenWay services from {from} to {to}",
    "ChargingServices": "Charging services",
    "InvoicingData": "Invoicing data",
    "EditIn": "Invoicing data can be edited in",
    "settings": "Settings",
    "CompanyName": "Company name",
    "BusinessId": "Business ID",
    "TaxId": "TAX ID",
    "Address": "Address",
    "PaymentMethod": "Payment method",
    "BrainTreeOption": "The invoice amount will be automatically withdrawn from your payment card",
    "PayoutOption": "Payout",
    "BankTransferOption": "Bank transfer",
    "FullVatPrice": "Full price with VAT",
    "FullPriceWithoutVat": "Full Price without VAT",
    "VatAmount": "VAT amount",
    "CreditNote": "Credit note",
    "CreditNoteNumber": "Credit note number",
    "DownloadCreditNote": "Download Credit note"
  },
  "drivers": {
    "DriversManagement": "Drivers management",
    "FilterByNameEmail": "Filter by name or email",
    "DriverData": "Driver data",
    "addDriver": "add driver",
    "Drivers": "Drivers",
    "Group": "Group",
    "Notifications": "Notifications",
    "itemsAreSelected": "items are selected",
    "Name": "Name",
    "Email": "Email",
    "Phone": "Phone",
    "NotCharging": "Not charging",
    "Running": "Running",
    "Month": "month",
    "details": "Details",
    "BlockedDrivers": "Blocked drivers",
    "ByPriceProgram": "By price program",
    "ActuallyChargingDrivers": "Actually charging drivers",
    "contactOperator": "There is an issue with your account. Please contact our helpdesk.",
    "insertSpecialCharacter": "Insert special character: ł",
    "requiredField": "* required field",
    "tariffChangesTitle": "Tariff changes",
    "tariffName": "Name",
    "start": "Start",
    "end": "End"
  },
  "addDriver": {
    "iWant": "I want",
    "standard": "Standard",
    "fast": "Fast",
    "ultraFast": "Ultra-fast",
    "noFees": "No monthly fee",
    "transferOfUnused": "Transfer of unused kWh from the previous month",
    "thisIsHowIDrive": "This is how I drive",
    "upTo25Description": "I charge the car at home. I usually drive short routes. I use public charging occasionally.",
    "upTo100Description": "I charge mainly at home and at work. I use public charging on business trips and on trips on average once a month.",
    "moreThan100Description": "I drive a lot and often, I use public charging at least once a week.",
    "standardPL": "AC",
    "fastPL": "DC",
    "ultraFastPL": "DC",
    "noFeesPL": "No monthly fee",
    "transferOfUnusedPL": "Transfer of unused kWh from the previous month",
    "thisIsHowIDrivePL": "This is how I drive",
    "upTo25DescriptionPL": "I charge the car at home. I usually drive short routes. I use public charging occasionally.",
    "upTo100DescriptionPL": "I charge mainly at home and at work. I use public charging on business trips and on trips on average once a month.",
    "moreThan100DescriptionPL": "I drive a lot and often, I use public charging at least once a week.",
    "upTo25": "up to 25 kW (incl.)",
    "upTo100": "up to 100 kW (incl.)",
    "moreThan100": "more than 100 kW",
    "upTo25PL": "up to 25 kW (incl.)",
    "25to70PL": "25 to 70 kW (incl.)",
    "70to140PL": "70 to 140 kW (incl.)",
    "moreThan140PL": "up to 140 kW (incl.)",
    "AddDriver": "Add driver",
    "cancel": "cancel",
    "save": "save",
    "done": "Done",
    "requiredFields": "You need to fill the required fields",
    "SelectPriceProgram": "Select price program",
    "DriverData": "Driver data",
    "FirstName": "Name",
    "LastName": "Last name",
    "CommunicationLanguage": "Communication language",
    "Phone": "Phone",
    "Email": "Email",
    "Identificator": "Identificator",
    "Password": "Password",
    "ConfirmPassword": "Confirm Password",
    "Producer": "Producer",
    "Version": "Version",
    "Model": "Model",
    "VehicleInformation": "Vehicle Information",
    "YearOfRegistration": "Year of registration",
    "YearOfProduction": "Year of production",
    "PlateNumber": "Plate number",
    "termsAndConditions1": "I accept the and",
    "termsAndConditions2": "and confirm being aware of the obligation to pay the price for the recharging services.",
    "accept": "accept",
    "terms": "General Terms",
    "and": "and",
    "conditions": "Conditions",
    "MoveTheCarAbove": "Move the car above and set your monthly drive range",
    "CheckOutPlanForYou": "Check out which plan is for you",
    "IsNotInTheList": "My electric vehicle is not in the list",
    "Month": "month",
    "Type": "Type",
    "Power": "Connector power",
    "Price": "Price",
    "hide": "hide",
    "details": "details",
    "FreeConnectionTime": "Free connection time (min)",
    "TimeReservedForCharging": "Time reserved for charging (min)",
    "FillProducerModelVersion": "Please, fill in the Producer, Model and Version of your car",
    "Show": "Show",
    "Hide": "Hide",
    "Benefits": "Benefits",
    "MonthlyFee": "Monthly fee",
    "MonthlyFeeSK": "Monthly fee",
    "MonthlyFeePL": "Monthly fee",
    "PriceListNotePL": "Payment for charging consists of the fee per kWh and the fee per minute. The fee per minute is 0,05 PLN for AC and DC ≤ 25 kW and 0,40 PLN for all other stations for each minute exceeded after the free connection time.\nRate for minutes applies to time, by which the client's connection time during a single session exceeds the payment-free connection time. It applies to each started minute. This fee is not applicable for AC recharging services between 20:00 and 8:00 in the morning next day when using own GreenWay's recharging points.",
    "PriceListNoteSK": "After free connection time expires cost is 0.10 €/min\nThe fee per minute does not apply at AC points from 8:00 p.m. till 8:00 a.m. the next day at GreenWay's own stations. The free connection time is calculated from the start of charging with an interruption at the specified time.\nFor the detailed information please see official price list."
  },
  "addVehicle": {
    "AddVehicle": "AddVehicle",
    "Producer": "Producer",
    "Version": "Version",
    "Model": "Model",
    "YearOfRegistration": "Year of registration",
    "YearOfProduction": "Year of production",
    "Other": "Other (Not in list)",
    "Registration": "Registration",
    "Production": "Production",
    "PlateNo": "Plate No",
    "PlateNumber": "Plate number",
    "cancel": "cancel",
    "save": "save"
  },
  "addcard": {
    "addcard": "Add RFID Card",
    "code": "Code",
    "example": "Example: 123 456 789",
    "cancel": "Cancel",
    "save": "Add"
  },
  "cardsdetail": {
    "addcard": "Add Card",
    "cardsdetail": "RFID Card Detail",
    "nocardtext": "You will receive your RFID card by mail. We ship card within 7 days after registration. If you do not received it, please contact our Customer Service."
  },
  "orderCard": {
    "requestTitle": "RFID Card for your charging",
    "requestDescription": "In case you would like to use RFID card for charging, feel free to order one for your driver. We ship the card within 7 days after registration. If you did not receive any, please contact our Customer Service department. Before being able to use your card, please register it via Client Zone, module Drivers.",
    "requestDescriptionSK": "In case you would like to use RFID card for charging, feel free to order one for your driver. We ship the card within 7 days after registration. If you did not receive any, please contact our Customer Service department. Before being able to use your card, please register it via Client Zone, module Drivers.",
    "requestDescriptionPL": "In case you would like to use RFID card for charging, feel free to order one for your driver. We ship the card within 7 days after registration. If you did not receive any, please contact our Customer Service department. Before being able to use your card, please register it via Client Zone, module Drivers.",
    "requestDescriptionAddDriver": "In case you would like to use RFID card for charging, feel free to order one for your driver. We ship the card within 7 days after registration. If you did not receive any, please contact our Customer Service department. Before being able to use your card, please register it via Client Zone, module Drivers.",
    "allINeed": "All I need is a mobile app.",
    "allINeedSK": "All I need is a mobile app.",
    "allINeedPL": "All I need is a mobile app.",
    "title": "Order RFID card",
    "cardAlreadyOrdered": "The RFID Card has been already ordered in the past. If you did not receive any, please contact our Customer Service department.",
    "unableToOrder": "Unable to order RFID card! Please contact customer support",
    "orderCard": "Order card",
    "success": "Card successfully ordered.",
    "description": "Would you really like to order RFID card?\" We recommend to use the RFID card for roaming charging.\" The price of RFID card is {0} and it will be used to keep the World green."
  },
  "driverDetails": {
    "DriverDetail": "Driver detail",
    "DriverZone": "Driver Zone",
    "Sessions": "Sessions",
    "VehicleDetail": "Vehicle Detail",
    "VehicleInformation": "Vehicle Information",
    "EditVehicle": "Edit vehicle information",
    "assign": "assign",
    "addVehicle": "Add vehicle",
    "ChargingLimits": "Charging limits",
    "Add": "Add",
    "cancel": "Cancel",
    "AllowedLocations": "Allowed locations",
    "SelectAllowedLocations": "Select allowed locations",
    "Driver`sView": "Driver`s view",
    "SelectView": "Select view",
    "closeTariffChangeModal": "Close"
  },
  "driverInfo": {
    "cancelInfo": "Do you want to cancel price program change?",
    "cancelChange": "Cancel change",
    "emailInfo": "When you change your email, you will need to verify your email address.",
    "smsInfo": "When you change your mobile phone number, you will receive an SMS code.",
    "changeOfPriceProgram": "Change of price program",
    "confirmChange": "Confirm change",
    "specialPriceApplied": "Special pricing is applied to you based on your contract with GreenWay",
    "changePriceProgram": "Change price program",
    "monthly": "monthly",
    "NoCurrentCharge": "No current charge",
    "CurrentCharge": "Charging in progress...",
    "InProgress": "in progress...",
    "StopCharging": "Stop Charging",
    "StartCharging": "Start Charging",
    "Status": "Status",
    "Active": "Active",
    "Blocked": "Blocked",
    "BlockedLogin": "Blocked",
    "Charging": "Charging",
    "Unblocked": "Unblocked",
    "NotCharging": "Not charging",
    "Running": "Running",
    "Phone": "Phone",
    "DriversGroup": "Drivers group",
    "PriceProgram": "Price program",
    "Email": "Email",
    "Note": "Note",
    "FirstName": "Name",
    "LastName": "Last name",
    "CommunicationLanguage": "Communication language",
    "Password": "Password",
    "PasswordConfirm": "Password confirmation",
    "Lang": "Communication language",
    "AreYouSure": "Are you sure",
    "Done": "Done",
    "Change": "Change",
    "YouWantDeleteDriver": "You want to delete the driver",
    "YouWantDeleteVehicle": "You want to delete the vehicle",
    "YouWantDeleteCard": "You want to delete the card",
    "YouWantEditDriver": "You want to edit the driver",
    "DriverBeenDeletedSuccessfully": "Driver has been deleted successfully",
    "VehicleBeenDeletedSuccessfully": "Vehicle has been deleted successfully",
    "DataBeenChangedSuccessfully": "Data has been changed successfully",
    "YouCanNotChangeData": "You can change these fields in account settings",
    "AskYourMainDriver": "Ask your main driver for changing this field",
    "priceProgramWillBeChanged": "Price program will be changed to",
    "YouWantToChangePriceProgram": "you want to change price program",
    "PriceProgramHasBeenChanged": "Price program has been changed",
    "driverWillDeletedAt": "Driver will be deleted at",
    "unknown": "unknown",
    "on": "on",
    "NoAvailableCars": "You have no vehicle added",
    "EditPriceProgram": "Edit price program",
    "MoveMe": "Move Me",
    "cancel": "Cancel",
    "edit": "Edit",
    "apply": "Apply",
    "save": "Save",
    "delete": "Delete",
    "DriverWillBeDeleted": "Driver will be deleted at",
    "DriversWillBeDeleted": "Drivers will be removed at the end of the day or at the end of the billing period",
    "ChargingStoppedSuccessfully": "Charging was stopped successfully",
    "DoYouWantToCreateDriver": "Do you want to create a driver with a subscription fee?",
    "YouWantToChangeSubscription": "You want to change your subscription to paid?",
    "DriverHasBeenCreated": "Driver has been created successfully",
    "validFrom": "valid from",
    "toDetail": "Detail",
    "tariffActiveNextMonth": "Selected tariff ({tariff}) will be activated at the beginning of the following month.",
    "plannedPriceProgramChange": "Planned price program change",
    "plannedFrom": "from"
  },
  "vehicleInformation": {
    "title": "Vehicle Information",
    "infoMessage": "By filling in data about your car, we can provide you more information about charging and extract status of\n      compatible chargers to your car. Your car details will also help us to solve potential issues faster. By\n      providing the vehicle data I agree to their processing for the purpose of providing assistance relating to\n      recharging services and for the purpose of analysing e-mobility and recharging services topic; the analysis will\n      only refer to cumulative and generalized data."
  },
  "account": {
    "chargingSettings": "Charging Settings",
    "AccountSettings": "Account settings",
    "ClientAccount": "Client Account",
    "SignOut": "Sign out",
    "Cancel": "Cancel",
    "Update": "Update",
    "Save": "Save",
    "details": "details",
    "UpdateSettings": "Update settings",
    "EditSettings": "Edit settings",
    "PersonalData": "Personal data",
    "ManagePayment": "Manage payment",
    "security": "Security",
    "devices": "Devices",
    "unknown": "Unknown",
    "currentDevice": "Current device",
    "logOutEverywhere": "Log out everywhere",
    "cardExpired": "Your card {cardNo} has expired. Please remove it and add a new one.",
    "AdministrativeUsers": "Administrative users",
    "Customization": "Customization",
    "notifications": "Notifications",
    "YouWantEditData": "You want to edit data",
    "goBack": "Back to previous page",
    "priceList": "Price list",
    "termsAndConditions": "Terms and Conditions",
    "dataProtection": "Data protection",
    "disputeResolutionPlatform": "Dispute resolution platform",
    "withdrawalForm": "Withdrawal form",
    "informationOnPriceProgram": "Information on price program",
    "generalTermsAndConditions": "General Terms and Conditions",
    "informationOnPersonalDataProtection": "Information on personal data protection",
    "applicableWithin14Days": "Applicable within 14 days",
    "agreeGTC": "I accept the General Terms and Conditions and confirm being aware of the obligation to pay the price for the recharging services.",
    "agreeDataCorrect": "I would like to receive offers, updates, promotions and other marketing materials from GreenWay and so I hereby agree the use of my contact details for marketing communication.",
    "agreeMarketingCommunication": "I wish and agree to start using recharging services prior the expiration of period of 14 days to withdraw from the contract. I confirm that I have been informed that, by giving this consent, I am forfeiting the right to withdraw from the contract in case, and after provision of the service in full.",
    "agreeStartUsingImmediately": "I hereby agree to the processing of my personal data on using recharging services for the purpose of providing assistance relating to recharging services and for the purpose of analysing e-mobility and recharging services topics; the analysis will only refer to cumulative and generalized data.",
    "SessionsTitle": "Sessions",
    "SessionSummaryNotifications": "Send session summary email after each session",
    "AddPaymentCard": "Add payment card",
    "expires": "Expires",
    "removePaymentCard": "Remove card",
    "cardNumber": "Card no.",
    "pendingCheckoutMessage": "Processing payment card...",
    "addCardToUseChargingServices": "Please add your payment card to use charginng services",
    "actionsNeeded": "Actions needed",
    "skipForNow": "Skip for now"
  },
  "personalData": {
    "personalData": "Personal data",
    "Title": "Title",
    "FirstName*": "First name*",
    "LastName*": "Last name*",
    "DateOfBirth*": "Date of birth*",
    "CommunicationLanguage*": "Communication language*",
    "Phone*": "Phone*",
    "Email*": "Email*",
    "Password": "Password",
    "BillingAddressCompany": "Billing address",
    "BillingAddressIndividual": "Billing address",
    "Country*": "Country*",
    "City": "City",
    "Street*": "Street*",
    "HouseNumber*": "House number*",
    "ZipCode*": "Zip Code*",
    "DeliveryAddress": "Delivery address",
    "DifferentDeliveryAddress": "Different Delivery Address",
    "LinkedAccounts": "Linked Accounts",
    "Consents": "Consents",
    "InformationForUser": "Information for user",
    "CompanyInformation": "Company information"
  },
  "managePayment": {
    "withdrawalInfoMessage": "The application needs to verify that you have a functional card for future payments. In this step we will set {amount} on hold. This amount will be returned within 5 working days.",
    "withdrawalInfoMessagePayout": "The application needs to verify that you have a functional card for future payments.",
    "payingWithCard": "Paying with card",
    "removeCard": "Remove Card",
    "anotherWayToPay": "Replace payment card",
    "infoMessage": "By providing your payment card details you agree that our company is authorized to initiate payments from your payment card for our recharging services on due dates and in amounts set in accordance with the [General Terms and Conditions](https://data.greenway.sk/clientzone/GWI_SR_GTC_EN.pdf), the [Pricelist of Recharging Services](https://data.greenway.sk/clientzone/pricelist_EN.pdf), your price program and reflecting our discounts and/or any other special conditions agreed with you, during the entire duration of your framework contract concluded with us (hereinafter the “Mandate”). The Mandate applies equally to any payments for any other services and/or products provided to you by us upon your request. Moreover, the Mandate includes the right to initiate multiple payment transactions for fixed, as well as variable amounts (such as funds reservation for your payment card authorization, recurring and/or future transactions, including prepayments, (unscheduled) credential/card-on-file transactions and/or instalment transactions). In addition, by providing your payment card details you acknowledge that in order to secure the provision of the recharging services the payment service provider (i.e. [PayPal (Europe) S.a.r.l. et Cie, S.C.A.](https://www.braintreepayments.com/sk/legal )), processes your payment card details to the extent necessary for the purpose of any payment transaction made during the entire duration of your framework contract concluded with us. Your payment card details and/or payment processing updates may be accessed and/or updated at any time in mobile application or Client Zone. For more details on any potential payment refunds and/or payments see the the General Terms and Conditions of Recharging Services for Registered Clients (for example, Article 6 (Payment Method) and the Price List of Recharging Services (for example, Article 2 (Price Calculation and Payments)).",
    "bankTransferInfo": "GreenWay Slovakia: Tatra banka, a.s. : 2948021526/1100 IBAN / SWIFT: SK90 1100 0000 0029 4802 1526 / TATRSKBX The variable symbol is the invoice number. Payment to the bank account will be settled within 48 hours from payment.",
    "PaymentSetToBankTransfer": "Payment set to bank transfer",
    "replaceCard": "Replace payment card",
    "cardNumber": "Card Number",
    "paymentProvider": "Payment provider",
    "doYouReallyWantTo": "Do you really want to remove your payment card",
    "removingOfThePaymentCard": "Removing of the payment card will block charging services",
    "CardBeenDeletedSuccessfully": "Card been deleted successfully. Charging services were blocked. Please add your payment card to enable changing services",
    "ExpirationDate": "Expiration Date",
    "MM/YY": "(MM/YY)",
    "CVV": "CVV",
    "digits": "(3 digits)",
    "PayPal": "PayPal (Europe) S.a.r.l. et Cie, S.C.A.",
    "providingCardDetailsPayout": "By providing your payment card details you agree that our company is authorized to initiate payments from your payment card for our recharging services on due dates and in amounts set in accordance with the [General Terms and Conditions](https://data.greenway.sk/clientzone/GWI_SR_GTC_EN.pdf), the [Pricelist of Recharging Services](https://data.greenway.sk/clientzone/pricelist_EN.pdf), your price program and reflecting our discounts and/or any other special conditions agreed with you, during the entire duration of your framework contract concluded with us (hereinafter the “Mandate”). The Mandate applies equally to any payments for any other services and/or products provided to you by us upon your request. Moreover, the Mandate includes the right to initiate multiple payment transactions for fixed, as well as variable amounts (such as funds reservation for your payment card authorization, recurring and/or future transactions, including prepayments, (unscheduled) credential/card-on-file transactions and/or instalment transactions). In addition, by providing your payment card details you acknowledge that in order to secure the provision of the recharging services the payment service provider (i.e. [Payout, a.s.](https://payout.one/en)), processes your payment card details to the extent necessary for the purpose of any payment transaction made during the entire duration of your framework contract concluded with us. Your payment card details and/or payment processing updates may be accessed and/or updated at any time in mobile application or Client Zone. For more details on any potential payment refunds and/or payments see the the General Terms and Conditions of Recharging Services for Registered Clients (for example, Article 6 (Payment Method) and the Price List of Recharging Services (for example, Article 2 (Price Calculation and Payments)).",
    "providingCardDetails": "By providing your payment card details you agree that our company is authorized to initiate payments from your payment card for our recharging services on due dates and in amounts set in accordance with the [General Terms and Conditions](https://data.greenway.sk/clientzone/GWI_SR_GTC_EN.pdf), the [Pricelist of Recharging Services](https://data.greenway.sk/clientzone/pricelist_EN.pdf), your price program and reflecting our discounts and/or any other special conditions agreed with you, during the entire duration of your framework contract concluded with us (hereinafter the “Mandate”). The Mandate applies equally to any payments for any other services and/or products provided to you by us upon your request. Moreover, the Mandate includes the right to initiate multiple payment transactions for fixed, as well as variable amounts (such as funds reservation for your payment card authorization, recurring and/or future transactions, including prepayments, (unscheduled) credential/card-on-file transactions and/or instalment transactions). In addition, by providing your payment card details you acknowledge that in order to secure the provision of the recharging services the payment service provider (i.e. [PayPal (Europe) S.a.r.l. et Cie, S.C.A.](https://www.braintreepayments.com/sk/legal )), processes your payment card details to the extent necessary for the purpose of any payment transaction made during the entire duration of your framework contract concluded with us. Your payment card details and/or payment processing updates may be accessed and/or updated at any time in mobile application or Client Zone. For more details on any potential payment refunds and/or payments see the the General Terms and Conditions of Recharging Services for Registered Clients (for example, Article 6 (Payment Method) and the Price List of Recharging Services (for example, Article 2 (Price Calculation and Payments))."
  },
  "accountCustomize": {
    "UploadFullVersionLogo*": "Upload full-version logo*",
    "Browse": "Browse",
    "UploadShortVersionLogo*": "Upload short-version logo*",
    "ChangeColor": "Change color"
  },
  "help": {
    "Title": "FAQ - Frequently asked questions",
    "Empty": "Sorry, nothing found"
  },
  "NoAnswer": {
    "Title": "Did not find the answer? Please use our chat to start a conversation!",
    "Phone": "You can always call us on",
    "Email": "You can always write us on",
    "Schedule": "since Monday to Friday",
    "PhoneTechnical": "The technical helpline is active 24/7 at"
  },
  "coupons": {
    "name": "Coupon name",
    "description": "Description",
    "title": "My Coupons",
    "code": "Coupon Code",
    "scan": "scan QR code",
    "apply": "Apply Coupon",
    "success": "Coupon Successfully Registered",
    "successScan": "Coupon Successfully Scanned",
    "close": "close",
    "used": "Used On",
    "registred": "Registered At",
    "registeredAt": "Registered At",
    "usedAt": "Used at",
    "validTo": "valid to",
    "active": "Active",
    "campaign": "Campaign Name",
    "ScanFromFile": "Scan from file",
    "couponsAndDiscounts": "Coupons & Discounts",
    "applyCouponTitle": "Apply Coupon",
    "ok": "Ok",
    "cancel": "Cancel",
    "selectDriver": "Select driver"
  },
  "discounts": {
    "title": "Discounts",
    "description": "Discount description",
    "appliesTo": "Applies to",
    "appliesToAll": "Applies to all drivers",
    "validTo": "Valid to",
    "history": "History",
    "appliesToSession": "Applies to (charging) session",
    "appliesPerDriver": "Applies per (each) driver",
    "appliesToInvoice": "Applies to (whole) invoice"
  },
  "creditBox": {
    "title": "Amount of remaining credits",
    "validTo": "Valid to",
    "contractualDiscount": "Contractual discount",
    "specialDiscount": "Special discount",
    "wholeInvoice": "whole invoice"
  },
  "resetPassword": {
    "ForgottenPassword": "Forgotten password",
    "SendPassword": "Send a password reset link",
    "Email": "Email",
    "BackTo": "Back to",
    "LogIn": "Log in",
    "PasswordRecovery": "Password recovery",
    "NewPassword": "New password",
    "RepeatPassword": "Repeat password",
    "ConfirmPassword": "Confirm new password",
    "PasswordResetEmail": "Reset password email has been sent to:",
    "CheckYourInbox": "Please check your inbox",
    "ChangedSuccessfully": "Password was changed successfully",
    "SomethingGoesWrong": "Something goes wrong, please try again",
    "passwordIsNotMatch": "The passwords do not match"
  },
  "BtnGoBack": {
    "BackToPreviousPage": "Back to Previous page",
    "BackToDriverManagement": "Back to Driver management",
    "BackToClientZone": "Back to Client zone",
    "BackToRegistration": "Back to Registration",
    "Back": "Back",
    "ok": "ok"
  },
  "filter": {
    "ClearAll": "Clear all"
  },
  "error": {
    "InvalidValue": "Invalid value",
    "DuplicateEmail": "The email has already been taken",
    "PhoneNumberIsNotVerified": "Phone number is not verified",
    "InvalidEmail": "Invalid email",
    "FieldIsRequired": "Field is required",
    "MinimumSymbols": "Minimum 6 symbols contains uppercase, lowercase and number",
    "PasswordTooLong": "The password may not be greater than 45 characters",
    "PasswordConfirmationDoesNotMatch": "Password confirmation does not match",
    "FormContainsErrors": "Form contains errors",
    "OnlyDigits": "Please use only digits and + symbol",
    "OnlyYear": "Please fill a year",
    "SomethingGoesWrong": "Something goes wrong",
    "AuthorizationError": "Authorization error! You will be redirected to Welcome page.",
    "MustBe18Years": "Sorry, you must be 18 years of age to apply.",
    "MustBeLowerThenMaxValue": "Must be lower then max value",
    "MustBeGraterThenMinValue": "Must be grater then min value",
    "AtLeast3Characters": "Minimum 3 characters.",
    "AtLeast2Characters": "Minimum 2 characters.",
    "AtLeast1Character": "Minimum 1 character.",
    "IncorrectVatId": "Incorrect Vat ID",
    "IncorrectTaxId": "Incorrect Tax ID",
    "incorrectcardid": "Incorrect Card RFID",
    "IncorrectCompanyId": "Incorrect business id",
    "IncorrectPolishCompanyId": "Incorrect NIP",
    "IncorrectBirthDate": "You must be 18 years old or above",
    "CarRegistrationYear": "Registration year should be greater or equal to production year",
    "NoDataAvailable": "No data yet",
    "Caution": "Caution",
    "500": "An error occurred during processing, please try again or contact the customer support line +421 2 330 56 236",
    "404": "Error 404: Page not found!",
    "MissingPaymentCard": "Please add your payment card to add a driver with a subscription fee",
    "HasChanges": "There are unsaved changes. Do you want to leave the page?",
    "InvalidYear": "Invalid year",
    "MaxCharsExceeded": "Maximum characters exceeded",
    "LongPlateNumber": "The plate number may not be greater than 20 characters"
  },
  "cameraError": {
    "NotAllowedError": "Denied camera access permisson",
    "NotFoundError": "No suitable camera device installed",
    "NotSupportedError": "Page is not served over HTTPS",
    "NotReadableError": "Camera is already in use",
    "OverconstrainedError": "Did you requested the front camera although there is none?",
    "StreamApiNotSupportedError": "Browser seems to be lacking features"
  },
  "lang": {
    "English": "English",
    "Poland": "Poland",
    "Slovak": "Slovak"
  },
  "qrcode": {
    "scanned": "Scanned: "
  },
  "dashboard": {
    "TimeOfCharging": "Time of charging",
    "Dashboard": "Chargers dashboard",
    "Utilization": "Utilization (7 days)",
    "ConnectorsStatus": "Connectors status",
    "ChargingSessions": "Charging sessions per day",
    "TimeSpentCharging": "Time spent charging (minutes)",
    "ConsumedEnergy": "Consumed energy",
    "AvailableConnectors": "Available connectors",
    "OccupiedConnectors": "Occupied connectors",
    "ConnectorsOutOfOperation": "Connectors out of operation",
    "ConnectorsInFault": "Connectors in fault",
    "ConnectorsOutOfCommunication": "Connectors out of communication",
    "AC": "AC",
    "DC": "DC",
    "7days": "7 days",
    "Total": "Total",
    "companyChargers": "Company chargers",
    "homeChargers": "Home chargers",
    "all": "All"
  },
  "fleetDashboard": {
    "FleetDashboard": "Fleet dashboard",
    "ChargingSessionsPerDriver": "Charging sessions per driver (7 days)",
    "ConsumedEnergyPerDriver": "Consumed energy (kWh) per driver (7 days)",
    "TimeSpentChargingPerDriver": "Time spent charging (minutes) per driver (7 days)",
    "RoamingSessions": "Roaming sessions (7 days)",
    "ChargingSessions": "Charging sessions per day",
    "ConsumedEnergy": "Consumed energy (kWh)",
    "TimeSpentCharging": "Time spent charging (minutes)"
  },
  "tableHeaders": {
    "user": "User",
    "charger": "Charger",
    "tags": "Tags",
    "fromTo": "From - to",
    "consumption": "Consumption",
    "cost": "Cost",
    "odometer": "Odometer",
    "averageConsumption": "Average consumption",
    "cardid": "Card ID",
    "cardtype": "Card Type",
    "Name": "Name",
    "Status": "Status",
    "Email": "Email",
    "Phone": "Phone",
    "From": "From",
    "To": "To",
    "Vehicle": "Vehicle",
    "Price program": "Price program",
    "Communication language": "Communication language",
    "Duration": "Duration",
    "Energy": "Energy",
    "Price": "Price",
    "Currency": "Currency",
    "Max Power": "Max Power",
    "Average Power": "Average Power",
    "Location name": "Location name",
    "Device": "Device",
    "Browser": "Browser",
    "OperatingSystem": "Operating system",
    "LastActivity": "Last activity",
    "Duration minutes": "Duration minutes",
    "Total kWh": "Total kWh",
    "FreekWh": "Free kWh",
    "AC/DC": "AC/DC",
    "Driver": "Driver",
    "Access type": "Access type",
    "Code": "Code",
    "Street": "Street",
    "House number": "House number",
    "City": "City",
    "ChargerLabel": "Charger label",
    "Manufacture": "Manufacture",
    "Model": "Model",
    "Location": "Location",
    "LocationCode": "Location code",
    "Location ID": "Location ID",
    "Location address": "Location address",
    "Connector ID": "Connector ID",
    "Connector": "Connector",
    "Connector type": "Connector type",
    "Connector power": "Connector power",
    "Charger ID": "Charger ID",
    "External Invoice ID": "External Invoice ID",
    "Is roaming": "Is roaming",
    "Invoice Number": "Invoice Number",
    "Issue date": "Issue date",
    "Due date": "Due date",
    "Actions": "Actions",
    "PrintedId": "Printed ID",
    "AssigneeId": "Assignee ID",
    "AssigneeName": "Assignee Name",
    "NumberOfCards": "Number of cards",
    "RequestedOn": "Requested on",
    "InvoiceItems": "Invoice Items",
    "Quantity": "Quantity",
    "PriceWithoutVat": "Price without VAT",
    "VatAmount": "VAT amount",
    "PriceWithVat": "Price with VAT",
    "token": "Token"
  },
  "cookieBar": {
    "CookiesSettings": "Cookies Settings",
    "ThisPageUsesCookie": "This website uses cookies. We would like to provide you with a content that really interests you, therefore we use cookies. This is a common practice on most websites. Click the Allow all if you agree we can use all types of cookies to collect data in order to display relevant content.",
    "LearnMore": "Learn more",
    "Accept": "Accept",
    "AboutCookies": "About cookies",
    "AllowSelection": "Accept only essential cookies",
    "AllowAllCookies": "Accept all cookies",
    "DenyAllCookies": "Deny all",
    "Necessary": "Necessary",
    "Statistics": "Statistics",
    "ShowDetails": "show details",
    "NecessaryText": "Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.",
    "StatisticsText": "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.",
    "VITE_APP_COOKIE_ACCEPT": "http://data.greenway.sk/clientzone/legal_information_EN.pdf",
    "VITE_APP_COOKIE_ACCEPT_FALLBACK": "http://data.greenway.sk/clientzone/legal_information_EN.pdf"
  },
  "sidebar": {
    "Dashboard": "Dashboard",
    "Fleet": "Fleet",
    "News": "News",
    "Sessions": "Sessions",
    "Invoices": "Invoices",
    "Drivers": "Drivers",
    "Chargers": "Chargers",
    "chargersSessions": "Chargers` sessions",
    "Coupons": "Coupons",
    "chargingCards": "Charging cards",
    "Settings": "Settings",
    "Help": "Help",
    "Logout": "Logout",
    "locationMap": "Location Map"
  },
  "legal": {
    "LegalInformation": "Legal information",
    "Contact": "Contact",
    "GreenWayInfrastructure": "GreenWay Infrastructure s.r.o",
    "Documents": "Documents",
    "LegalInformationDocument": "Legal information document",
    "PrivacyPolicy": "Privacy policy",
    "AlternativeResolutions": "Alternative resolutions of consumers disputes",
    "BraintreePaymentTerms": "Braintree Payment Terms & Conditions",
    "DocumentsArchive": "Documents Archive",
    "FullRegistration": "full-registration",
    "OneTime": "one-time",
    "TpayPaymentTerms": "TPay Payment Terms & Conditions",
    "OurOfficeIsOpen": "Our office is open Monday to Friday from 09:00 to 18:00 Central European Time",
    "YouCanReachUsDirectly": "You can reach us directly at",
    "OurCustomerServiceHotline": "Our Customer Service hotline is open 24/7 at",
    "ClientService": "Client Service",
    "OtherIssuesAndInquiries": "Other Issues & Inquiries",
    "TechnicalSupport": "Technical support 24/7",
    "BusinessRegistered": "Business Registered",
    "BusinessID": "Business ID",
    "VatID": "VAT ID",
    "TaxID": "TAX ID",
    "ICDPH": "(IC DPH)",
    "DIC": "(DIC)",
    "NIP": "(NIP)",
    "ICO": "(ICO)",
    "KRS": "(KRS)",
    "OIB": "(OIB)",
    "REGON": "REGON",
    "Signature": "Signature",
    "Poland": "Poland",
    "DistrictCourtOf": "District Court of Bratislava I, Section: Sro, Insert 136109/B",
    "SupervisorAuthority": "Supervisor authority",
    "SlovakTradeInspections": "Slovak Trade Inspection, Central Inspectorate, P.O.BOX 29, Prievoszka 32, 827 99 Bratislava",
    "RegulatoryOfficeFor": "Regulatory Office for Network Industries, P.O.BOX 12, Bajkalska 27, 820 07 Bratislava",
    "BusinessAndCustomerService": "Business & Customer Service (Monday - Friday 9:00 - 17:00)",
    "Close": "Close",
    "content": "This is just a placeholder for legal information content."
  },
  "table": {
    "ItemsPerPage": "Items per page",
    "CardView": "Card view"
  },
  "popUp": {
    "Cancel": "Cancel",
    "Yes": "Yes"
  },
  "chargingForm": {
    "startCharging": "Start charging",
    "select": "Select",
    "location": "location",
    "charger": "charger",
    "connector": "connector",
    "back": "back",
    "next": "next",
    "minutes": "minutes",
    "myLocation": "My location",
    "cancel": "Cancel",
    "stop": "Stop",
    "freeOfCharge": "Free of charge",
    "DoYouWantToStartCharging": "Do you want to start charging?",
    "PricingInformation": "Pricing information*",
    "ConsumedEnergyFee": "Consumed energy fee",
    "ChargingTimeFeeAfter": "Charging time fee after",
    "DiscountsAreNotIncludedInPrice": "Discounts are not included in price",
    "ChargingHasBeenStarted": "Charging has been started successfully",
    "ChargingHasBeenStopped": "Charging has been stopped successfully",
    "chargingRequestProcessed": "Charging request has been processed"
  },
  "campaigns": {
    "HowToActivate": "How to activate your charging card",
    "StepsToActivateIfNoAccount": "Steps to activate charging card if you do not have an account",
    "StepsToActivateWithAccount": "Steps to activate charging card with your account",
    "IfYouHaveAnyQuestions": "If you have any questions, our Customer Service hotline is open 24/7 at",
    "GoToPage": "Go to page",
    "RegistrationPage": "client.greenway.sk/registration",
    "PleaseSignUp": "Please Sign Up",
    "InClientZoneGoToSection": "In Client zone go to section",
    "PleaseLoginInClientZoneGoToSection": "Please login into Client zone and go to section",
    "EnterYourCouponCode": "Enter your Coupon code {0} Format: Number  on RFID card - Code from \"welcome set\" {0} Example: 123456-abc123",
    "WeHopeYouWillEnjoyOurService": "We hope, you will enjoy our service, and we wish you many happy and safe electric kilometers",
    "PressApply": "press \"APPLY COUPON\""
  },
  "freeKWhpanel": {
    "freeKwhPackages": "Free kWh packages",
    "title": "Amount of free kWh",
    "amountPerDriver": "Amount of free kWh per driver",
    "validTo": "Valid to"
  },
  "driversManagement": {
    "title": "Manage Drivers",
    "description": "Need more charging accounts? Adding another driver gives you more options to manage accounts for your family or company fleet."
  },
  "tooltip": {
    "openMenu": "Open menu",
    "closeMenu": "Close menu"
  },
  "startCharging": {
    "connector": "Connector",
    "confirmStartCharging": "Confirm & start charging",
    "confirmStartChargingAnyways": "Start charging anyways",
    "loginToStart": "Login/register to start charging",
    "goToOneTimeCharging": "Use as one-time charging",
    "isRoamingConnectorMessage": "Start charging on roaming stations is possible only with charging card.",
    "oneTimeChargingTitle": "One-time charging",
    "oneTimeChargingRecaptcha": "Some text telling user that he/she has to verify recaptcha to proceed.",
    "cardDetails": "Card details - Set Maximum Charging Amount",
    "back": "Back",
    "submit": "Submit",
    "continue": "Continue",
    "cardVerifyText": "The application also needs to verify that you have functional card for future payments. In this step we will set {amount} on hold. This amount will be returned within 5 working days.",
    "returnsPolicy": "I agree with General Terms & Conditions",
    "connectorIsNotAvailable": "Connector is not available",
    "connectorNotFound": "Connector not found",
    "blockedUser": "User blocked, show info",
    "after": "after",
    "showPriceList": "Show full price list",
    "notLoggedPriceInfo": "The stated price is for one-time charging (login or register to get better price)",
    "initiatingConnection": "Initiating connection...",
    "connectThePlug": "Please connect the plug to vehicle",
    "chargingStarted": "Charging started!",
    "chargingNotStarted": "Charging not started",
    "showCharginDetails": "Show charging details",
    "endChargingSession": "End charging session",
    "charging": "Charging...",
    "chargingEnded": "Charging has ended successfully. Thank you for choosing GreenWay.",
    "finalChargerData": "Waiting for the final data from the charger",
    "temporaryOffline": "Charging station is offline",
    "temporaryOfflineText": "We are sorry, but we lost connection to this station. To end charging please use your charging card / end charging from your vehicle / or contact our support.",
    "sessionEnded": "Session ended",
    "thankYou": "Thank you",
    "sessionSummary": "Session summary",
    "plugDetails": "Plug details",
    "duration": "Duration",
    "start": "Start",
    "end": "End",
    "chargeTotal": "Charge total",
    "chargeForFree": "Charge for free",
    "chargePaid": "Charge paid",
    "priceVat": "Price incl. VAT",
    "showSummary": "Show summary",
    "chargerIsOffline": "Charger is offline. More info.",
    "elapsed_time": "Elapsed time",
    "active_energy_consumed": "Energy consumed",
    "state_of_charge": "Battery state",
    "power": "Power",
    "tryAgain": "Try again",
    "confirmAction": "Confirm action",
    "areYouSure": "Are you sure you want to terminate the charging session?",
    "areYouSureSummary": "You are leaving the area of your charging session. You won't be able to get the receipt neither to see the session data anymore.",
    "generatingReceipt": "Generating receipt...",
    "downloadReceipt": "Download Receipt",
    "sendReceipt": "Send Receipt",
    "enterYourEmail": "Enter your email",
    "yesTerminate": "Yes, terminate",
    "processing": "Processing...",
    "cancel": "Cancel",
    "close": "Close",
    "connectorStatusUnknown": "We are sorry, but status for this connector is unknown.",
    "UnableToStopChargingSession": "Unable to stop charging session.",
    "runningSessionElsewhere": "Running session elsewhere",
    "bestPriceTitle": "Charging prices start from",
    "chargingPriceTitle": "Charging price",
    "oneTimePriceTitle": "One-time charging price",
    "pleaseSelectFee": "This amount will be pre-authorized on your card, and charging will end once this limit is reached.",
    "gtcApproval": "Clicking on your chosen amount confirms acceptance of our General Terms & Conditions and initiates the charging session.",
    "cancelPyoutCheckout": "Cancel checkout",
    "checkoutCanceled": "Checkout canceled"
  },
  "chargingCards": {
    "pageTitle": "Charging cards",
    "exportTokens": "Export tokens",
    "orderNewCard": "Order new card",
    "numberOfChargingCards": "Number of charging cards",
    "maxCards": "A maximum of {limit} cards",
    "minCards": "At least 1 card",
    "ordersRemaining": "You can still order {orderAmount} cards. In case you would like to order more, please contact our Customer Support team.",
    "maxNumberOfOrdersReached": "You have reached maximum number of ordered cards. In case you would like to order more, please contact our Customer Support team.",
    "orderNewCardTitle": "Order charging card",
    "registerCardTitle": "Register GreenWay card/s",
    "registerCard": "Register card",
    "printedId": "Printed ID",
    "assigneeId": "Assignee ID",
    "addAnotherCard": "Add another card",
    "listOfChargingCards": "List of charging cards",
    "listOfOrders": "List of orders",
    "selectAll": "Select all",
    "cancel": "Cancel",
    "remove": "Remove",
    "send": "Send",
    "save": "Save",
    "deleteSelected": "Delete selected",
    "removeCard": "Remove card",
    "removeCards": "Remove cards",
    "removeCardPrompt": "Are you really sure you would like to delete the charging card? After deletion, you won't be able to register it again.",
    "removeCardsPrompt": "Are you sure you want to remove {cardAmount} cards?",
    "blockCard": "Block card",
    "blockCardPrompt": "Are you sure you want to block this card?",
    "unblockCard": "Unblock card",
    "unblockCardPrompt": "Are you sure you want to unblock this card?",
    "unblockPrimaryDriverCardPrompt": "Are you sure? Your current active card {currentCard} will be changed to {newCard}.",
    "unassigned": "- Unassigned",
    "chargingCardDetailTitle": "Charging card detail",
    "assignCard": "Assign card",
    "selectDriver": "Select driver to assign",
    "unassignCard": "Unassign card",
    "unassignCardPrompt": "Are you sure you want to unassign card number {cardNo} from {driver}?",
    "addNewCard": "Add new card",
    "noStatus": "No status",
    "pleaseSelectChargingCards": "Please select a charging card/s",
    "featureNotEnabled": "This feature is not enabled.",
    "exportQuestion": "Would you like to export selected charging card/s to a CSV file?",
    "activeOnly": "Active only",
    "unassignedOnly": "Unassigned only",
    "name": "Name",
    "startTypingName": "Start typing name"
  },
  "phoneNumber": {
    "help": "+421 2 330 56 236",
    "technical": "+421 2 33 56 236",
    "customerServiceOnline": "+421 2 33 56 236"
  },
  "freeKwh": {
    "freeKwh": "Free KWh",
    "of": "of",
    "until": "until",
    "showDetails": "Show details"
  },
  "footer": {
    "VITE_APP_CONTACTS": "https://greenway.sk/en/contact/",
    "VITE_APP_TERMS_LINK": "https://data.greenway.sk/clientzone/GTC_06112019_EN.pdf",
    "VITE_APP_PRICE_LIST_LINK": "https://data.greenway.sk/clientzone/pricelist_EN.pdf"
  },
  "information": {
    "VITE_APP_OFFICE_SK": "Einsteinova 24",
    "VITE_APP_OFFICE_SK_EXTRA_INFO": "851 01 Bratislava, Slovakia",
    "VITE_APP_WITHDRAWAL_LINK": "https://data.greenway.sk/clientzone/private_data_protection_EN.pdf",
    "VITE_APP_LEGAL_INFORMATION_LINK": "https://data.greenway.sk/clientzone/pl/GWP_GTC_EN.pdf",
    "VITE_APP_PRIVACY_POLICY_LINK": "https://data.greenway.sk/clientzone/pl/legal_information_EN.pdf",
    "VITE_APP_ALTERNATIVE_RESOLUTIONS_LINK": "https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN",
    "VITE_APP_BRAINTREE_TERMS_LINK": "https://www.braintreepayments.com/pl/legal",
    "VITE_APP_TPAY_TERMS_LINK": "https://tpay.com/en/terms-conditions",
    "VITE_APP_DOCUMENTS_ARCHIVE_LINK": "https://greenway.sk/en/archive-of-documents/",
    "VITE_APP_TERMS_ONE_TIME_LINK": "https://data.greenway.sk/clientzone/pl/GWP_GTC_ADHOC_EN.pdf",
    "VITE_APP_DATA_PROTECTION_LINK": "https://data.greenway.sk/clientzone/private_data_protection_EN.pdf",
    "VITE_APP_DISPUTE_RESOLUTION_LINK": "https://www.mhsr.sk/obchod/ochrana-spotrebitela/alternativne-riesenie-spotrebitelskych-sporov-1/alternativne-riesenie-spotrebitelskych-sporov"
  },
  "otCard": {
    "VITE_APP_GTC": "https://data.greenway.sk/clientzone/GWI_SR_GTC_One-time_EN.pdf"
  },
  "weblinks": {
    "VITE_APP_WEB": "https://greenwaynetwork.com/"
  },
  "priceProgram": {
    "overstayFeeContent": "Overstay fee"
  }
}