<script setup>
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

// components
import Btn from '@/components/inputs/Btn.vue'
import Modal from '@/components/modals/Modal.vue'
import NewsForm from '@/pages/news/components/NewsForm.vue'

// utils
import { apiRequest } from '@/utils/apiRequest'
import showMessageArray from '@/utils/errorMessageArray'
import showMessage from '@/utils/errorMessage'
import setPageTitle from '@/utils/pageTitle'

// helpers
import NewsLoader from '@/loaders/NewsLoader.vue'

// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

const { dispatch, getters, commit } = store
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const state = reactive({
    isModalWelcome: false,
    fullName: getters['account/fullName']
})
const hasSearchResults = ref(false)

const isLoading = computed(() => {
    return getters['isLoading']
})

const isLoadMore = computed(() => {
    return getters['news/isLoadMore']
})

const search = computed({
    get() {
        return getters['news/search']
    },
    set(newValue) {
        commit('news/setSearch', newValue)
    }
})

watch(search, (value) => {
    if (!value && hasSearchResults.value) {
        dispatch('news/getNews')
        return
    }
    hasSearchResults.value = false
})

onBeforeMount(() => {
    if (cookies.get('gw_token')) {
        const redirectToRegistration = async () => {
            if (store.state.account.actionAfterLogin !== null) router.push('/registration-wizard')
            window.history.pushState({}, '', '')
        }
        redirectToRegistration()
    }
})

onMounted(async () => {
    setPageTitle(t('news.News'))
    if (route.query.welcome) {
        state.isModalWelcome = true
    }

    commit('news/clearNews')
    await dispatch('news/getNotifications')
    await dispatch('news/getNews')

    // TODO - Andrej double check if there is a better way
    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('openChat') && urlParams.get('openChat') === 'true') {
        setTimeout(() => {
            document.getElementById('zsiq_float').click()
            let query = Object.assign({}, route.query)
            delete query.openChat
            router.replace({ query })
        }, 3000)
    }

    if (route.query.emailtoken) {
        await apiRequest
            .post('email/verify', { code: route.query.emailtoken })
            .then(() => {
                showMessage(t('logIn.EmailVerified'), 'success')
                commit('news/clearNews')
                dispatch('news/getNotifications')
                dispatch('news/getNews')
            })
            .catch((error) => {
                showMessageArray(error.errors.code)
            })
    }
})

const updateSearch = (value) => {
    search.value = value
    triggerSearch()
}

const triggerSearch = () => {
    if (!search.value || search.value.length >= 3 || search.value.length === 0) {
        dispatch('news/getNews')
        hasSearchResults.value = true
    }
}

const loadNews = () => {
    commit('news/setPage', store.state.news.page + 1)
    dispatch('news/loadMoreNews')
}
</script>

<template>
    <div class="news-wrapper">
        <header class="news-wrapper__header align-self-center mb-5">
            <div class="news-wrapper__title">
                {{ t('news.News') }}
            </div>
            <div class="news-wrapper__search ml-auto d-flex align-center">
                <v-text-field
                    v-model="search"
                    :disabled="store.getters['isLoading']"
                    :loading="store.getters['isLoading']"
                    :placeholder="t('news.SearchNews')"
                    class="search-input-field"
                    clearable
                    density="compact"
                    full-width
                    hide-details
                    rounded="0"
                    type="input"
                    variant="outlined"
                    @keydown.enter.prevent="updateSearch(search)"
                />
                <v-btn
                    class="search-button ma-0 rounded-r"
                    color="primary"
                    depressed
                    elevation="0"
                    icon="mdi-magnify"
                    size="small"
                    tile
                    @click="updateSearch(search)"
                />
            </div>
        </header>
        <main v-if="isLoading" class="news-wrapper__main">
            <NewsLoader />
            <NewsLoader />
            <NewsLoader />
            <NewsLoader />
            <NewsLoader />
            <NewsLoader />
            <NewsLoader />
        </main>
        <main v-else class="news-wrapper__main">
            <NewsForm :data="store.state.news.notifications" />
            <NewsForm :data="store.state.news.news" />
            <Btn
                v-if="isLoadMore"
                :disable="store.getters['isLoading']"
                :show="!store.getters['isLoading']"
                size="small"
                variant="text"
                class="w-100"
                @click="loadNews()"
            >
                {{ t('news.loadMore') }}
            </Btn>
        </main>
        <Modal :is-active="state.isModalWelcome" :max-width="476" @click:outside="state.isModalWelcome = false">
            <v-card class="welcome-modal pa-6">
                <v-card-text>
                    <h6 class="text-h6 font-weight-bold">
                        {{ state.fullName }}, {{ t('ModalWelcome.WelcomeToGreenWayNetwork') }}!
                    </h6>
                </v-card-text>
                <v-card-text>
                    <p class="mb-4" v-html="t('ModalWelcome.GreenWayNetworkIsTheLargest')" />
                    <p class="mb-4" v-html="t('ModalWelcome.IfYouHaveAnyQuestions')" />
                    <p class="mb-4" v-html="t('ModalWelcome.ThankYouForDrivingElectric')" />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="mx-auto"
                        color="primary"
                        variant="elevated"
                        :ripple="false"
                        @click="state.isModalWelcome = false"
                    >
                        {{ t('ModalWelcome.GoToClientPortal') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </Modal>
    </div>
</template>
